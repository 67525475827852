import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Container,
    CircularProgress,
    Button,
    Box,
} from "@mui/material";
import SeverErrorIllustration from "../components/SeverErrorIllustration";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../AuthenticationData.ts";
import Logo from "../components/Logo";

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

const VerifyingMail = () => {
    const navigate = useNavigate();
    const { token } = useParams();

    const [verificationStatus, setVerificationStatus] = useState("pending");

    useEffect(() => {
        verify();
    }, []);

    const verify = async () => {
        try {
            const response = await verifyEmail(token);
            if (response != null) {
                setVerificationStatus("success");
            }
            if (response.error) {
                setVerificationStatus("error");
            }
        } catch (error) {
            console.error("Error verifying email:", error);
            setVerificationStatus("error");
        }
    };

    return (
        <>
            <Helmet>
                <title>IDigix | Verifying Email</title>
            </Helmet>

            <Box sx={{ px: 3, py: 2, display: "inline-flex" }}>
                <Logo />
            </Box>

            <Container sx={{ marginTop: "-100px" }}>
                <StyledContent
                    sx={{ textAlign: "center", alignItems: "center" }}
                >
                    <Typography variant="h3" paragraph color="primary">
                        Verifying Your Email
                    </Typography>

                    <SeverErrorIllustration
                        sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                    />

                    {verificationStatus === "success" && (
                        <>
                            <Typography sx={{ color: "text.secondary" }}>
                                Thank you for verifiying your email
                            </Typography>
                            <Typography sx={{ color: "text.secondary" }}>
                                Please enjoy using IDigix
                            </Typography>

                            <Button
                                size="large"
                                variant="contained"
                                onClick={() => {
                                    navigate("/Login");
                                }}
                            >
                                Home
                            </Button>
                        </>
                    )}
                    {verificationStatus === "pending" && <CircularProgress />}
                    {verificationStatus === "error" && (
                        <Typography variant="body1" color="error">
                            Error verifying email. Please try again later.
                        </Typography>
                    )}
                </StyledContent>
            </Container>
        </>
    );
};

export default VerifyingMail;
