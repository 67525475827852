import React, { createContext, useContext, useState, useEffect } from "react";
import { GetProfile } from "../ProfileData.ts";

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState({
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        category: "",
        budget: "",
        rate: "",
        rateCount: "",
        rate_Skill_QualityOfWork: "",
        rate_Skill_CostEffectiveness: "",
        rate_Skill_Timeliness: "",
        rate_Skill_Communication: "",
        rate_Skill_Agility: "",
        auth: {
            id: "",
            userId: "",
            password: "",
            refreshToken: "",
            refreshTokenExpiration: "",
            user: null,
        },
        contacts: [
            {
                id: "",
                userId: "",
                contactInfo: "",
                category: "",
                isPublic: "",
                user: null,
            },
        ],
        deposits: null,
        purchases: null,
        profile: {
            id: "",
            userId: "",
            userName: "",
            birthDate: "",
            gender: "",
            profilePicture: "",
            profileCoverPicture: "",
        },
    });

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        const response = await GetProfile();
        const profileData = response?.data;
        if (profileData && profileData.category) {
            localStorage.setItem("userCategory", profileData.category);
        }
        setProfile(profileData);
    };

    return (
        <ProfileContext.Provider value={{ profile, setProfile, fetchProfile }}>
            {children}
        </ProfileContext.Provider>
    );
};
