import React from "react";
import { Box, Paper, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./appprofilecard.css";

const StyledCover = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
});

export default function AppProfileCard({ User, Profile }) {
    if (!User) {
        return <div>Loading user data...</div>;
    }

    return (
        <Box
            sx={{
                display: "grid",
                gap: 3,
            }}
            className="mobile-container"
        >
            <Paper
                sx={{
                    textAlign: "center",
                    fontSize: "13px",
                }}
            >
                {/* profile picture */}
                <div
                    className="rounded-top text-white"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Avatar
                        alt="Profile"
                        src={Profile.profilePicture}
                        sx={{
                            marginTop: "20px",
                            width: 130,
                            height: 130,
                        }}
                    />
                </div>
                {/* name / occupation / username */}
                <div className="p-4">
                    <div className="text-center">
                        <div>
                            <p className="mb profile-name">
                                {User.firstName} {User.lastName}
                            </p>
                            <p className="text-muted mb-0">
                                @{Profile.userName}
                            </p>
                            <p className="text-muted mb-0">
                                {Profile.occupation ? Profile.occupation : "-"}
                            </p>
                            <p className="text-muted mb-0">
                                {Profile.gender ? Profile.gender : "-"}
                            </p>
                        </div>
                        {/* <div className="px-3">
                                    <p className="mb-1 h6">
                                        {Profile.occupation
                                            ? Profile.occupation
                                            : "-"}
                                    </p>
                                    <p className="small text-muted mb-0">
                                        Occupation
                                    </p>
                                </div>
                                <div className="px-3">
                                    <p className="mb-1 h6">
                                        {Profile.gender ? Profile.gender : "-"}
                                    </p>
                                    <p className="small text-muted mb-0">
                                        Gender
                                    </p>
                                </div> */}
                    </div>
                </div>
            </Paper>
        </Box>
    );
}
