import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PutUserData {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    category: string;
}

export interface PutProfileData {
    id: number;
    userId: number;
    userName: string;
    birthDate: string;
    gender: string;
    profilePicture: string;
    profileCoverPicture: string;
    profilePictureFrame: 0;
    occupation: string;
    isPremium: boolean;
    isSearch: boolean;
    country: string;
}

export const GetProfile = async () => {
    try {
        const response = await api.get(`${BASE_URL}/Profile/GetProfile`, {
            headers: {
                withCredentials: true,
            },
        });

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const GetProfilesFromLink = async (search: string) => {
    try {
        const response = await api.get(
            `${BASE_URL}/Profile/GetProfilesFromLink/` + search,
            {
                headers: {
                    "Cache-Control": "public,max-age=3600",
                    Pragma: "",
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.response.data };
    }
};

export const EditProfile = async (
    newUser: PutUserData,
    newProfile: PutProfileData
) => {
    const data = {
        Id: newProfile.id,
        UserId: newProfile.userId,
        UserName: newProfile.userName,
        FirstName: newUser.firstName,
        LastName: newUser.lastName,
        BirthDate: newProfile.birthDate,
        Gender: newProfile.gender,
        ProfilePicture: newProfile.profilePicture,
        ProfileCoverPicture: newProfile.profileCoverPicture,
        ProfilePictureFrame: newProfile.profilePictureFrame,
        Occupation: newProfile.occupation,
        isPremium: newProfile.isPremium,
        Country: newProfile.country,
        isSearch: newProfile.isSearch,
    };

    if (newProfile.isSearch === true) data.isSearch = true;
    else data.isSearch = false;

    try {
        const response = await api.put(
            `${BASE_URL}/Profile/EditProfile`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.log("Error:", error.message);
    }
};
