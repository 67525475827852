import api from "./Api";
import { BASE_URL } from "./Context/config";

interface PostPurcahseData {
    UserId: number;
    Reference: string;
    Date: string;
    Amount: number;
    Country: string;
}

export const PurchaseBundle = async (purchase: PostPurcahseData) => {
    const data = {
        UserId: purchase.UserId,
        Reference: purchase.Reference,
        Date: purchase.Date,
        Amount: purchase.Amount,
        Country: purchase.Country,
    };

    try {
        const authToken = getAuthToken();
        const response = await api.post(
            `${BASE_URL}/Purchases/PurchasingBundle`,
            data,
            {
                headers: {
                    ContentType: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const GetDeposits = async () => {
    try {
        const authToken = getAuthToken();
        const response = await api.get(`${BASE_URL}/Purchases/GetDeposits`, {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const GetPurchases = async () => {
    try {
        const authToken = getAuthToken();
        const response = await api.get(`${BASE_URL}/Purchases/GetPurchases`, {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

function getAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return cookie.substring("authToken=".length, cookie.length);
        }
    }

    return null;
}
