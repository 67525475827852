import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
    Button,
    Grid,
    Box,
    Stack,
    Container,
    Tabs,
    Tab,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    IconButton,
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    useTheme,
} from "@mui/material";
import SimpleBar from "simplebar-react";
import { PurchasingCoupon, GetCoupons } from "../CouponsData.ts";
import { useProfile } from "../Context/ProfileContext";
import { useEffect, useState, lazy, startTransition, Suspense } from "react";
import { useBudget } from "../Context/BudgetContext";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { fDateTime } from "src/utils/formatTime";

const RemoveCircleOutlineIcon = lazy(() =>
    import("@mui/icons-material/RemoveCircleOutline")
);
const AddCircleOutlineIcon = lazy(() =>
    import("@mui/icons-material/AddCircleOutline")
);
const DeleteIcon = lazy(() => import("@mui/icons-material/Delete"));

// --------------------------------------------------------------

const VerticalLinearStepper = lazy(() =>
    import("../sections/@dashboard/Rating/VerticalLinearStepper.js")
);
const ToastContainer = lazy(() =>
    import("react-toastify").then((module) => ({
        default: module.ToastContainer,
    }))
);

const EmptyContent = lazy(() =>
    import("../sections/@dashboard/Coupons/EmptyContent.js")
);
const InviterFriends = lazy(() =>
    import("../sections/@dashboard/app/InviterFriends.js")
);
const BookingCustomerReviews = lazy(() =>
    import("../sections/@dashboard/Rating/BookingCustomerReviews.js")
);

const steps = [
    {
        label: "Get a coupon",
        description: `Before rating someone's skills, make sure to obtain a coupon. Coupons are essential for rating and evaluating someone's skills. You can either purchase a coupon or look for one in your saved cases.`,
    },
    {
        label: "Find the profile",
        description:
            "Once you have your coupon, proceed to find the profile of the person you want to rate. Take your time to explore their profile thoroughly.",
    },
    {
        label: "Rate their skills",
        description: `Now that you have the coupon and have reviewed the profile, it's time to rate their skills. Provide a rating of 1 to 5 for each skill category. Your feedback helps improve user satisfaction and fosters growth on the platform.`,
    },
];
const AvailableTableColumns = [
    { field: "serialKey", headerName: "Serial Key", width: 240 },
    { field: "reference", headerName: "Reference", width: 140 },
];
const ReservedTableColumns = [
    {
        field: "customerDetails",
        headerName: "Customer",
        width: 250,
        renderCell: (params) => (
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => {
                    window.open(
                        `/Profile/${params.row.customerUserName}`,
                        "_blank"
                    );
                }}
            >
                <Avatar
                    alt={params.row.customerFirstName}
                    src={params.row.customerProfilePicture}
                />
                <div>
                    <Typography variant="subtitle2">
                        {params.row.customerFirstName}{" "}
                        {params.row.customerLastName}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            color: "text.secondary",
                            mt: 0.5,
                            display: "block",
                        }}
                    >
                        {params.row.customerUserName}
                    </Typography>
                </div>
            </Stack>
        ),
    },
    { field: "reserveDate", headerName: "Date of Reservation", width: 180 },
    { field: "serialKey", headerName: "Serial Key", width: 140 },
];
const UsedTableColumns = [
    {
        field: "customerDetails",
        headerName: "Customer",
        width: 250,
        renderCell: (params) => (
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => {
                    window.open(
                        `/Profile/${params.row.customerUserName}`,
                        "_blank"
                    );
                }}
            >
                <Avatar
                    alt={params.row.customerFirstName}
                    src={params.row.customerProfilePicture}
                />
                <div>
                    <Typography variant="subtitle2">
                        {params.row.customerFirstName}{" "}
                        {params.row.customerLastName}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            color: "text.secondary",
                            mt: 0.5,
                            display: "block",
                        }}
                    >
                        {params.row.customerUserName}
                    </Typography>
                </div>
            </Stack>
        ),
    },
    { field: "useDate", headerName: "Date of Use", width: 180 },
    { field: "serialKey", headerName: "Serial Key", width: 140 },
];
const Scrollbar = styled(SimpleBar)``;

export const RatingPage = () => {
    const { profile, fetchProfile } = useProfile();
    const { setBudget } = useBudget();
    const [User, setUser] = useState({
        id: 0,
        email: "",
        firstName: "",
        lastName: "",
        category: "",
        budget: 0.0,
    });
    const [Profile, setProfile] = useState({
        id: 0,
        userId: 0,
        userName: "",
        birthDate: "",
        gender: "",
        profilePicture: "",
        profileCoverPicture: "",
        profilePictureFrame: 0,
        occupation: "",
        isPremium: false,
        user: null,
        socialLinks: null,
        customLinks: null,
        premium: null,
        isSearch: null,
        country: null,
    });
    const [AvailableCoupons, setAvailableCoupons] = useState([]);
    const [ReservedCoupons, setReservedCoupons] = useState([]);
    const [UsedCoupons, setUsedCoupons] = useState([]);
    const [showAvailableCoupons, setShowAvailableCoupons] = useState(false);
    const [showReservedCoupons, setShowReservedCoupons] = useState(false);
    const [showUsedCoupons, setShowUsedCoupons] = useState(false);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [PurchaseCoupon, setPurchaseCoupon] = useState({
        title: "coupon",
        quantity: 1,
        available: 99,
        amount: 15,
    });
    const [showGetCoupons, setShowGetCoupons] = useState(false);

    const [activeTab, setActiveTab] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const theme = useTheme();

    const [AvailablePagination, setAvailablePagination] = useState({
        page: 0,
        pageSize: 5,
    });

    const [ReservedPagination, setReservedPagination] = useState({
        page: 0,
        pageSize: 5,
    });

    const [UsedPagination, setUsedPagination] = useState({
        page: 0,
        pageSize: 5,
    });

    const navigate = useNavigate();

    useEffect(() => {
        startTransition(() => {
            fetchUserData();
            fetchCoupons();
        });
    }, [profile]);

    const fetchUserData = async () => {
        try {
            setUser({
                id: profile.id,
                email: profile.email,
                firstName: profile.firstName,
                lastName: profile.lastName,
                category: profile.category,
            });
            setProfile(profile.profile);
        } catch (error) {
            if (error.redirectToLogin) {
                navigate("/Login");
            }
            console.error("Error fetching profile data:", error);
        }
    };

    const fetchCoupons = async () => {
        try {
            const response = await GetCoupons();
            const usedCoupons = response.filter(
                (coupon) => coupon.isReserved && coupon.isUsed
            );
            const reservedCoupons = response.filter(
                (coupon) => coupon.isReserved && !coupon.isUsed
            );
            const availableCoupons = response.filter(
                (coupon) => !coupon.isReserved && !coupon.isUsed
            );

            if (availableCoupons.length > 0) {
                setAvailableCoupons(availableCoupons);
                setShowAvailableCoupons(true);
            }
            if (reservedCoupons.length > 0) {
                setReservedCoupons(
                    reservedCoupons.map((coupon) => {
                        coupon.reserveDate = fDateTime(coupon.reserveDate);
                        return coupon;
                    })
                );
                setShowReservedCoupons(true);
            }
            if (usedCoupons.length > 0) {
                setUsedCoupons(
                    usedCoupons.map((coupon) => {
                        coupon.useDate = fDateTime(coupon.useDate);
                        return coupon;
                    })
                );
                setShowUsedCoupons(true);
            }
        } catch (error) {
            console.error("Error with fetching coupons : ", error);
        }
    };

    const handleAddQuantity = (id) => {
        startTransition(() => {
            setPurchaseCoupon((coupon) => ({
                ...coupon,
                quantity: coupon.quantity + 1,
                available: coupon.available - 1,
            }));
        });
    };

    const handleSubtractQuantity = (id) => {
        startTransition(() => {
            setPurchaseCoupon((coupon) => ({
                ...coupon,
                quantity: Math.max(0, coupon.quantity - 1),
                available: coupon.available + 1,
            }));
        });
    };

    const handleConfirm = async () => {
        try {
            const response = await PurchasingCoupon({
                UserId: User.id,
                Date: new Date(),
                Country: Profile.country,
                Amount: PurchaseCoupon.amount,
                Number: PurchaseCoupon.quantity,
            });

            setPurchaseCoupon((coupon) => ({
                ...coupon,
                quantity: 0,
            }));

            setShowGetCoupons(false);

            fetchCoupons();

            setBudget(response.data.newAmount);
            startTransition(() => {
                toast.success("Succesfully purchased a coupon", {
                    position: "top-center",
                    autoClose: 1000,
                });
            });
        } catch (error) {
            console.error("Error with purchasing coupons : ", error);
        }
    };

    const handleCopy = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    toast.info("Serial Key copied", {
                        position: "top-center",
                        autoClose: 300,
                    });
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                    copyToClipboardFallback(text);
                });
        } else {
            copyToClipboardFallback(text);
        }
    };

    const copyToClipboardFallback = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        toast.info("Serial Key copied (fallback)", {
            position: "top-center",
            autoClose: 300,
        });
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Container>
            <Helmet>
                <title> IDigix | Rating </title>
            </Helmet>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Rating
            </Typography>
            <Grid container padding={1} spacing={2}>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardHeader
                            title="Exclusive Discount Coupons!"
                            subheader="Interested in saving big on your next purchase? Check out our special coupons!"
                        />
                        <CardContent>
                            {showGetCoupons ? (
                                <>
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            Price
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Quantity
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow
                                                        key={PurchaseCoupon.id}
                                                    >
                                                        <TableCell align="right">
                                                            {
                                                                PurchaseCoupon.amount
                                                            }
                                                            <sup>Dt</sup>
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            <Box
                                                                sx={{
                                                                    textAlign:
                                                                        "right",
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <Stack
                                                                    sx={{
                                                                        fontSize:
                                                                            "small",
                                                                    }}
                                                                >
                                                                    <Box>
                                                                        <IconButton
                                                                            size="small"
                                                                            color="inherit"
                                                                            onClick={() =>
                                                                                handleSubtractQuantity(
                                                                                    PurchaseCoupon.id
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                PurchaseCoupon.quantity <=
                                                                                1
                                                                            }
                                                                        >
                                                                            <Suspense
                                                                                fallback={
                                                                                    <div>
                                                                                        Loading...
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <RemoveCircleOutlineIcon
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </IconButton>
                                                                        {
                                                                            PurchaseCoupon.quantity
                                                                        }
                                                                        <IconButton
                                                                            size="small"
                                                                            color="inherit"
                                                                            onClick={() =>
                                                                                handleAddQuantity(
                                                                                    PurchaseCoupon.id
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                PurchaseCoupon.quantity >=
                                                                                PurchaseCoupon.available
                                                                            }
                                                                        >
                                                                            <Suspense
                                                                                fallback={
                                                                                    <div>
                                                                                        Loading...
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <AddCircleOutlineIcon
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </IconButton>
                                                                    </Box>
                                                                </Stack>

                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{
                                                                        color: "text.secondary",
                                                                        fontSize:
                                                                            "9px",
                                                                    }}
                                                                >
                                                                    available:{" "}
                                                                    {
                                                                        PurchaseCoupon.available
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {PurchaseCoupon.amount *
                                                                PurchaseCoupon.quantity}
                                                            Dt
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={() =>
                                                                    setConfirmDialogOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <Suspense
                                                                    fallback={
                                                                        <div>
                                                                            Loading...
                                                                        </div>
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </Suspense>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleConfirm}
                                            >
                                                Confirm
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : showAvailableCoupons ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ marginBottom: "40px" }}
                                        onClick={() => {
                                            setShowGetCoupons(true);
                                        }}
                                    >
                                        Get Coupons
                                    </Button>
                                    <Scrollbar>
                                        <Box sx={{ height: 370 }}>
                                            <DataGrid
                                                columns={AvailableTableColumns}
                                                rows={AvailableCoupons}
                                                onRowClick={(o) =>
                                                    handleCopy(o.row.serialKey)
                                                }
                                                sx={{
                                                    "& .MuiDataGrid-cell:focus":
                                                        {
                                                            outline: "none",
                                                        },
                                                    "& .MuiDataGrid-cell": {
                                                        alignContent: "center",
                                                    },
                                                    border: "none",
                                                    backgroundColor:
                                                        theme.palette.common
                                                            .white,
                                                }}
                                                paginationModel={
                                                    AvailablePagination
                                                }
                                                paginationMode="client"
                                                onPaginationModelChange={
                                                    setAvailablePagination
                                                }
                                            />
                                        </Box>
                                    </Scrollbar>
                                </>
                            ) : (
                                <Stack>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setShowGetCoupons(true);
                                        }}
                                    >
                                        Get Coupons
                                    </Button>
                                    <Suspense fallback={<CircularProgress />}>
                                        <EmptyContent
                                            title="You don't have any coupons"
                                            description="Looks like you have no items in your shopping Coupons."
                                            img="/assets/illustrations/illustration_empty_cart.svg"
                                        />
                                    </Suspense>
                                </Stack>
                            )}
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 3 }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                                padding: "10px",
                            }}
                        >
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                aria-label="Account tabs"
                            >
                                <Tab label="Reserved" />
                                <Tab label="Used" />
                            </Tabs>
                        </Box>
                        <CardContent>
                            {activeTab === 0 ? (
                                <Scrollbar>
                                    <Box sx={{ height: 370 }}>
                                        <DataGrid
                                            columns={ReservedTableColumns}
                                            rows={ReservedCoupons}
                                            sx={{
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: "none",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    alignContent: "center",
                                                },
                                                border: "none",
                                                backgroundColor:
                                                    theme.palette.common.white,
                                            }}
                                            paginationModel={ReservedPagination}
                                            paginationMode="client"
                                            onPaginationModelChange={
                                                setReservedPagination
                                            }
                                        />
                                    </Box>
                                </Scrollbar>
                            ) : (
                                <Scrollbar>
                                    <Box sx={{ height: 370 }}>
                                        <DataGrid
                                            columns={UsedTableColumns}
                                            rows={UsedCoupons}
                                            sx={{
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: "none",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    alignContent: "center",
                                                },
                                                border: "none",
                                                backgroundColor:
                                                    theme.palette.common.white,
                                            }}
                                            paginationModel={UsedPagination}
                                            paginationMode="client"
                                            onPaginationModelChange={
                                                setUsedPagination
                                            }
                                        />
                                    </Box>
                                </Scrollbar>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={3}>
                        {/* <Suspense fallback={<CircularProgress />}>
                            <BookingCustomerReviews />
                        </Suspense> */}

                        <Card>
                            <CardContent>
                                <Suspense fallback={<CircularProgress />}>
                                    <VerticalLinearStepper steps={steps} />
                                </Suspense>
                            </CardContent>
                        </Card>
                        <Suspense fallback={<CircularProgress />}>
                            <InviterFriends />
                        </Suspense>
                    </Stack>
                </Grid>
            </Grid>
            <Dialog
                open={confirmDialogOpen}
                onClose={() => {
                    setConfirmDialogOpen(false);
                }}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDialogOpen(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setPurchaseCoupon((coupon) => ({
                                ...coupon,
                                quantity: 0,
                            }));
                            setConfirmDialogOpen(false);
                            setShowGetCoupons(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Suspense fallback={<CircularProgress />}>
                <ToastContainer />
            </Suspense>
        </Container>
    );
};
