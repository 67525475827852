import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PostViewData {
    userId: number;
    country?: string;
    gender?: string;
    date?: string;
}

export interface PostClickData {
    userId: number;
    linkId: number;
    gender?: string;
    country?: string;
    category: string;
    date?: string;
}

export const GetClicks = async () => {
    try {
        const authToken = getAuthToken();

        const response = await api.get(`${BASE_URL}/Analytics/GetClicks`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                withCredentials: true,
            },
        });

        return response;
    } catch (error) {
        console.log("Error:", error.message);
    }
};

export const GetViews = async () => {
    try {
        const authToken = getAuthToken();

        const response = await api.get(`${BASE_URL}/Analytics/GetViews`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                withCredentials: true,
            },
        });

        return response;
    } catch (error) {
        console.log("Error:", error.message);
    }
};

export const PostClick = async (click: PostClickData) => {
    const Data = {
        userId: click.userId,
        linkId: click.linkId,
        gender: click.gender,
        country: click.country,
        category: click.category,
        date: click.date,
    };

    try {
        await api.post(`${BASE_URL}/Analytics/CreateClick`, Data, {
            headers: {
                withCredentials: true,
            },
        });
    } catch (error) {
        console.log("Error:", error.message);
    }
};

export const PostView = async (view: PostViewData) => {
    const Data = {
        userId: view.userId,
        gender: view.gender,
        country: view.country,
        date: view.date,
    };

    try {
        const response = await api.post(
            `${BASE_URL}/Analytics/CreateView`,
            Data,
            {
                headers: {
                    withCredentials: true,
                },
            }
        );
    } catch (error) {
        console.log("Error:", error.message);
    }
};

function getAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return cookie.substring("authToken=".length, cookie.length);
        }
    }

    return null;
}

export const calculateDailyViews = (data) => {
    const dailyViews = {};

    if (data[0].views && data[0].views.length > 0) {
        data[0].views.forEach((view) => {
            const date = new Date(view.date);

            const formattedDate =
                `${date.getDate().toString().padStart(2, "0")}/` +
                `${(date.getMonth() + 1).toString().padStart(2, "0")}/` +
                `${date.getFullYear()}`;

            if (dailyViews[formattedDate]) {
                dailyViews[formattedDate] += 1;
            } else {
                dailyViews[formattedDate] = 1;
            }
        });
    }

    const result = Object.keys(dailyViews).map((date) => ({
        date,
        totalClicks: dailyViews[date],
    }));

    return result;
};

export const mostViewedCountry = (data) => {
    const countryViews = {};

    if (data && data.length > 0 && data[0].views && data[0].views.length > 0) {
        data[0].views.forEach((countryView) => {
            const country = countryView.country.trim(); // Remove leading/trailing whitespace

            if (countryViews[country]) {
                countryViews[country] += 1; // Count occurrences
            } else {
                countryViews[country] = 1;
            }
        });
    }

    let mostViewedCountry = null;
    let highestViewCount = 0;

    for (const country in countryViews) {
        if (countryViews[country] > highestViewCount) {
            highestViewCount = countryViews[country];
            mostViewedCountry = country;
        }
    }

    return mostViewedCountry;
};

export const calculateDailyClicks = (data) => {
    const dailyClicks = {};

    data.forEach((item) => {
        if (item.linkClicks && item.linkClicks.length > 0) {
            item.linkClicks.forEach((click) => {
                const date = new Date(click.date);

                const formattedDate =
                    `${date.getDate().toString().padStart(2, "0")}/` +
                    `${(date.getMonth() + 1).toString().padStart(2, "0")}/` +
                    `${date.getFullYear()}`;

                if (dailyClicks[formattedDate]) {
                    dailyClicks[formattedDate] += 1;
                } else {
                    dailyClicks[formattedDate] = 1;
                }
            });
        }
    });

    const result = Object.keys(dailyClicks).map((date) => ({
        date,
        totalClicks: dailyClicks[date],
    }));

    return result;
};

export const calculateCountryClicks = (data) => {
    const countryClicks = {};

    data.forEach((item) => {
        if (item.linkClicks && item.linkClicks.length > 0) {
            item.linkClicks.forEach((click) => {
                if (countryClicks[click.country]) {
                    countryClicks[click.country] += 1;
                } else {
                    countryClicks[click.country] = 1;
                }
            });
        }
    });

    const result = Object.keys(countryClicks).map((country) => ({
        country,
        totalClicks: countryClicks[country],
    }));

    return result;
};

export const calculateGenderClicks = (data) => {
    const genderClicks = {};

    data.forEach((item) => {
        if (item.linkClicks && item.linkClicks.length > 0) {
            item.linkClicks.forEach((click) => {
                if (genderClicks[click.gender]) {
                    genderClicks[click.gender] += 1;
                } else {
                    genderClicks[click.gender] = 1;
                }
            });
        }
    });

    const result = Object.keys(genderClicks).map((gender) => ({
        gender,
        totalClicks: genderClicks[gender],
    }));

    return result;
};

export const MostClickedCountry = (data) => {
    const countryClicks = {};

    data.forEach((item) => {
        if (item.countryClickCounts && item.countryClickCounts.length > 0) {
            item.countryClickCounts.forEach((countryClick) => {
                const country = countryClick.country;

                if (countryClicks[country]) {
                    countryClicks[country] += countryClick.clickCount;
                } else {
                    countryClicks[country] = countryClick.clickCount;
                }
            });
        }
    });

    let mostClickedCountry = null;
    let highestClickCount = 0;

    for (const country in countryClicks) {
        if (countryClicks[country] > highestClickCount) {
            highestClickCount = countryClicks[country];
            mostClickedCountry = country;
        }
    }

    return mostClickedCountry;
};

export const getClickCountsByCategory = (data) => {
    const categories = [
        "Custom",
        "Twitter",
        "GitHub",
        "Instagram",
        "Facebook",
        "LinkedIn",
    ];

    const clickCountsByCategory = [];

    categories.forEach((category) => {
        const totalClickCount = data.reduce((total, item) => {
            if (item.linkCategory === category) {
                return total + item.clickCount;
            }
            return total;
        }, 0);

        clickCountsByCategory.push(totalClickCount);
    });

    return clickCountsByCategory;
};

export const GetCategoryClickCounts = (data) => {
    const categoryClickCounts = {};

    data.forEach((item) => {
        if (!categoryClickCounts[item.linkCategory])
            categoryClickCounts[item.linkCategory] = 0;

        categoryClickCounts[item.linkCategory] += item.clickCount;
    });

    // Convert the accumulated click counts into an array of objects
    const result = Object.keys(categoryClickCounts).map((category) => {
        var icon = category;
        var color;

        switch (category) {
            case "Twitter":
                icon = "Twitter-circle";
                color = "#212121";
                break;
            case "Custom":
                icon = "Profile";
                color = "#007BFF";
                break;
            case "Facebook":
                color = "#5892d0";
                break;
            case "Youtube":
                color = "#FF0000";
                break;
            case "Instagram":
                color = "#D81B60";
                break;
            case "Github":
                color = "#212121";
                break;
            case "LinkedIn":
                color = "#00b9f1";
                break;
            default:
                color = "#000000";
                break;
        }

        return {
            name: category,
            value: categoryClickCounts[category],
            icon: icon.toLowerCase(),
            color: color,
        };
    });

    return result;
};
