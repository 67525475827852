import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    Button,
    Box,
    Paper,
    Stepper,
    StepLabel,
    Typography,
    Step,
    StepContent,
    TextField,
    Grid,
    IconButton,
} from "@mui/material";
import ThankYouCard from "../sections/@dashboard/Rating/ThankYouCard";
import CloseIcon from "@mui/icons-material/Close";

const Steps = [
    {
        label: "Provide the coupon please",
        description:
            "Can you please provide the coupon serial key to perform this rating",
    },
    {
        label: "Quality of work",
        description:
            "Evaluate the overall quality and accuracy of the work performed by the individual.",
    },
    {
        label: "Communication",
        description:
            "Assess the effectiveness of communication between you and the individual.",
    },
    {
        label: "Timeliness",
        description:
            "Consider the punctuality and adherence to deadlines in completing tasks.",
    },
    {
        label: "Cost-Effectiveness",
        description:
            "Review the efficiency and value for money of the services provided.",
    },
    {
        label: "Agility",
        description:
            "Examine whether the individual conducts themselves with integrity and adherence to ethical principles.",
    },
];

export default function RatingDialog({
    serialKey,
    openDialog,
    onClose,
    onClick,
}) {
    const [feedbackScores, setFeedbackScores] = useState(
        Array(Steps.length).fill(0)
    );
    const [Skill_QualityOfWork, setSkill_QualityOfWork] = useState(0);
    const [Skill_CostEffectiveness, setSkill_CostEffectiveness] = useState(0);
    const [Skill_Timeliness, setSkill_Timeliness] = useState(0);
    const [Skill_Communication, setSkill_Communication] = useState(0);
    const [Skill_Agility, setSkill_Agility] = useState(0);

    const [SerialKey, setSerialKey] = useState(serialKey ? serialKey : "");

    const handleRatingClick = (score, step) => {
        const updatedFeedbackScores = [...feedbackScores];
        updatedFeedbackScores[step] = score;
        setFeedbackScores(updatedFeedbackScores);

        switch (step) {
            case 1:
                setSkill_QualityOfWork(score);
                break;
            case 2:
                setSkill_CostEffectiveness(score);
                break;
            case 3:
                setSkill_Timeliness(score);
                break;
            case 4:
                setSkill_Communication(score);
                break;
            case 5:
                setSkill_Agility(score);
                break;
        }
    };

    const [activeStep, setActiveStep] = useState(SerialKey ? 1 : 0);

    const handleFinish = async () => {
        onClick({
            SerialKey,
            Skill_QualityOfWork,
            Skill_CostEffectiveness,
            Skill_Timeliness,
            Skill_Communication,
            Skill_Agility,
        });
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog open={openDialog} fullWidth maxWidth="sm">
                <DialogContent>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {activeStep === Steps.length ? (
                        <Paper sx={{ p: 3, mt: 3, bgcolor: "grey.50012" }}>
                            <ThankYouCard
                                feedbackScores={[
                                    Skill_QualityOfWork,
                                    Skill_CostEffectiveness,
                                    Skill_Timeliness,
                                    Skill_Communication,
                                    Skill_Agility,
                                ]}
                            />
                            <Button variant="contained" onClick={handleFinish}>
                                Confirm
                            </Button>
                            <Button
                                onClick={() => {
                                    setActiveStep(
                                        (prevActiveStep) => prevActiveStep - 1
                                    );
                                }}
                            >
                                Back
                            </Button>
                        </Paper>
                    ) : (
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {Steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === Steps.length - 1 ? (
                                                <Typography variant="caption">
                                                    Last step
                                                </Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {index === 0 ? (
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Coupon Serial Key"
                                                        value={SerialKey}
                                                        onChange={(event) =>
                                                            setSerialKey(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            setActiveStep(
                                                                (
                                                                    prevActiveStep
                                                                ) =>
                                                                    prevActiveStep +
                                                                    1
                                                            )
                                                        }
                                                        disabled={
                                                            SerialKey === ""
                                                        }
                                                    >
                                                        Next
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Typography>
                                                    {step.description}
                                                </Typography>
                                                <Box sx={{ mt: 3 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                spacing={2}
                                                            >
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (score) => (
                                                                        <Grid
                                                                            item
                                                                            key={
                                                                                score
                                                                            }
                                                                        >
                                                                            <Button
                                                                                onClick={() => {
                                                                                    handleRatingClick(
                                                                                        score,
                                                                                        activeStep
                                                                                    );
                                                                                    setActiveStep(
                                                                                        (
                                                                                            prevActiveStep
                                                                                        ) =>
                                                                                            prevActiveStep +
                                                                                            1
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    bgcolor:
                                                                                        feedbackScores[
                                                                                            activeStep
                                                                                        ] ===
                                                                                        score
                                                                                            ? "primary.main"
                                                                                            : "grey.300",
                                                                                    color:
                                                                                        feedbackScores[
                                                                                            activeStep
                                                                                        ] ===
                                                                                        score
                                                                                            ? "common.white"
                                                                                            : "grey.800",
                                                                                    "&:hover":
                                                                                        {
                                                                                            bgcolor:
                                                                                                "primary.dark",
                                                                                            color: "common.white",
                                                                                        },
                                                                                    borderRadius:
                                                                                        "50%",
                                                                                    minWidth:
                                                                                        "50px",
                                                                                    height: "50px",
                                                                                    fontSize:
                                                                                        "18px",
                                                                                    fontWeight: 600,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    score
                                                                                }
                                                                            </Button>
                                                                        </Grid>
                                                                    )
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={() => {
                                                            setActiveStep(
                                                                (
                                                                    prevActiveStep
                                                                ) =>
                                                                    prevActiveStep -
                                                                    1
                                                            );
                                                        }}
                                                    >
                                                        Back
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
