import { useState, useEffect, lazy, startTransition, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
// Lazy load Material-UI components
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Avatar,
    TextField,
    FormControl,
    RadioGroup,
    Select,
    Radio,
    MenuItem,
    FormControlLabel,
    InputLabel,
    Button,
    Stack,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Switch,
    ListItemText,
    Box,
    CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";

// import SquarePhotoSelector from "../sections/auth/signup/SquarePhotoSelector";
const SquarePhotoSelector = lazy(() =>
    import("../sections/auth/signup/SquarePhotoSelector")
);
const AddCvDialog = lazy(() =>
    import("src/sections/@dashboard/Contact/AddCvDialog")
);
const WomanIcon = lazy(() => import("@mui/icons-material/Woman"));
const ManIcon = lazy(() => import("@mui/icons-material/Man"));
// const LocationOnIcon = lazy(() => import("@mui/icons-material/LocationOn"));
// const LockIcon = lazy(() => import("@mui/icons-material/Lock"));
const SaveIcon = lazy(() => import("@mui/icons-material/Save"));
const CloseIcon = lazy(() => import("@mui/icons-material/Close"));
const CheckCircleOutlineIcon = lazy(() =>
    import("@mui/icons-material/CheckCircleOutline")
);

const themes = [
    {
        name: "Custom",
        color: "linear-gradient(to right, #ffffff, #ffffff)",
        upgrade: false,
    },
    {
        name: "Pure Motion",
        color: "linear-gradient(to right, #f0f9ff, #cbebff)",
        upgrade: false,
    },
    {
        name: "Fire Motion",
        color: "linear-gradient(to right, #ffecd2, #fcb69f)",
        upgrade: true,
    },
    {
        name: "Luxury Motion",
        color: "linear-gradient(to right, #a1c4fd, #c2e9fb)",
        upgrade: true,
    },
];

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
        "&::before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        "&::after": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z"/></svg>')`,
            right: 12,
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function Appearance({
    Profile,
    User,
    isSaveButtonActive,
    handlePhotoSelect,
    handleProfileChange,
    handleUserChange,
    handleSave,
    handleIsSearchChange,
    handleCoverPhotoSelect,
}) {
    const [isVisible, setIsVisible] = useState(true);
    const [search, setSearch] = useState(Profile.isSearch);
    const [hideBranding, setHideBranding] = useState(false);
    const navigate = useNavigate();
    const [focused, setFocused] = useState(false);
    const [clickedCardIndex, setClickedCardIndex] = useState(-1);

    const [isFormValid, setFormValid] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        startTransition(() => {
            const isCardVisible = localStorage.getItem("isCardVisible");
            setIsVisible(User.category == "Free" && isCardVisible !== "false");
            setSearch(Profile.isSearch);
            if (Profile.occupation && User.firstName && User.lastName) {
                validateForm();
            }
        });
    }, [Profile, User]);

    const handlePremuimSectionClosse = () => {
        startTransition(() => {
            setIsVisible(false);
            localStorage.setItem("isCardVisible", "false");
        });
    };

    const handleHideBrandingChange = (event) => {
        startTransition(() => {
            setHideBranding(event.target.checked);
        });
    };

    const handleSearchChange = (event) => {
        startTransition(() => {
            setSearch(event.target.checked);
            handleIsSearchChange(event.target.checked);
        });
    };

    const handleCardClick = (index) => {
        startTransition(() => {
            if (index === clickedCardIndex) {
                // If clicked card is already checked, uncheck it
                setClickedCardIndex(-1);
            } else {
                // Otherwise, check the clicked card and uncheck all others
                setClickedCardIndex(index);
            }
        });
    };

    const validateForm = () => {
        const { firstName, lastName } = User;
        const { occupation } = Profile;

        const isFirstNameValid = /^[A-Za-z ]{3,32}$/.test(firstName);
        const isLastNameValid = /^[A-Za-z ]{3,32}$/.test(lastName);
        const isOccupationValid = /^[A-Za-z0-9_ ]{3,100}$/.test(occupation);

        startTransition(() => {
            setErrors({
                firstName:
                    firstName.length > 32
                        ? "First name must be between 2 and 50 characters long."
                        : isFirstNameValid
                        ? ""
                        : "First name can only contain letters and hyphens.",
                lastName:
                    lastName.length > 32
                        ? "Last name must be between 2 and 50 characters long."
                        : isLastNameValid
                        ? ""
                        : "Last name can only contain letters and hyphens.",
                occupation:
                    occupation.length > 100
                        ? "Occupation must be between 2 and 100 characters long."
                        : isOccupationValid
                        ? ""
                        : "Occupation can only contain letters, numbers, spaces, hyphens, underscores, and periods.",
            });

            setFormValid(
                isFirstNameValid && isLastNameValid && isOccupationValid
            );
        });
    };

    const handleSubmit = (e) => {
        startTransition(() => {
            e.preventDefault();
            if (isFormValid) handleSave();
            else {
                toast.error("Please validate your information", {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        });
    };

    return (
        <Grid>
            <Suspense fallback={<CircularProgress />}>
                <Card>
                    <CardHeader
                        title="Profile"
                        subheader="this is where you can edit your entire profile!"
                    />
                    <CardContent>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{
                                    marginBottom: "40px",
                                }}
                            >
                                <Grid item>
                                    <Avatar
                                        src={Profile.profilePicture}
                                        alt="User Profile Photo"
                                        sx={{
                                            width: 80,
                                            height: 80,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={7} md={6}>
                                    <SquarePhotoSelector
                                        onSelect={handlePhotoSelect}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{
                                    marginBottom: "40px",
                                }}
                            >
                                <Grid item>
                                    <Avatar
                                        src={Profile.profileCoverPicture}
                                        alt="User Cover Photo"
                                        sx={{
                                            width: 80,
                                            height: 80,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={7} md={6}>
                                    <SquarePhotoSelector
                                        onSelect={handleCoverPhotoSelect}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid item xs={8} md={4} sx={{ marginLeft: "20px" }}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    name="gender"
                                    value={Profile.gender}
                                    onChange={handleProfileChange}
                                >
                                    <FormControlLabel
                                        value="male"
                                        label={
                                            <ManIcon
                                                sx={{
                                                    color: "text.secondary",
                                                }}
                                            />
                                        }
                                        control={<Radio />}
                                    />

                                    <FormControlLabel
                                        value="female"
                                        label={
                                            <WomanIcon
                                                sx={{
                                                    color: "text.secondary",
                                                }}
                                            />
                                        }
                                        control={<Radio />}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Box className="row" style={{ marginTop: "18px" }}>
                            <Box className="col">
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First name"
                                    value={User.firstName}
                                    onChange={handleUserChange}
                                    error={
                                        errors.firstName &&
                                        User.firstName.trim() !== ""
                                    }
                                    helperText={errors.firstName}
                                />
                            </Box>
                            <Box className="col">
                                <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last name"
                                    value={User.lastName}
                                    onChange={handleUserChange}
                                    error={
                                        errors.lastName &&
                                        User.lastName.trim() !== ""
                                    }
                                    helperText={errors.lastName}
                                />
                            </Box>
                        </Box>
                        <Box className="row" style={{ marginTop: "18px" }}>
                            <Box className="col">
                                <TextField
                                    fullWidth
                                    name="userName"
                                    label="Username"
                                    value={Profile.userName}
                                    onChange={handleProfileChange}
                                    disabled
                                />
                            </Box>
                        </Box>
                        <Box
                            className="row"
                            style={{ marginTop: "18px" }}
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                gap: { xs: "12px", sm: "0" },
                            }}
                            spacing={5}
                        >
                            <Box className="col-sm-6">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="occupation"
                                    label="Occupation"
                                    value={Profile.occupation}
                                    onChange={handleProfileChange}
                                    error={
                                        errors.occupation &&
                                        Profile.occupation.trim() !== ""
                                    }
                                    helperText={errors.occupation}
                                    InputLabelProps={{
                                        shrink: !!Profile.occupation,
                                    }}
                                />
                            </Box>
                            <Box className="col">
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink={
                                            focused || Profile.country !== ""
                                        }
                                    >
                                        Country
                                    </InputLabel>
                                    <Select
                                        name="country"
                                        value={Profile.country}
                                        focus
                                        onChange={handleProfileChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="Tunisia">
                                            Tunisia
                                        </MenuItem>
                                        <MenuItem value="Sfax">Sfax</MenuItem>
                                        <MenuItem value="Sousse">
                                            Sousse
                                        </MenuItem>
                                        <MenuItem value="Kairouan">
                                            Kairouan
                                        </MenuItem>
                                        <MenuItem value="Bizerte">
                                            Bizerte
                                        </MenuItem>
                                        <MenuItem value="Gabes">Gabes</MenuItem>
                                        <MenuItem value="Ariana">
                                            Ariana
                                        </MenuItem>
                                        <MenuItem value="Gafsa">Gafsa</MenuItem>
                                        <MenuItem value="Marsa">Marsa</MenuItem>
                                        <MenuItem value="Tataouine">
                                            Tataouine
                                        </MenuItem>
                                        <MenuItem value="Hammamet">
                                            Hammamet
                                        </MenuItem>
                                        <MenuItem value="Mestir">
                                            Mestir
                                        </MenuItem>
                                        <MenuItem value="Nabeul">
                                            Nabeul
                                        </MenuItem>
                                        <MenuItem value="Beja">Beja</MenuItem>
                                        <MenuItem value="Mahdia">
                                            Mahdia
                                        </MenuItem>
                                        <MenuItem value="Ben Arous">
                                            Ben Arous
                                        </MenuItem>
                                        <MenuItem value="Zarzis">
                                            Zarzis
                                        </MenuItem>
                                        <MenuItem value="Medenine">
                                            Medenine
                                        </MenuItem>
                                        <MenuItem value="Siliana">
                                            Siliana
                                        </MenuItem>
                                        <MenuItem value="Kasserine">
                                            Kasserine
                                        </MenuItem>
                                        <MenuItem value="Tozeur">
                                            Tozeur
                                        </MenuItem>
                                        <MenuItem value="Kebili">
                                            Kebili
                                        </MenuItem>
                                        <MenuItem value="Jandouba">
                                            Jandouba
                                        </MenuItem>
                                        <MenuItem value="Zaghouan">
                                            Zaghouan
                                        </MenuItem>
                                        <MenuItem value="Sbitla">
                                            Sbitla
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        {/* <TextField
                            fullWidth
                            label="location"
                            name="occupation"
                            value={"tunisia,sfax"}
                            InputProps={{
                                startAdornment: (
                                    <LocationOnIcon color="disabled" />
                                ),
                                maxLength: 140,
                            }}
                            style={{ marginTop: "18px" }}
                        />

                        <TextField
                            fullWidth
                            disabled
                            InputProps={{
                                startAdornment: <LockIcon color="disabled" />,
                                maxLength: 140,
                            }}
                            placeholder="https://www.google.com/maps/place/Milwaukee,+WI"
                            sx={{
                                "& input": {
                                    cursor: "not-allowed",
                                },
                            }}
                            margin="normal"
                        />

                        <FormControlLabel
                            control={
                                <Android12Switch
                                    disabled
                                    checked={hideBranding}
                                    onChange={handleHideBrandingChange}
                                    name="hideBranding"
                                    color="primary"
                                />
                            }
                        />

                        <Typography
                            sx={{ color: "rgba(20, 43, 58, 0.5)" }}
                            variant="p"
                        >
                            Hide iDigix<sup>™</sup> branding{" "}
                            <span
                                style={{
                                    backgroundColor: "#ee705e",
                                    color: "white",
                                    colorAdjust: "exact",
                                    WebkitPrintColorAdjust: "exact",
                                    display: "inline-flex",
                                    borderRadius: "10px",
                                    height: "1.25rem",
                                    minWidth: "20px",
                                    padding: "4px 6px",
                                    fontSize: ".75rem",
                                    fontWeight: "700",
                                    lineHeight: "1",
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                    verticalAlign: "baseline",
                                }}
                            >
                                Pro
                            </span>{" "}
                        </Typography> */}

                        <Stack
                            direction="row"
                            spacing="1vh"
                            style={{
                                marginTop: "30px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Android12Switch
                                        checked={search}
                                        onChange={handleSearchChange}
                                        name="Search"
                                        color="primary"
                                    />
                                }
                            />
                            <Typography sx={{ color: "rgba(20, 43, 58, 0.5)" }}>
                                Search
                            </Typography>
                        </Stack>
                    </CardContent>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ margin: "10px 21px 20px " }}
                        disabled={isSaveButtonActive}
                    >
                        <span
                            style={{
                                marginRight: "10px",
                            }}
                        >
                            Save profile
                        </span>

                        <SaveIcon />
                    </Button>
                </Card>
            </Suspense>
            {User.category == "Student" && (
                <Suspense fallback={<CircularProgress />}>
                    <AddCvDialog />
                </Suspense>
            )}
            <Suspense fallback={<CircularProgress />}>
                <Card
                    sx={{
                        display: isVisible ? "flex" : "none",
                        marginTop: "20px",
                    }}
                >
                    <Box
                        sx={{
                            objectFit: "cover",
                            height: "1px",
                            width: "50%",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Suspense fallback={<CircularProgress />}>
                            <img src="https://cdn.campsite.bio/packs/media/images/ads/pro-users-vertical-d1963e70a48a6ffa660708ef6da45271.png" />
                        </Suspense>
                    </Box>
                    <CardContent>
                        <IconButton
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                            aria-label="close"
                            onClick={handlePremuimSectionClosse}
                        >
                            <Suspense fallback={<CircularProgress />}>
                                <CloseIcon />
                            </Suspense>
                        </IconButton>
                        <Typography gutterBottom variant="h6" component="div">
                            Join the Premium
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon
                                        sx={{ fontSize: "20px" }}
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{ fontSize: "16px" }}>
                                        Automate your links with the feed and
                                        image grid. Pull in your latest content
                                        automatically.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon
                                        sx={{ fontSize: "20px" }}
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{ fontSize: "16px" }}>
                                        Connect your own domain.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon
                                        sx={{ fontSize: "20px" }}
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{ fontSize: "16px" }}>
                                        Get access to analytics data to analyze
                                        your performanceListItem.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon
                                        sx={{ fontSize: "20px" }}
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{ fontSize: "16px" }}>
                                        Unlock premium link types, like the
                                        opt-in form and feed.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate("/admin/bundles");
                            }}
                        >
                            Join Premium
                        </Button>
                    </CardContent>
                </Card>
            </Suspense>

            {/* <Suspense fallback={<CircularProgress />}>
                <Card sx={{ marginTop: "20px" }}>
                    <CardHeader title="Select a Theme" />
                    <CardContent>
                        <Box sx={{ p: 4 }}>
                            <Grid container spacing={1} justifyContent="center">
                                {themes.map((theme, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={5}
                                        sm={3}
                                        md={4}
                                        lg={3}
                                    >
                                        <Card>
                                            <CardActionArea
                                                onClick={() =>
                                                    handleCardClick(index)
                                                }
                                                disabled={theme.upgrade}
                                            >
                                                <Box
                                                    sx={{
                                                        height: 200,
                                                        background: theme.color,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        borderRadius: "inherit",
                                                        border:
                                                            index ===
                                                            clickedCardIndex
                                                                ? "2px solid #ee705e"
                                                                : "2px solid #E0E0E0",
                                                        transition:
                                                            "border-color 0.3s",
                                                        "&:hover": {
                                                            borderColor:
                                                                "#ee705e",
                                                        },
                                                        position: "relative",
                                                        pointerEvents:
                                                            theme.upgrade
                                                                ? "none"
                                                                : "auto",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRadius:
                                                                "inherit",
                                                            alignItems:
                                                                "center",
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            height: "100%",
                                                            width: "100%",
                                                            backgroundSize:
                                                                "cover",
                                                            justifyContent:
                                                                "center",
                                                            position:
                                                                "relative",
                                                        }}
                                                    >
                                                        <span
                                                            className="theme-bg__btn"
                                                            style={{
                                                                background:
                                                                    "rgb(100, 125, 94)",
                                                                margin: "0.25rem 0",
                                                                width: "75%",
                                                                height: "20px",
                                                            }}
                                                        ></span>
                                                        <span
                                                            className="theme-bg__btn"
                                                            style={{
                                                                background:
                                                                    "rgb(100, 125, 94)",
                                                                margin: "0.25rem 0",
                                                                width: "75%",
                                                                height: "20px",
                                                            }}
                                                        ></span>
                                                        <span
                                                            className="theme-bg__btn"
                                                            style={{
                                                                background:
                                                                    "rgb(100, 125, 94)",
                                                                margin: "0.25rem 0",
                                                                width: "75%",
                                                                height: "20px",
                                                            }}
                                                        ></span>
                                                    </div>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        justifyContent:
                                                            "center",
                                                        textAlign: "center",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        {theme.name}
                                                    </Typography>
                                                    {theme.upgrade && (
                                                        <Button
                                                            sx={{
                                                                height: "22px",
                                                                minWidth:
                                                                    "22px",
                                                                lineHeight: 0,
                                                                borderRadius:
                                                                    "8px",
                                                                alignItems:
                                                                    "center",
                                                                whiteSpace:
                                                                    "nowrap",
                                                                justifyContent:
                                                                    "center",
                                                                padding:
                                                                    "0px 8px",
                                                                color: "#fff",
                                                                fontSize:
                                                                    "0.75rem",
                                                                backgroundColor:
                                                                    "#ee705e",
                                                                fontWeight: 700,
                                                                zIndex: 9,
                                                                top: "5px",
                                                                left: "5px",
                                                                position:
                                                                    "absolute",
                                                            }}
                                                        >
                                                            Upgrade
                                                            <LockIcon
                                                                sx={{
                                                                    fontSize:
                                                                        "0.75rem",
                                                                }}
                                                            />
                                                        </Button>
                                                    )}
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Suspense> */}
        </Grid>
    );
}
