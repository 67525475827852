import React, { useState, useEffect } from "react";
import {
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Avatar,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify";
import PhotoSelector from "./PhotoSelector";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Register3.css";

const Register3 = ({ onNext, onPrevious, data }) => {
    const [formData, setFormData] = useState(data);
    const [showPassword, setShowPassword] = useState(false);
    const [strength, setStrength] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "password") validatePassword(value);
    };

    const handlePhotoSelect = (photoDataUrl) => {
        setFormData((prevData) => ({
            ...prevData,
            profilePicture: photoDataUrl,
        }));
    };

    const handlePrevious = (e) => {
        e.preventDefault();
        onPrevious(formData);
    };

    const handleNext = (e) => {
        e.preventDefault();

        validateEmail(formData.email);
        validatePassword(formData.password);

        if (!emailError && !passwordError) {
            onNext(formData);
        } else {
            toast.error(`Please validate your information `, {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };

    const validateEmail = (email) => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailError(isValidEmail ? "" : "Invalid email");
    };

    const validatePassword = (password) => {
        let strengthIndicator = -1;
        let upper = false,
            lower = false,
            numbers = false,
            firstname = false,
            lastname = false,
            username = false,
            specialChars = false;

        if (/[A-Z]/.test(password)) upper = true;
        if (/[a-z]/.test(password)) lower = true;
        if (/\d/.test(password)) numbers = true;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password))
            specialChars = true;
        if (
            formData.password
                .toLowerCase()
                .trim()
                .includes(formData.firstName.toLowerCase().trim())
        )
            firstname = true;
        if (
            formData.password
                .toLowerCase()
                .trim()
                .includes(formData.lastName.toLowerCase().trim())
        )
            lastname = true;
        if (
            formData.password
                .toLowerCase()
                .trim()
                .includes(formData.userName.toLowerCase().trim())
        )
            username = true;

        if (password.length >= 8) {
            strengthIndicator++;
            if (!firstname && !lastname && !username && password.length > 5)
                strengthIndicator++;
        }
        if (upper && lower && numbers && specialChars) strengthIndicator++;

        setStrength(["weak", "medium", "strong"][strengthIndicator] || "");

        switch (true) {
            case password.length < 8:
                setPasswordError(
                    "Password must be at least 8 characters long."
                );
                break;
            case firstname || lastname:
                setPasswordError(
                    "Password must not match your first or last name."
                );
                break;
            case username:
                setPasswordError(
                    "Password must not match your unique user name."
                );
                break;
            case !specialChars:
                setPasswordError(
                    "Password must contain at least one special character."
                );
                break;
            case !upper:
                setPasswordError(
                    "Password must contain at least one upper case character."
                );
                break;
            case !lower:
                setPasswordError(
                    "Password must contain at least one lower case character."
                );
                break;
            case !numbers:
                setPasswordError("Password must contain numbers.");
                break;
            default:
                setPasswordError("");
                break;
        }
    };

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!emailError}
                    helperText={emailError}
                    aria-invalid={!!emailError}
                    aria-describedby="email-error"
                />
                <TextField
                    id="password"
                    name="password"
                    label="Password"
                    value={formData.password}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Iconify icon="eva:eye-fill" />
                                    ) : (
                                        <Iconify icon="eva:eye-off-fill" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChange}
                    error={!!passwordError}
                    helperText={passwordError}
                    aria-invalid={!!passwordError}
                    aria-describedby="password-error"
                />
                <div className={`bars ${strength}`}>
                    <div></div>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <div className="photo-selector-label">
                            Profile Picture
                        </div>
                        <Avatar
                            src={formData.profilePicture}
                            alt="User Profile Photo"
                        />
                        <PhotoSelector onSelect={handlePhotoSelect} />
                    </div>
                </div>
            </Stack>

            <Typography variant="body2" sx={{ mb: 5 }}>
                Already have an account?{" "}
                <a href="/login" color="primary">
                    Sign in
                </a>
            </Typography>

            <LoadingButton
                className="me-5"
                size="large"
                type="submit"
                variant="contained"
                onClick={handlePrevious}
            >
                Previous
            </LoadingButton>
            <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={handleNext}
                disabled={
                    !!emailError ||
                    !!passwordError ||
                    strength.trim() !== "strong"
                }
            >
                Next
            </LoadingButton>
            <ToastContainer />
        </>
    );
};

export default Register3;
