import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography, Box } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";

// sections
import { SignUpForm } from "../sections/auth/signup";
import Logo from "../components/Logo";

const StyledRoot = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const StyledSection = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
    marginLeft: "50px",
}));

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SingUpPage() {
    const mdUp = useResponsive("up", "md");

    return (
        <>
            <Helmet>
                <title>Sign Up | idigics.com</title>
            </Helmet>

            <StyledRoot>
                <Box sx={{ px: 3, py: 2, display: "inline-flex" }}>
                    <Logo />
                </Box>

                {mdUp && (
                    <StyledSection>
                        <img
                            src="/assets/illustrations/signup1.svg"
                            alt="login"
                            style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "80%",
                            }}
                        />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                marginBottom: "40px",
                                color: "text.secondary",
                            }}
                        >
                            Sign up for idigics.com
                        </Typography>

                        <SignUpForm />
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
