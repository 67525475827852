import axios from "axios";
import Cookies from "js-cookie";
import { RefreshToken, setAuthTokens } from "./AuthenticationData.ts";
import { BASE_URL } from "./Context/config";

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.request.use(
    async (config) => {
        const authToken = Cookies.get("authToken");

        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            originalRequest.url !== "/Login"
        ) {
            originalRequest._retry = true;

            const refreshToken = Cookies.get("refreshToken");
            console.log("RefreshToken from client =", refreshToken);

            if (refreshToken) {
                try {
                    const response = await RefreshToken();
                    console.log("RefreshToken from SERVER =", response);

                    if (
                        response &&
                        response.data &&
                        response.data.accessToken
                    ) {
                        console.log("===== Setting TOKENS =====");
                        setAuthTokens(
                            response.data.accessToken,
                            response.data.refreshToken,
                            response.data.ExpiresIn
                        );
                        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
                        console.log("The API did refresh the token");
                        return axios(originalRequest);
                    } else {
                        console.error("No new refreshed token from the server");
                    }
                } catch (refreshError) {
                    console.error("Failed to refresh token:", refreshError);
                }
            } else {
                return Promise.reject({ redirectToLogin: true });
            }
        }

        return Promise.reject(error);
    }
);

export default api;
