import { React, useEffect } from "react";

const RegisterConfirm = ({ onSubmit }) => {
    useEffect(() => {
        onSubmit();
    }, []);
    return <></>;
};

export default RegisterConfirm;
