import { styled } from "@mui/material/styles";
import { Container, Typography, Box } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { LoginForm } from "../sections/auth/login";
import "../Css/loginpage.css";
import Logo from "../components/Logo";

const StyledRoot = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const StyledSection = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
    marginLeft: "50px",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "60vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(4, 0), // Adjust padding for better spacing on mobile
    [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
    },
}));

export default function LoginPage() {
    const mdUp = useResponsive("up", "md");

    return (
        <>
            <title> Login | iDigix ™ </title>

            <StyledRoot>
                <Box sx={{ px: 3, py: 2, display: "inline-flex" }}>
                    <Logo />
                </Box>

                {mdUp && (
                    <StyledSection>
                        <img
                            src="/assets/illustrations/illustration_login.svg"
                            alt="login"
                            style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "80%",
                            }}
                        />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                marginBottom: "40px",
                                color: "text.secondary",
                            }}
                        >
                            Sign in to iDigix ™
                        </Typography>

                        <LoginForm />
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
