import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Button, Box } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import React from "react";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: "#ee705e",
    background: `linear-gradient(135deg, ${alpha("#ee705e", 0)} 0%, ${alpha(
        "#ee705e",
        0.24
    )} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppBundleWidget({
    title,
    icon,
    data,
    amount,
    reference,
    article,
    setOpenDialog,
    setReference,
    setAmount,
}) {
    const isFreeBundle = title === "Free";

    const handleOpen = (reference, amount) => {
        setOpenDialog(true);
        setReference(reference);
        setAmount(amount);
    };

    return (
        <>
            <Card
                sx={{
                    py: 5,
                    textAlign: "center",
                    color: "#ee705e",
                    boxShadow: "0.2rem 0.5rem 0.8rem rgba(20, 43, 58, 0.1)",
                    height: "46rem",
                }}
            >
                {/* article */}
                <div
                    style={{
                        background: "#ee705e",
                        color: "white",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "0 2em",
                        textAlign: "center",
                        transform:
                            "translateY(-100%) rotate(90deg) translateX(70.7106781187%) rotate(-45deg)",
                        transformOrigin: "bottom right",
                    }}
                >
                    {article}
                </div>

                <StyledIcon>{icon}</StyledIcon>

                <Typography
                    sx={{
                        textAlign: "center",
                    }}
                    variant="h4"
                >
                    {title}
                </Typography>

                <Typography
                    sx={{
                        padding: "10px",
                        fontWeight: "500",
                        marginBottom: "20px",
                        color: isFreeBundle
                            ? "rgba(20, 43, 58, 0.5)"
                            : undefined, // Set color based on condition
                    }}
                    variant="h5"
                >
                    {amount}
                    <sup>dt</sup>
                    <Typography
                        variant="subtitle1"
                        component="span"
                        fontSize={16}
                        color="rgba(20, 43, 58, 0.5)"
                    >
                        /Month
                    </Typography>
                </Typography>

                {data.map((x, index) => {
                    const trimmedX = x.trim();
                    if (trimmedX === "") return null;

                    const isInclude = trimmedX
                        .toLowerCase()
                        .startsWith("include");
                    const iconColor = isInclude ? "#78b627" : "#c42f29";
                    const textDecoration = isInclude ? "none" : "line-through";
                    const text = isInclude
                        ? trimmedX.substring("include".length).trim()
                        : trimmedX;

                    const IconComponent = isInclude
                        ? DoneOutlinedIcon
                        : ClearOutlinedIcon;

                    return (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "12px",
                                alignItems: "center",
                                marginLeft: "30px", // Align items vertically
                            }}
                            key={index}
                        >
                            <IconComponent
                                sx={{
                                    borderRadius: "50%",
                                    fontSize: 14,
                                    color: iconColor,
                                    background: `linear-gradient(135deg, ${alpha(
                                        iconColor,
                                        0
                                    )} 0%, ${alpha(iconColor, 0.24)} 100%)`,
                                    height: 20,
                                    width: 20,
                                    padding: "3px",
                                }}
                            />
                            <Typography
                                fontSize={14}
                                color="text.secondary"
                                fontWeight={500}
                                sx={{
                                    px: "20px",
                                    color: isInclude
                                        ? "text.secondary"
                                        : "rgba(20, 43, 58, 0.5)",
                                    textDecoration: textDecoration,
                                }}
                            >
                                {text}
                            </Typography>
                        </Box>
                    );
                })}

                {!isFreeBundle && (
                    <Button
                        onClick={() => {
                            handleOpen(reference, amount);
                        }}
                        color="primary"
                        variant="outlined"
                        size="large"
                        sx={{
                            marginTop: "35px",
                            backgroundColor: "#ee705e",
                            color: "white",
                            "&:hover": {
                                color: "#ee705e",
                            },
                        }}
                    >
                        Purchase this bundle
                    </Button>
                )}
            </Card>
        </>
    );
}
