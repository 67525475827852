import React, { useRef } from "react";
import { Button } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";

const PhotoSelector = ({ onSelect }) => {
    const fileInputRef = useRef(null);
    const maxSize = 1024 * 1024;

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size > maxSize) {
                alert(
                    "File size exceeds the 1MB limit. Please select a smaller file."
                );
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                onSelect(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <Button
                variant="contained"
                onClick={handleButtonClick}
                style={{
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                    border: "none",
                    padding: "0",
                    fontSize: "20px",
                    backgroundColor: "transparent",
                    color: "#ff715b",
                }}
            >
                <i className="bi bi-plus-circle-fill"></i>
            </Button>
        </>
    );
};

export const FileSelector = ({ onSelect }) => {
    const fileInputRef = useRef(null);
    const maxSize = 1024 * 1024;

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.type != "application/pdf") {
                alert("Invalid file type. Only PDF are allowed.");
                return;
            }

            if (file.size > maxSize) {
                alert(
                    "File size exceeds the 1MB limit. Please select a smaller file."
                );
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                onSelect(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <Button
                variant="contained"
                onClick={handleButtonClick}
                color="primary"
            >
                <span
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Upload
                </span>
                <PostAddIcon />
            </Button>
        </>
    );
};

export default PhotoSelector;
