import { styled } from "@mui/system";
import { formatFloatToOneDecimal } from "../../../utils/formatNumber";
import { keyframes } from "@emotion/react";
import {
    Grid,
    Rating,
    Box,
    Typography,
    LinearProgress,
    Stack,
} from "@mui/material";

// ----------------------------------------------------------------------

const RatingStyle = styled(Rating)(({ theme }) => ({
    marginBottom: "5vh",
    fontSize: "calc(8vh + 1rem)",
}));

const GridStyle = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
}));

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const pulsateAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

const glowAnimation = keyframes`
  0% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.5), 0 0 10px rgba(255, 0, 0, 0.5), 0 0 15px rgba(255, 0, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.8), 0 0 15px rgba(255, 0, 0, 0.8), 0 0 20px rgba(255, 0, 0, 0.8);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.5), 0 0 10px rgba(255, 0, 0, 0.5), 0 0 15px rgba(255, 0, 0, 0.5);
  }
`;

function ProgressItem({ star }) {
    const { name, starCount } = star;
    return (
        <Stack direction="row" alignItems="center" spacing="3vh">
            <Typography
                variant="subtitle2"
                sx={{
                    display: "flex",
                    width: "35%",
                    justifyContent: "left",
                    fontSize: "2.8vh",
                    fontWeight: "Bold",
                }}
            >
                {name}
            </Typography>
            <LinearProgress
                variant="determinate"
                value={(starCount / 5) * 100}
                sx={{
                    flexGrow: 1,
                    bgcolor: "divider",
                    height: "1.5vh",
                }}
            />
            <Typography
                variant="subtitle3"
                sx={{
                    color: "text.secondary",
                    minWidth: "40px",
                    fontSize: "3vh",
                }}
            >
                {formatFloatToOneDecimal(starCount)}
            </Typography>
        </Stack>
    );
}

const RatingDetailsReview = ({ product }) => {
    const { totalRating, Ratings, rateCount } = product;
    return (
        <Box>
            <Grid container>
                <GridStyle item xs={6} md={6} lg={6}>
                    <Typography
                        sx={{
                            color: "error.main",
                            fontSize: "27vh",
                            fontWeight: "bold",
                            animation: `${pulsateAnimation} 4s infinite`,
                        }}
                    >
                        {formatFloatToOneDecimal(totalRating)}/5
                    </Typography>
                    <RatingStyle readOnly value={totalRating} precision={0.1} />
                    <Typography
                        variant="body1"
                        sx={{
                            color: "text.secondary",
                            fontSize: "4vh",
                        }}
                    >
                        ({rateCount}&nbsp;reviews)
                    </Typography>
                </GridStyle>

                <GridStyle item xs={6} md={6} lg={6} marginTop="7vh">
                    <Stack spacing="6vh" sx={{ width: "90%" }}>
                        {Ratings.map((rating) => (
                            <ProgressItem key={rating.name} star={rating} />
                        ))}
                    </Stack>
                </GridStyle>
            </Grid>
        </Box>
    );
};

export default RatingDetailsReview;
