import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PutContact {
    Category: string;
    ContactInfo: string;
    isPublic: boolean;
}

export interface PostContact {
    UserId: string;
    ContactInfo: string;
    Category: string;
    isPublic: boolean;
}

export const EditContact = async (newContact: PutContact) => {
    const data = {
        Category: newContact.Category,
        ContactInfo: newContact.ContactInfo,
        isPublic: newContact.isPublic,
    };

    if (newContact.isPublic === true) data.isPublic = true;
    else data.isPublic = false;

    try {
        const response = await api.put(
            `${BASE_URL}/Contact/EditContact`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.log("Error:", error.message);
    }
};

export const DeleteContact = async (Id: number) => {
    try {
        const response = await api.delete(
            `${BASE_URL}/Contact/DeleteContact/` + Id,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const CreateContact = async (newContact: PostContact) => {
    const data = {
        UserId: newContact.UserId,
        Category: newContact.Category || "CvFile",
        ContactInfo: newContact.ContactInfo,
        isPublic: newContact.isPublic,
    };

    if (newContact.isPublic === true) data.isPublic = true;
    else data.isPublic = false;

    try {
        const response = await api.post(
            `${BASE_URL}/Contact/CreateContact`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.error("Error creating contact:", error);
    }
};
