import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Typography, Container, Box, Button } from "@mui/material";
import SeverErrorIllustration from "../components/SeverErrorIllustration";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

const VerifyMail = () => {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>IDigix | Verify Email</title>
            </Helmet>
            <Box sx={{ px: 3, py: 2, display: "inline-flex" }}>
                <Logo />
            </Box>
            <Container sx={{ marginTop: "-100px" }}>
                <StyledContent
                    sx={{ textAlign: "center", alignItems: "center" }}
                >
                    <Typography variant="h3" paragraph color="primary">
                        Verify Your Email
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                        please check your email and click the link we just sent
                        you.
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                        this email may come in the SPAM section !
                    </Typography>

                    <SeverErrorIllustration
                        sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                    />
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => {
                            navigate("/Login");
                        }}
                    >
                        Go to login page
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
};

export default VerifyMail;
