import { useState } from "react";
import {
    Card,
    Stack,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Divider,
    CardHeader,
    CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";

export default function CheckoutReserved({
    onApply,
    ShowCouponSection,
    onClose,
}) {
    const [coupon, setCoupon] = useState("");
    const [showCouponSection, setShowCouponSection] = useState(
        ShowCouponSection ? ShowCouponSection : false
    );

    const handleApplyClick = async () => {
        if (!coupon) {
            toast.error("Please enter a valid coupon serial key", {
                position: "top-center",
                autoClose: 1000,
            });
        } else {
            await onApply(coupon);
            toast.success("Coupon reserved successfully", {
                position: "top-center",
                autoClose: 1000,
            });
            if (ShowCouponSection) {
                onClose();
                setCoupon("");
            }
        }
    };

    const handleAddCouponClick = () => {
        setShowCouponSection(true);
    };

    return (
        <Card sx={{ mb: 3 }}>
            <CardHeader
                title="Order Reserved Coupon"
                subheader="Customer-provided Coupon for the Client's Evaluation of the Service Rendered"
            />

            <CardContent>
                <Stack spacing={2}>
                    <Divider />

                    {showCouponSection && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography variant="subtitle1">
                                    Coupon :
                                </Typography>
                                <Box sx={{ textAlign: "right" }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ color: "error.main" }}
                                    >
                                        {/* Here you can display the coupon code */}
                                        {coupon ? " " : "No coupon applied"}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{ fontStyle: "italic" }}
                                    >
                                        (Used only one time)
                                    </Typography>
                                </Box>
                            </Stack>

                            <TextField
                                fullWidth
                                placeholder="Coupons codes /rates"
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                type="button"
                                                onClick={handleApplyClick}
                                                sx={{ mr: -0.5 }}
                                            >
                                                Apply
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}
                    {!showCouponSection && (
                        <Button
                            onClick={handleAddCouponClick}
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                        >
                            Add Coupon
                        </Button>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
