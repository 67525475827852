import { React, useEffect, useState } from "react";
import { Grid, Avatar, Typography, Stack, Paper, Box } from "@mui/material";
import RatingDetailsReviewTrack from "../sections/@dashboard/Rating/RatingDetailsReviewTrack";
import { GetProfilesFromLink } from "../ProfileData.ts";
import { useNavigate } from "react-router-dom";
import { PROFILE_URL } from "../Context/config";
import QRCode from "qrcode.react";

export const Track = () => {
    const navigate = useNavigate();
    const [isPcScreen, setIsPcScreen] = useState(true); // Track if the screen is PC size
    const [Account, setAccount] = useState({
        email: "",
        firstName: "",
        id: 0,
        lastName: "",
        category: "",
        profile: {
            birthDate: "",
            customLinks: null,
            gender: "",
            id: 0,
            isPremium: false,
            occupation: "",
            premium: null,
            profileCoverPicture: "",
            profilePicture: "",
            profilePictureFrame: 0,
            socialLinks: null,
            user: null,
            userId: 0,
            userName: "",
        },
        auth: {
            id: 0,
            password: "",
            user: null,
            userId: 8,
        },
        rate: 0,
        rateCount: 0,
        rate_Skill_QualityOfWork: 0,
        rate_Skill_CostEffectiveness: 0,
        rate_Skill_Timeliness: 0,
        rate_Skill_Communication: 0,
        rate_Skill_Agility: 0,
    });

    const Ratings = [
        {
            name: "Quality Of Work",
            starCount: Account.rate_Skill_QualityOfWork - 0.3,
        },
        {
            name: "Cost Effectiveness",
            starCount: Account.rate_Skill_CostEffectiveness - 0.3,
        },
        {
            name: "Timeliness",
            starCount: Account.rate_Skill_Timeliness - 0.3,
        },
        {
            name: "Communication",
            starCount: Account.rate_Skill_Communication - 0.3,
        },
        {
            name: "Agility",
            starCount: Account.rate_Skill_Agility - 0.3,
        },
    ];

    useEffect(() => {
        const fetchData = () => {
            fetchProfileData();
        };

        // Initial fetch
        fetchData();

        // Execute fetch every minute
        const intervalId = setInterval(fetchData, 60000);

        const mediaQuery = window.matchMedia(
            "(min-width: 900px) and (min-height: 768px)"
        );

        const handleMediaQueryChange = (event) => {
            setIsPcScreen(event.matches); // Update state based on media query match
        };

        handleMediaQueryChange(mediaQuery);
        mediaQuery.addEventListener("change", handleMediaQueryChange);

        // Cleanup function to remove event listener and clear interval
        return () => {
            clearInterval(intervalId);
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);

    const fetchProfileData = async () => {
        const currentPath = window.location.pathname;
        const searchQueryPart = currentPath.substring(
            currentPath.lastIndexOf("/") + 1
        );
        try {
            const response = await GetProfilesFromLink(searchQueryPart);
            setAccount(response.data);
        } catch (error) {
            navigate("/404");
        }
    };

    return isPcScreen ? (
        <div className="wrapper1">
            <div
                className="background-track-overlay"
                style={{
                    backgroundImage: "url(/assets/images/covers/cover_4.jpg)",
                }}
            ></div>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={12} lg={12}>
                    <Stack
                        direction="row"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5vh",
                            paddingRight: "5vh",
                            paddingLeft: "8vh",
                        }}
                    >
                        <a
                            className="logo-link"
                            href="/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span className="Tracklogo">iDigix</span>
                        </a>

                        <Paper
                            elevation={4}
                            style={{ padding: "15px", borderRadius: "10px" }}
                        >
                            <QRCode
                                value={`${PROFILE_URL}/Profile/${Account.profile.userName}`}
                                size={150}
                            />
                        </Paper>

                        <Stack
                            direction="row"
                            spacing="1vh"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Avatar
                                alt="Profile"
                                src={Account.profile.profilePicture}
                                sx={{ width: "12vh", height: "12vh" }}
                            />
                            <Stack direction="column">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: "3.5vh",
                                        fontWeight: 1000,
                                        color: "white",
                                    }}
                                >
                                    {Account.firstName} {Account.lastName}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "text.secondary",
                                        fontSize: "2vh",
                                    }}
                                >
                                    @{Account.profile.userName}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <hr
                    style={{
                        width: "100%",
                        border: "none",
                        height: "1px",
                        backgroundColor: "#ccc",
                        margin: "20px 0",
                    }}
                />
                <Grid item xs={12} md={12} lg={12}>
                    <RatingDetailsReviewTrack
                        product={{
                            totalRating: Account.rate,
                            Ratings,
                            rateCount: Account.rateCount,
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    ) : (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                textAlign: "center",
                flexDirection: "column",
                backgroundColor: "#f5f5f5",
                color: "#333",
                padding: 3,
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 700,
                    mb: 2,
                }}
            >
                This page must be opened on a PC screen with a resolution of at
                least 1024x768.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: "#666",
                    fontWeight: 300,
                }}
            >
                Please adjust your screen settings or use a PC to view this
                content.
            </Typography>
        </Box>
    );
};
