import React, { createContext, useContext, useState, useEffect } from "react";
import { PostSearch, GetProfileFromSearch } from "../SearchData.ts";
import { useProfile } from "./ProfileContext";

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const { profile } = useProfile();
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchSearchResults = async (query) => {
        setLoading(true);
        setError(null);
        try {
            const response = await GetProfileFromSearch(query);
            setSearchResults(response.data);
            await PostSearch({
                UserId: profile.id,
                Query: query,
                Date: new Date(),
            });
        } catch (err) {
            setError("Error fetching search results");
        } finally {
            setLoading(false);
        }
    };

    return (
        <SearchContext.Provider
            value={{
                searchResults,
                loading,
                error,
                fetchSearchResults,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};
