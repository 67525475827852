import { Helmet } from "react-helmet-async";
import { useCallback, useState, lazy, startTransition, Suspense } from "react";

import { Grid, Typography, CircularProgress } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
    Card,
    Stack,
    Button,
    Box,
    CardContent,
    Container,
    CardHeader,
    Tab,
    Tabs,
} from "@mui/material";

import { GetDeposits, GetPurchases } from "../PurchasesData.ts";
import { useEffect } from "react";

import AppPurchasesTab from "../sections/@dashboard/app/AppPurchasesTab";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useProfile } from "../Context/ProfileContext";
import { fDateTime } from "../utils/formatTime";

const AppDepositsTab = lazy(() =>
    import("../sections/@dashboard/app/AppDepositsTab")
);

const AccountSettings = lazy(() =>
    import("../sections/@dashboard/Account/AccountSettings")
);

const reservdlist = [
    {
        id: 1,
        name: "John Doe",
        createdAt: "2024-03-04",
        tokenId: "abc123dfsfsdfdssfq",
    },
    {
        id: 2,
        name: "Jane Smith",
        createdAt: "2024-03-03",
        tokenId: "def456dfdsfsdfsdfdsf",
    },

    // Add more user objects as needed
];

// ----------------------------------------------------------------------

const invoices = [
    { id: 1, createdAt: "2023-01-20", price: 100 },
    { id: 2, createdAt: "2023-02-19", price: 150 },
    { id: 3, createdAt: "2023-03-18", price: 200 },
    // Add more invoice objects as needed
];
const fDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
};

const fCurrency = (amount) => {
    return `$${amount.toFixed(2)}`; // Format currency as needed
};

export const SettingsPage = () => {
    const { profile } = useProfile();
    const navigate = useNavigate();
    const [PurchasesPage, setPurchasesPage] = useState(0);
    const [Deposits, setDeposits] = useState([]);
    const [Purchases, setPurchases] = useState([]);
    const [PurchasesRowsPerPage, setPurchasesRowsPerPage] = useState(5);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handlePurchasesPageChange = useCallback((event, value) => {
        setPurchasesPage(value);
    }, []);

    const handlePurchasesRowsPerPageChange = useCallback((event) => {
        setPurchasesRowsPerPage(event.target.value);
    }, []);

    useEffect(() => {
        startTransition(() => {
            fetchDeposits();
        });
        startTransition(() => {
            fetchPurchases();
        });
    }, []);

    const fetchDeposits = async () => {
        const response = await GetDeposits();
        setDeposits(
            response.data.map((deposit) => {
                deposit.date = fDateTime(deposit.date);
                return deposit;
            })
        );
    };
    const fetchPurchases = async () => {
        const response = await GetPurchases();
        setPurchases(
            response.data.map((purchase) => {
                purchase.date = fDateTime(purchase.date);
                return purchase;
            })
        );
    };

    return (
        <Container sx>
            <Helmet>
                <title> Idigix | Settings </title>
            </Helmet>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Settings
            </Typography>
            <Box>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Account tabs"
                >
                    <Tab label="Account" />
                    <Tab label="Budget" />
                </Tabs>
            </Box>

            {activeTab === 0 && (
                <Suspense callback={<CircularProgress />}>
                    <AccountSettings />
                </Suspense>
            )}

            {activeTab === 1 && (
                <Container sx={{ marginTop: "25px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8}>
                            {profile.category && (
                                <Card sx={{ p: 3, marginBottom: "30px" }}>
                                    <Typography
                                        variant="overline"
                                        sx={{
                                            mb: 3,
                                            display: "block",
                                            color: "text.secondary",
                                        }}
                                    >
                                        Your Plan
                                    </Typography>
                                    <Typography variant="h4">
                                        {profile.category}
                                    </Typography>
                                    {profile.category != "Freelance" &&
                                        profile.category != "Enterprise" && (
                                            <Box
                                                sx={{
                                                    mt: { xs: 2, sm: 0 },
                                                    position: {
                                                        sm: "absolute",
                                                    },
                                                    top: { sm: 24 },
                                                    right: { sm: 24 },
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        navigate(
                                                            "/admin/bundles"
                                                        );
                                                    }}
                                                >
                                                    Upgrade plan
                                                </Button>
                                            </Box>
                                        )}
                                </Card>
                            )}
                            <Card>
                                <CardHeader
                                    title="Deposits"
                                    subheader="This table provides a comprehensive history of deposit transactions for tracking purposes."
                                />
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <Suspense
                                            callback={<CircularProgress />}
                                        >
                                            <AppDepositsTab
                                                deposits={Deposits}
                                            />
                                        </Suspense>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card sx={{ marginTop: "30px" }}>
                                <CardHeader
                                    title="Purchases"
                                    subheader="This table serves as a record of past purchases, allowing you to track your purchases history."
                                />
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <Suspense
                                            callback={<CircularProgress />}
                                        >
                                            <AppPurchasesTab
                                                purchases={Purchases}
                                                onPageChange={
                                                    handlePurchasesPageChange
                                                }
                                                onRowsPerPageChange={
                                                    handlePurchasesRowsPerPageChange
                                                }
                                                page={PurchasesPage}
                                                rowsPerPage={
                                                    PurchasesRowsPerPage
                                                }
                                            />
                                        </Suspense>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            {/* <Card>
                                <CardContent>
                                    <Stack spacing={3} alignItems="flex-end">
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ width: 1 }}
                                        >
                                            Invoice History
                                        </Typography>

                                        <Stack spacing={2} sx={{ width: 1 }}>
                                            {invoices.map((invoice) => (
                                                <Stack
                                                    key={invoice.id}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    sx={{ width: 1 }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ minWidth: 160 }}
                                                    >
                                                        {fDate(
                                                            invoice.createdAt
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {fCurrency(
                                                            invoice.price
                                                        )}
                                                    </Typography>
                                                    <RouterLink
                                                        to="#"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            color: "#ff715b",
                                                        }}
                                                    >
                                                        PDF
                                                    </RouterLink>
                                                </Stack>
                                            ))}
                                        </Stack>

                                        <Button
                                            size="small"
                                            endIcon={<ArrowForwardIcon />}
                                        >
                                            All invoices
                                        </Button>
                                    </Stack>
                                </CardContent>
                            </Card> */}
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Container>
    );
};

export default SettingsPage;
