import { React, useEffect, useState } from "react";
import "../Css/style1.css"; // Import the CSS file for applying styles
// @mui
import {
    Grid,
    Button,
    Menu,
    MenuItem,
    Dialog,
    Paper,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Tabs,
    IconButton,
    Tab,
    Avatar,
} from "@mui/material";
import axios from "axios";
import InstagramIcon from "@mui/icons-material/Instagram";
import RatingDialog from "./RatingDialog";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IosShareIcon from "@mui/icons-material/IosShare";
import XIcon from "@mui/icons-material/X";
import QRCode from "qrcode.react";
import { checkAuthToken } from "../AuthenticationData.ts";
import { AppLinksByPublicProfile } from "../sections/@dashboard/app";
import { GetProfilesFromLink, GetProfile } from "../ProfileData.ts";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import CheckoutReserved from "../sections/@dashboard/Coupons/CheckoutReserved";
import { useProfile } from "../Context/ProfileContext";
import { UseCoupon, ReserveCoupon } from "../CouponsData.ts";
import { useNavigate } from "react-router-dom";
import { PostView } from "../AnalyticsData.ts";
import RatingDetailsReview from "../sections/@dashboard/Rating/RatingDetailsReview";
import ProfileAbout from "./ProfileAbout";
import { useRef } from "react";

const Profile = () => {
    const hasRun = useRef(false);
    const { profile, fetchProfile } = useProfile();
    const [SerialKey, setSerialKey] = useState(null);
    const [Account, setAccount] = useState({
        email: "",
        firstName: "",
        id: 0,
        lastName: "",
        category: "",
        profile: {
            birthDate: "",
            customLinks: null,
            gender: "",
            id: 0,
            isPremium: false,
            occupation: "",
            premium: null,
            profileCoverPicture: "",
            profilePicture: "",
            profilePictureFrame: 0,
            socialLinks: null,
            user: null,
            userId: 0,
            userName: "",
        },
        auth: {
            id: 0,
            password: "",
            user: null,
            userId: 8,
        },
        rate: 0,
        rateCount: 0,
        rate_Skill_QualityOfWork: 0,
        rate_Skill_CostEffectiveness: 0,
        rate_Skill_Timeliness: 0,
        rate_Skill_Communication: 0,
        rate_Skill_Agility: 0,
    });
    const Ratings = [
        {
            name: "Quality Of Work",
            starCount: Account.rate_Skill_QualityOfWork - 0.3,
        },
        {
            name: "Cost Effectiveness",
            starCount: Account.rate_Skill_CostEffectiveness - 0.3,
        },
        {
            name: "Timeliness",
            starCount: Account.rate_Skill_Timeliness - 0.3,
        },
        {
            name: "Communication",
            starCount: Account.rate_Skill_Communication - 0.3,
        },
        {
            name: "Agility",
            starCount: Account.rate_Skill_Agility - 0.3,
        },
    ];

    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        if (
            newValue != 0 &&
            (Account.category == "Freelance" ||
                Account.category == "Enterprise")
        )
            newValue++;
        setActiveTab(newValue);
    };

    const [SocialLinks, setSocialLinks] = useState([]);
    const [CustomLinks, setCustomLinks] = useState([]);

    const [sameAccount, setSameAccount] = useState(false);

    const navigate = useNavigate();

    const handleApplyCoupon = async (coupon) => {
        try {
            const response = await ReserveCoupon(coupon);
            if (response.error) throw new Error(response.error);

            toast.success("Coupon reserved successfuly", {
                position: "top-center",
                autoClose: 1000,
            });
            return response;
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };

    async function copyToClipboard(text) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement("textarea");
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                const successful = document.execCommand("copy");
                if (successful) {
                    console.log("Text copied to clipboard (fallback)");
                } else {
                    console.error("Fallback: Unable to copy text");
                }
            } catch (err) {
                console.error("Failed to copy text with fallback: ", err);
            }
            document.body.removeChild(textarea);
        }
    }

    const handleViewCount = async (profile, profileToView) => {
        if (hasRun.current) return;
        try {
            const viewData = {
                userId: profileToView.id,
                date: new Date(),
            };

            // If the user is logged in and views someone else's profile
            if (profile && profile.id && profile.id !== profileToView.id) {
                viewData.gender = profile.profile?.gender ?? null;
                viewData.country = profile.profile?.country ?? "Tunisia";
                await PostView(viewData);
                hasRun.current = true;
            }

            // If the user is not logged in and views a profile
            if (profile == null) {
                await PostView(viewData);
                hasRun.current = true;
            }
        } catch (error) {
            console.error("Error posting view:", error);
        }
    };

    useEffect(() => {
        const storedSerialKey = localStorage.getItem("serialKey");
        if (storedSerialKey) {
            setSerialKey(storedSerialKey);
            setOpenRatingDialog(true);
        }
        const fetchData = () => {
            fetchProfileData();
        };

        // Initial fetch
        fetchData();

        // Execute fetch every minute
        const intervalId = setInterval(fetchData, 15000);

        return () => {
            clearInterval(intervalId);
        };
    }, [profile]);

    useEffect(() => {
        const runOnce = async () => {
            try {
                // Check if the user is authenticated
                const isAuthenticated = checkAuthToken();

                // If the user is authenticated, ensure their profile is fetched
                if (isAuthenticated && (!profile || !profile.id)) {
                    await fetchProfile();
                }

                const currentPath = window.location.pathname;
                const searchQueryPart = currentPath.substring(
                    currentPath.lastIndexOf("/") + 1
                );

                // Fetch the profile associated with the current URL
                const response = await GetProfilesFromLink(searchQueryPart);
                const profileToView = response.data;

                // Determine if the authenticated user matches the profile being viewed
                if (isAuthenticated) {
                    setSameAccount(profile.id === profileToView.id);
                } else {
                    setSameAccount(false);
                }

                // Handle view count logic
                await handleViewCount(
                    isAuthenticated ? profile : null,
                    profileToView
                );
            } catch (error) {
                console.error("Error in runOnce:", error);
            }
        };

        runOnce();
    }, [profile]);

    const fetchProfileData = async () => {
        const currentPath = window.location.pathname;
        const searchQueryPart = currentPath.substring(
            currentPath.lastIndexOf("/") + 1
        );
        try {
            const response = await GetProfilesFromLink(searchQueryPart);
            if (response.error) {
                throw new Error(response.error);
            }

            setAccount(response.data);
            setSocialLinks(response.data.profile.socialLinks);
            setCustomLinks(response.data.profile.customLinks);
        } catch (error) {
            navigate("/404");
        }
    };

    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [copyDialogOpen, setCopyDialogOpen] = useState(false);

    const [copyConfirmed, setCopyConfirmed] = useState(false);

    const handleClose = () => {
        setAnchorEl();
    };

    const [openQRDialog, setOpenQRDialog] = useState(false);

    const handleQRDialogOpen = () => {
        setOpenQRDialog(true);
    };

    const handleQRDialogClose = () => {
        setOpenQRDialog(false);
    };
    const handleDownloadQRCode = async () => {
        const currentURL = window.location.href;
        const apiKey =
            "SyqyiqtYqfympFt_2YY5dDYk4Tkbqpwt0GVHUWTefWpkXm-TYH8xsP3fKJNDhy6W";

        try {
            const requestData = {
                data: currentURL,
                config: {
                    body: "circle",
                    logo: "YOUR_LOGO_URL",
                },
                size: 200,
                file: "png",
            };

            const response = await axios.post(
                "https://api.qr-code-generator.com/v1/create",
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.downloadUri) {
                // Create an <a> element to trigger the download
                const downloadLink = document.createElement("a");
                downloadLink.href = response.data.downloadUri;
                downloadLink.download = "qrcode.png"; // Set the filename for the downloaded file
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            } else {
                console.error(
                    "Failed to generate QR code:",
                    response.data.error
                );
            }
        } catch (error) {
            console.error("Error downloading QR code:", error);
        }
    };

    const backgroundStyle = {
        backgroundImage: `url(${Account.profile.profileCoverPicture})`,
    };

    const [openRatingDialog, setOpenRatingDialog] = useState(
        SerialKey ? true : false
    );
    const [openReserveDialog, setOpenReserveDialog] = useState(false);

    const handleSendRate = async (data) => {
        try {
            const response = await UseCoupon(data);
            if (response.error) throw new Error(response.error);

            localStorage.removeItem("serialKey");
            setSerialKey(null);
            toast.success("Rate sent successfully", {
                position: "top-center",
                autoClose: 1000,
            });
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 1000,
            });
        }
        fetchProfile();
    };

    return (
        <div className="wrapper">
            <div className="background-overlay" style={backgroundStyle}></div>
            <Grid
                sx={{
                    textAlign: "center",
                    fontSize: "13px",
                }}
            >
                {/* Profile Picture */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Avatar
                        alt="Profile"
                        src={Account.profile.profilePicture}
                        sx={{
                            border: "solid white",
                            width: 130,
                            height: 130,
                            marginBottom: "2rem",
                        }}
                    />
                </div>
                {/* QR view */}
                <div>
                    <Paper
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                            padding: "8px",
                            boxShadow: "0 0.2rem 0.5rem rgba(20, 43, 58, 0.50)",
                            position: "absolute",
                            background: "#f5f5f5f5",
                            borderRadius: "50%",
                            right: "15px",
                            top: "20px",
                            color: "#212121",
                            transition: "transform 0.3s ease-in-out",
                            "&:hover": {
                                transform: "scale(1.05)", // Apply scale transformation on hover
                            },
                        }}
                    >
                        <IosShareIcon />
                    </Paper>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={handleQRDialogOpen}>
                            QR view
                        </MenuItem>
                        {/* <MenuItem onClick={handleCopyDialogOpen}>Copy</MenuItem>{" "} */}
                    </Menu>
                </div>
                {/* QR view */}
                <Dialog open={openQRDialog} onClose={handleQRDialogClose}>
                    <DialogTitle>QR Code</DialogTitle>
                    <DialogContent>
                        {/* Generate the QR code using the 'qrcode.react' component */}
                        <QRCode value={window.location.href} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleQRDialogClose}>Close</Button>
                        {/* <Button onClick={handleDownloadQRCode}>Download</Button> */}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={copyDialogOpen}
                    onClose={() => {
                        setCopyDialogOpen(false);
                        setCopyConfirmed(false); // Reset copy confirmation
                    }}
                >
                    <DialogTitle>Copy URL</DialogTitle>
                    <DialogContent>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <span>{window.location.href}</span>
                            {copyConfirmed ? (
                                <i
                                    className="bi bi-check-circle"
                                    style={{
                                        color: "green",
                                        marginLeft: "5px",
                                    }}
                                ></i>
                            ) : (
                                <Button
                                    onClick={() => {
                                        copyToClipboard(window.location.href);
                                    }}
                                >
                                    <i className="bi bi-files"></i>
                                </Button>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCopyDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* profile name */}
                <h1 className="profile-name">
                    {Account.firstName} {Account.lastName}
                </h1>
                {/* Username */}
                <p
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    @{Account.profile.userName}
                    <br />
                    {Account.profile.occupation}
                </p>
                {/* Links */}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        padding: "10px",
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="Account tabs"
                    >
                        <Tab
                            label="Links"
                            sx={{
                                color: "#f0f0f0",
                            }}
                        />

                        {Account.category === "Student" && (
                            <Tab
                                label="About"
                                sx={{
                                    color: "#f0f0f0",
                                }}
                            />
                        )}
                        {(Account.category === "Freelance" ||
                            Account.category === "Enterprise") && (
                                <Tab
                                    label="Rating"
                                    sx={{
                                        color: "#f0f0f0",
                                    }}
                                />
                            )}
                    </Tabs>
                </Box>
                <>
                    <hr
                        style={{
                            fontSize: "20px",
                            fontWeight: "20px",
                            marginBottom: "50px",
                        }}
                    />
                    {activeTab === 0 && (
                        <div className="tab-content" id="nav-tabContent">
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginTop: "2rem",
                                }}
                            >
                                <AppLinksByPublicProfile
                                    title="Social links"
                                    subheader="Here are your custom links"
                                    type="socialLinks"
                                    AccountId={Account.id}
                                    profile={profile}
                                    sameAccount={sameAccount}
                                    country={profile?.country}
                                    list={SocialLinks.map(
                                        ({ title, linkUrl, category, id }) => {
                                            let iconn;
                                            let color;
                                            switch (category) {
                                                case "Twitter":
                                                    iconn = <XIcon />;
                                                    color = "#0f7ae5"; // Darker shade of blue
                                                    break;
                                                case "GitHub":
                                                case "Phone":
                                                    iconn = <GitHubIcon />;
                                                    color = "#333333"; // Dark gray
                                                    break;
                                                case "Instagram":
                                                    iconn = <InstagramIcon />;
                                                    color = "#8a1679"; // Darker shade of purple
                                                    break;
                                                case "Facebook":
                                                    iconn = <FacebookIcon />;
                                                    color = "#1e3d6f"; // Darker shade of blue
                                                    break;
                                                case "LinkedIn":
                                                    iconn = <LinkedInIcon />;
                                                    color = "#00668e"; // Darker shade of blue
                                                    break;
                                                default:
                                                    iconn = null;
                                                    color = "#333333"; // Default dark gray
                                            }

                                            return {
                                                key: id,
                                                name: title,
                                                body: linkUrl,
                                                icon: iconn,
                                                color: color,
                                            };
                                        }
                                    )}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginTop: "3rem",
                                }}
                            >
                                <AppLinksByPublicProfile
                                    title="Custom links"
                                    subheader="Here are your social links"
                                    type="customLinks"
                                    profile={profile}
                                    sameAccount={sameAccount}
                                    country={profile?.country}
                                    list={CustomLinks.map((link) => {
                                        return {
                                            key: link.id,
                                            name: link.title,
                                            body: link.linkUrl,
                                            color: link.colors,
                                            icon: link.icon,
                                        };
                                    })}
                                />
                            </Grid>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <ProfileAbout profile={profile} account={Account} />
                    )}

                    {activeTab === 2 && (
                        <RatingDetailsReview
                            product={{
                                totalRating: Account.rate,
                                Ratings,
                                rateCount: Account.rateCount,
                            }}
                            onOpenRatingDialog={() => setOpenRatingDialog(true)}
                            onOpenReserveDialog={() =>
                                setOpenReserveDialog(true)
                            }
                            DifferentAccount={
                                !profile || profile.id !== Account?.id
                            }
                        />
                    )}
                </>
            </Grid>
            {openRatingDialog && (
                <RatingDialog
                    onClose={() => {
                        setOpenRatingDialog(false);
                    }}
                    onClick={handleSendRate}
                    openDialog={openRatingDialog}
                    serialKey={SerialKey}
                />
            )}
            {openReserveDialog && (
                <Dialog open={openReserveDialog} fullWidth maxWidth="sm">
                    <DialogContent>
                        <IconButton
                            onClick={() => {
                                setOpenReserveDialog(false);
                            }}
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CheckoutReserved
                            onApply={handleApplyCoupon}
                            ShowCouponSection={true}
                            onClose={() => {
                                setOpenReserveDialog(false);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}

            <footer
                style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button>
                    <span className="logo" onClick={() => navigate("/signup")}>
                        iDigix™{" "}
                    </span>
                </Button>
            </footer>
            <ToastContainer />
        </div>
    );
};

export default Profile;
