import PropTypes from "prop-types";

import Edit2FillIcon from "@mui/icons-material/EditTwoTone";
import { styled } from "@mui/system";
import { formatFloatToOneDecimal } from "../../../utils/formatNumber";

import {
    Grid,
    Rating,
    Button,
    Box,
    Typography,
    LinearProgress,
    Stack,
} from "@mui/material";

// ----------------------------------------------------------------------

const RatingStyle = styled(Rating)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const GridStyle = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "&:nth-of-type(2)": {
        [theme.breakpoints.up("md")]: {
            borderLeft: `solid 1px ${theme.palette.divider}`,
            borderRight: `solid 1px ${theme.palette.divider}`,
        },
    },
}));

ProgressItem.propTypes = {
    star: PropTypes.object,
    total: PropTypes.number,
};

function ProgressItem({ star }) {
    const { name, starCount } = star;
    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1.2}
                sx={{ width: 1 }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", width: 125, justifyContent: "left" }}
                >
                    {name}
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={(starCount / 5) * 100}
                    sx={{
                        flexGrow: 1,
                        bgcolor: "divider",
                    }}
                />
                <Typography
                    variant="subtitle3"
                    sx={{ color: "text.secondary", minWidth: 40 }}
                >
                    {formatFloatToOneDecimal(starCount)}
                </Typography>
            </Stack>
        </>
    );
}

const RatingDetailsReview = ({
    product,
    onOpenRatingDialog,
    onOpenReserveDialog,
    DifferentAccount,
}) => {
    const { totalRating, Ratings, rateCount } = product;
    return (
        <Box
            sx={{
                p: 3,
                borderRadius: "20px",
                backgroundColor: "rgba(250, 250, 250, 0.7)",
                WebkitBackdropFilter: "blur(50px)",
                backdropFilter: "blur(50px)",
            }}
        >
            <Grid container>
                <GridStyle item xs={12} md={6}>
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{ color: "error.main" }}
                    >
                        {formatFloatToOneDecimal(totalRating)}/5
                    </Typography>
                    <RatingStyle readOnly value={totalRating} precision={0.1} />
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                    >
                        ({rateCount}&nbsp;reviews)
                    </Typography>
                </GridStyle>

                <GridStyle item xs={12} md={6}>
                    <Stack spacing={1.5} sx={{ width: 1 }}>
                        {Ratings.map((rating) => (
                            <ProgressItem key={rating.name} star={rating} />
                        ))}
                    </Stack>
                </GridStyle>

                {DifferentAccount && (
                    <GridStyle item xs={12} md={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1.5}
                            sx={{ width: 1 }}
                        >
                            <Button
                                size="large"
                                variant="contained"
                                startIcon={<Edit2FillIcon />}
                                onClick={onOpenRatingDialog}
                                fullWidth
                            >
                                Give Rate
                            </Button>

                            <Button
                                size="large"
                                variant="outlined"
                                startIcon={<Edit2FillIcon />}
                                onClick={onOpenReserveDialog}
                                fullWidth
                            >
                                Reserve Coupon
                            </Button>
                        </Stack>
                    </GridStyle>
                )}
            </Grid>
        </Box>
    );
};

export default RatingDetailsReview;
