import PropTypes from "prop-types";
import { Box, Paper, Typography, Avatar, Grid } from "@mui/material";
import { toast } from "react-toastify";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyContent from "../Coupons/EmptyContent";

AppLinksByProfile.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    list: PropTypes.array.isRequired,
};

export default function AppLinksByProfile({
    type,
    list,
    onDelete,
    onEdit,
    ProfileCardVisible,
}) {
    const handleEdit = (link) => {
        if (ProfileCardVisible) ProfileCardVisible(false);
        onEdit(link);
    };
    return (
        <Box>
            {list.length == 0 ? (
                <EmptyContent
                    description={`Looks like you have no ${type}  yet.`}
                    img="/assets/illustrations/illustration_empty_content.svg"
                />
            ) : type == "socialLinks" ? (
                list.map((link, Id) => (
                    <Paper
                        variant="outlined"
                        sx={{
                            padding: "0.8rem 1.5rem",
                            position: "relative",
                            borderRadius: "10px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0.6rem 0",
                            marginTop: "15px",
                            boxShadow:
                                "0.1rem 0.2rem 0.5rem rgba(80, 40, 10, 0.2)",
                            transition: "transform 0.3s ease-in-out",
                        }}
                        key={Id}
                    >
                        <Box color={link.Color} fontSize="18px">
                            {link.Icon}
                        </Box>

                        <Typography
                            sx={{
                                color: "rgba(20, 43, 58, 1)",
                                fontWeight: 600,
                                margin: "auto",
                            }}
                        >
                            {link.Title}
                        </Typography>

                        <Grid>
                            <a onClick={() => handleEdit(link)}>
                                <AutoFixHighIcon
                                    sx={{
                                        marginRight: "10px",
                                        "&:hover": {
                                            color: "#ff715b",
                                            fontSize: "27px",
                                        },
                                    }}
                                />
                            </a>
                            <a onClick={() => onDelete(link.Id)}>
                                <DeleteIcon
                                    sx={{
                                        "&:hover": {
                                            color: "#ff715b",
                                            fontSize: "27px",
                                        },
                                    }}
                                />
                            </a>
                        </Grid>
                    </Paper>
                ))
            ) : (
                list.map((link, Id) => (
                    <Paper
                        sx={{
                            padding: "0.8rem 1.5rem",
                            position: "relative",
                            borderRadius: "10px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0.6rem 0",
                            marginTop: "15px",
                            boxShadow:
                                "0.1rem 0.2rem 0.5rem rgba(80, 40, 10, 0.2)",
                            transition: "transform 0.3s ease-in-out",
                            "&::after": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                width: "5px",
                                backgroundColor: "#ff715b",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                            },
                        }}
                        key={Id}
                    >
                        <Box>
                            <Avatar
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "60%",
                                    boxShadow:
                                        "0 0.4rem 0.8rem rgba(20, 43, 58, 0.25)",
                                }}
                                src={link.Icon}
                                alt="User Profile Photo"
                            />
                        </Box>

                        <Typography
                            sx={{
                                color: "rgba(20, 43, 58, 1)",
                                fontWeight: 600,
                                fontSize: "15px",
                                margin: "auto",
                            }}
                        >
                            {link.Title}
                        </Typography>

                        <Grid>
                            <a onClick={() => handleEdit(link)}>
                                <AutoFixHighIcon
                                    sx={{
                                        marginRight: "10px",
                                        "&:hover": {
                                            color: "#ff715b",
                                            fontSize: "27px",
                                        },
                                    }}
                                />
                            </a>
                            <a onClick={() => onDelete(link.Id)}>
                                <DeleteIcon
                                    sx={{
                                        "&:hover": {
                                            color: "#ff715b",
                                            fontSize: "27px",
                                        },
                                    }}
                                />
                            </a>
                        </Grid>
                    </Paper>
                ))
            )}
        </Box>
    );
}
