import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Typography, Stack, IconButton, Popover } from "@mui/material";

import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";

// ----------------------------------------------------------------------

const LANGS = [
    {
        value: "en",
        label: "English",
        icon: "/assets/icons/ic_flag_en.svg",
    },
];

// ----------------------------------------------------------------------

export default function PalastinePopover() {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        bgcolor: (theme) =>
                            alpha(
                                theme.palette.primary.main,
                                theme.palette.action.focusOpacity
                            ),
                    }),
                }}
            >
                <img src="/assets/icons/ic_flag_pl.svg" alt="Free Palastine" />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 300,
                        "& .MuiMenuItem-root": {
                            px: 1,
                            typography: "body2",
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Stack
                    spacing={2}
                    alignItems="center"
                    sx={{ textAlign: "center", p: 2 }}
                >
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Typography variant="h6" marginRight={1}>
                            Free Palestine
                        </Typography>
                        <FamilyRestroomIcon color="primary" fontSize="small" />
                    </Box>
                    <Typography variant="body2">
                        We stand in solidarity with the people of Palestine. Our
                        thoughts and prayers are with them during these
                        difficult times. May peace and justice prevail.
                    </Typography>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        mt={2}
                        p={1}
                        sx={{ backgroundColor: "#f0f0f0", borderRadius: 1 }}
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mr: 1 }}
                        >
                            #FreePalestine
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mr: 1 }}
                        >
                            #KeepTalking
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mr: 1 }}
                        >
                            #Gaza
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mr: 1 }}
                        >
                            #SavePalastine
                        </Typography>
                    </Box>
                </Stack>
            </Popover>
        </>
    );
}
