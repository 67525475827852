import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PostSocialLinkData {
    ProfileId: number;
    LinkUrl: string;
    Category: string;
    Title: string;
    Color: string;
}

export interface PostCustomLinkData {
    ProfileId: number;
    LinkUrl: string;
    Title: string;
    Icon: string;
}

export interface PutCustomLinkData {
    Id: number;
    ProfileId: number;
    LinkUrl: string;
    Title: string;
    Icon: string;
}

export interface PutSocialLinkData {
    Id: number;
    ProfileId: number;
    LinkUrl: string;
    Title: string;
    Category: string;
    Color: string;
}

export const CreateSocialLink = async (link: PostSocialLinkData) => {
    const data = {
        ProfileId: link.ProfileId,
        LinkUrl: link.LinkUrl,
        Category: link.Category,
        Title: link.Title,
        Color: link.Color,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.post(
            `${BASE_URL}/Links/CreateSocialLink`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const GetSocialLinks = async () => {
    try {
        var authToken = getAuthToken();

        const response = await api.get(`${BASE_URL}/Links/GetSocialLinks`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const EditSocialLink = async (link: PutSocialLinkData) => {
    const data = {
        Id: link.Id,
        ProfileId: link.ProfileId,
        Title: link.Title,
        LinkUrl: link.LinkUrl,
        Category: link.Category,
        Color: link.Color,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.put(
            `${BASE_URL}/Links/EditSocialLink`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const DeleteSocialLink = async (Id: number) => {
    try {
        var authToken = getAuthToken();
        const response = await api.delete(
            `${BASE_URL}/Links/DeleteSocialLink/` + Id,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const CreateCustomLink = async (link: PostCustomLinkData) => {
    const data = {
        ProfileId: link.ProfileId,
        LinkUrl: link.LinkUrl,
        Title: link.Title,
        Icon: link.Icon,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.post(
            `${BASE_URL}/Links/CreateCustomLink`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const GetCustomLinks = async () => {
    try {
        var authToken = getAuthToken();

        const response = await api.get(`${BASE_URL}/Links/GetCustomLinks`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const EditCustomLink = async (link: PutCustomLinkData) => {
    const data = {
        Id: link.Id,
        Title: link.Title,
        ProfileId: link.ProfileId,
        LinkUrl: link.LinkUrl,
        Icon: link.Icon,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.put(
            `${BASE_URL}/Links/EditCustomLink`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const DeleteCustomLink = async (Id: number) => {
    try {
        var authToken = getAuthToken();
        const response = await api.delete(
            `${BASE_URL}/Links/DeleteCustomLink/` + Id,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export function getAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return cookie.substring("authToken=".length, cookie.length);
        }
    }

    return null;
}
