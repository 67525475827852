import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, lazy, Suspense, startTransition } from "react";
import {
    GetClicks,
    GetViews,
    calculateDailyClicks,
    calculateCountryClicks,
    calculateGenderClicks,
    calculateDailyViews,
    MostClickedCountry,
    MostViewedCountry,
    GetCategoryClickCounts,
    getClickCountsByCategory,
} from "../AnalyticsData.ts";
import { Container, Grid, Typography, CircularProgress } from "@mui/material";

// Lazy load components
const AppWidgetSummary = lazy(() =>
    import("../sections/@dashboard/app/AppWidgetSummary")
);
const AppConversionRates = lazy(() =>
    import("../sections/@dashboard/app/AppConversionRates")
);
const AppCurrentVisits = lazy(() =>
    import("../sections/@dashboard/app/AppCurrentVisits")
);
const AppWebsiteVisits = lazy(() =>
    import("../sections/@dashboard/app/AppWebsiteVisits")
);

const EmptyContent = lazy(() =>
    import("../sections/@dashboard/Coupons/EmptyContent.js")
);

const AppTasks = lazy(() => import("../sections/@dashboard/app/AppTasks"));

export default function AnalyticsPage() {
    const theme = useTheme();

    const [Clicks, setClicks] = useState([]);
    const [Views, setViews] = useState([]);
    const [Daily, setDaily] = useState([]);
    const [CategoryClicks, setCategoryClicks] = useState([]);
    const [Country, setCountry] = useState([]);
    const [Gender, setGender] = useState([]);
    const [LinksTotalCount, setLinksTotalCount] = useState([]);
    const [DailyViews, setDailyViews] = useState([]);

    useEffect(() => {
        startTransition(() => {
            fetchClicksData();
            fetchViewsData();
        });
    }, []);

    const fetchClicksData = async () => {
        try {
            const response = await GetClicks();
            setClicks(response.data);
            setDaily(calculateDailyClicks(response.data));
            setCountry(calculateCountryClicks(response.data));
            setGender(calculateGenderClicks(response.data));
            setCategoryClicks(getClickCountsByCategory(response.data));
            setLinksTotalCount(GetCategoryClickCounts(response.data));
        } catch (error) {
            console.error("Error fetching Clicks data:", error);
        }
    };

    const fetchViewsData = async () => {
        try {
            const response = await GetViews();
            setViews(response.data);
            setDailyViews(calculateDailyViews(response.data));
        } catch (error) {
            console.error("Error fetching Views data:", error);
        }
    };

    return (
        <Container>
            <Helmet>
                <title> IDigix | Analytics </title>
            </Helmet>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Analytics
            </Typography>

            <Suspense fallback={<CircularProgress />}>
                <Container maxWidth="xl">
                    {Views.length == 0 || Clicks.length == 0 ? (
                        <EmptyContent
                            title="No Analytics Data Available"
                            description="It looks like there hasn't been any traffic yet. As soon as you start getting clicks and views, your analytics will populate here."
                            img="/assets/illustrations/illustration_empty_cart.svg"
                        />
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppWidgetSummary
                                        title="Total CLicks"
                                        total={Clicks.reduce(
                                            (total, click) =>
                                                total + click.clickCount,
                                            0
                                        )}
                                        color={"info"}
                                        icon={"ant-design:edit-filled"}
                                    />
                                </Suspense>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppWidgetSummary
                                        title="Total Views"
                                        total={Views.reduce(
                                            (total, view) =>
                                                total + view.viewsCount,
                                            0
                                        )}
                                        color="error"
                                        icon={"ant-design:eye-filled"}
                                    />
                                </Suspense>
                            </Grid>

                            {/* <Grid item xs={12} sm={6} md={4}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppWidgetSummary
                                        title="Most Viewed Country"
                                        total={
                                            MostClickedCountry(Clicks) || "-"
                                        } // Set "-" as the default value
                                        color="success"
                                        icon={"ant-design:flag-filled"}
                                    />
                                </Suspense>
                            </Grid> */}

                            <Grid item xs={12} md={6} lg={8}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppConversionRates
                                        title="Daily Views"
                                        subheader="Analyze the daily views to understand the trends and patterns in the number of views your content receives.Gain valuable insights into the most active days and make informed decisions based on this data."
                                        chartData={DailyViews.map((item) => ({
                                            label: item.date,
                                            value: item.totalClicks,
                                        }))}
                                    />
                                </Suspense>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppCurrentVisits
                                        title="Countries"
                                        subheader="Discover the top countries where your links"
                                        chartData={Country.map((item) => ({
                                            label: item.country,
                                            value: item.totalClicks,
                                        }))}
                                        chartColors={[
                                            theme.palette.success.main,
                                            theme.palette.warning.main,
                                            theme.palette.text.secondary,
                                            theme.palette.info.main,
                                            theme.palette.primary.main,
                                            theme.palette.error.main,
                                        ]}
                                    />
                                </Suspense>
                            </Grid>

                            <Grid item xs={12} md={6} lg={8}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppConversionRates
                                        title="Daily Engagement"
                                        subheader="Explore daily engagement metrics to track the interaction and activity of users with your content. Understand when your audience is most engaged, helping you optimize your strategies for higher user interaction and better overall engagement."
                                        chartData={Daily.map((item) => ({
                                            label: item.date,
                                            value: item.totalClicks,
                                        }))}
                                    />
                                </Suspense>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <Suspense fallback={<CircularProgress />}>
                                    <AppCurrentVisits
                                        title="Genders"
                                        subheader="Explore the distribution of link clicks based on gender"
                                        chartData={Gender.map((item) => ({
                                            label: item.gender,
                                            value: item.totalClicks,
                                        }))}
                                        chartColors={[
                                            theme.palette.error.main,
                                            theme.palette.secondary.main,
                                        ]}
                                    />
                                </Suspense>
                            </Grid>

                            {/* <Grid item xs={12} md={6} lg={8}>
                            <Suspense fallback={<CircularProgress />}>
                                <AppWebsiteVisits
                                    title="Website Visits"
                                    subheader="(+43%) than last year"
                                    chartLabels={[
                                        "01/01/2003",
                                        "02/01/2003",
                                        "03/01/2003",
                                        "04/01/2003",
                                        "05/01/2003",
                                        "06/01/2003",
                                        "07/01/2003",
                                        "08/01/2003",
                                        "09/01/2003",
                                        "10/01/2003",
                                        "11/01/2003",
                                    ]}
                                    chartData={[
                                        {
                                            name: "Team A",
                                            type: "column",
                                            fill: "solid",
                                            data: [
                                                23, 11, 22, 27, 13, 22, 37, 21,
                                                44, 22, 30,
                                            ],
                                        },
                                        {
                                            name: "Team B",
                                            type: "area",
                                            fill: "gradient",
                                            data: [
                                                44, 55, 41, 67, 22, 43, 21, 41,
                                                56, 27, 43,
                                            ],
                                        },
                                        {
                                            name: "Team C",
                                            type: "line",
                                            fill: "solid",
                                            data: [
                                                30, 25, 36, 30, 45, 35, 64, 52,
                                                59, 36, 39,
                                            ],
                                        },
                                    ]}
                                />
                            </Suspense>
                        </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={8}>
                            <Suspense fallback={<CircularProgress />}>
                                <AppTasks
                                    title="Tasks"
                                    list={[
                                        {
                                            id: "1",
                                            label: "Create FireStone Logo",
                                        },
                                        {
                                            id: "2",
                                            label: "Add SCSS and JS files if required",
                                        },
                                        {
                                            id: "3",
                                            label: "Stakeholder Meeting",
                                        },
                                        {
                                            id: "4",
                                            label: "Scoping & Estimations",
                                        },
                                        { id: "5", label: "Sprint Showcase" },
                                    ]}
                                />
                            </Suspense>
                        </Grid> */}
                        </Grid>
                    )}
                </Container>
            </Suspense>
        </Container>
    );
}
