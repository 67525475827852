import { useEffect, useState, lazy, Suspense, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { PurchaseBundle } from "../PurchasesData.ts";
import { useProfile } from "../Context/ProfileContext";
import { useBudget } from "../Context/BudgetContext";
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { toast } from "react-toastify";

// Lazy load toast container and toast
const ToastContainer = lazy(() =>
    import("react-toastify").then((module) => ({
        default: module.ToastContainer,
    }))
);

// Lazy load components that can be deferred
const EmojiPeopleIcon = lazy(() => import("@mui/icons-material/EmojiPeople"));
const ApartmentIcon = lazy(() => import("@mui/icons-material/Apartment"));
const BusinessCenterIcon = lazy(() =>
    import("@mui/icons-material/BusinessCenter")
);
const AppBundleWidget = lazy(() =>
    import("../sections/@dashboard/app").then((module) => ({
        default: module.AppBundleWidget,
    }))
);

export const BundlesPage = () => {
    const { profile, fetchProfile } = useProfile();
    const { budget, setBudget } = useBudget();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);

    const [reference, setReference] = useState("");
    const [amount, setAmount] = useState("");

    useEffect(() => {
        localStorage.setItem("isCardVisible", "true");
    }, []);

    const handlePurchase = async (ref, amount) => {
        try {
            await startTransition(async () => {
                if (budget < amount) {
                    toast.error(
                        "Your budget is not enough to make this purchase!",
                        {
                            position: "top-center",
                            autoClose: 1000,
                        }
                    );
                    return;
                }
                if (profile.category === ref) {
                    toast.error("You already have this bundle!", {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    return;
                }

                const response = await PurchaseBundle({
                    UserId: profile.id,
                    Reference: ref,
                    Date: new Date(),
                    Amount: amount,
                    Country: profile.profile.country,
                });

                if (response) {
                    setBudget(response.data.newAmount);
                    toast.success("Congratulations!", {
                        position: "top-center",
                        autoClose: 1000,
                    });
                }

                setOpenDialog(false);
                fetchProfile();
            });
        } catch (error) {
            if (error.redirectToLogin) {
                navigate("/Login");
            }
        }
    };

    return (
        <>
            <Container>
                <Helmet>
                    <title> IDigix | Bundles </title>
                </Helmet>
                <Typography variant="h5" sx={{ mb: 4 }}>
                    Bundles
                </Typography>
                <Grid container spacing={4}>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <Suspense fallback={<CircularProgress />}>
                            <AppBundleWidget
                                title="Free"
                                amount={0}
                                reference={"Free"}
                                icon={<EmojiPeopleIcon fontSize="large" />}
                                data={[
                                    "include Social Links (Up to 5)",
                                    "include Custom Links (1)",
                                    "Media Pictures (Up to 5)",
                                    "YouTube Integration (Links to videos)",
                                    "Enhanced Biography (About)",
                                    "Analytic Tracking (View performance)",
                                    "Rating System (coupons)",
                                ]}
                                isFreeBundle={true}
                            />
                        </Suspense>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={4}>
                        <Suspense fallback={<CircularProgress />}>
                            <AppBundleWidget
                                title="Student"
                                amount={25}
                                reference={"Student"}
                                icon={<BusinessCenterIcon fontSize="large" />}
                                data={[
                                    "include Social Links (Unlimited)",
                                    "include Custom Links (Up to 1)",
                                    "include Media Pictures (Up to 5)",
                                    "include YouTube Integration (Links to videos)",
                                    "include Enhanced Biography (About)",
                                    "Analytic Tracking (View performance)",
                                    "Rating Rating System (coupons)",
                                ]}
                                setOpenDialog={setOpenDialog}
                                setReference={setReference}
                                setAmount={setAmount}
                            />
                        </Suspense>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Suspense fallback={<CircularProgress />}>
                            <AppBundleWidget
                                title="Freelance"
                                amount={35}
                                reference={"Freelance"}
                                icon={<ApartmentIcon fontSize="large" />}
                                data={[
                                    "include Social Links (Unlimited)",
                                    "include Custom Links (Up to 1)",
                                    "include Media Pictures (Up to 5)",
                                    "include YouTube Integration (Links to videos)",
                                    "include Enhanced Biography (About)",
                                    "include Rating System (coupons)",
                                    "Analytic Tracking (View performance)",
                                ]}
                                setOpenDialog={setOpenDialog}
                                setReference={setReference}
                                setAmount={setAmount}
                            />
                        </Suspense>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Suspense fallback={<CircularProgress />}>
                            <AppBundleWidget
                                title="Enterprise"
                                amount={50}
                                reference={"Enterprise"}
                                article={"Recommended"}
                                icon={<ApartmentIcon fontSize="large" />}
                                data={[
                                    "include Social Links (Unlimited)",
                                    "include Custom Links (Up to 1)",
                                    "include Media Pictures (Up to 5)",
                                    "include YouTube Integration (Links to videos)",
                                    "include Enhanced Biography (About)",
                                    "include Analytic Tracking (View performance)",
                                    "include Rating System (coupons)",
                                ]}
                                setOpenDialog={setOpenDialog}
                                setReference={setReference}
                                setAmount={setAmount}
                            />
                        </Suspense>
                    </Grid>
                </Grid>
                <Suspense fallback={<CircularProgress />}>
                    <ToastContainer />
                </Suspense>
            </Container>
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
            >
                <DialogTitle color="primary">Purchase</DialogTitle>

                <DialogContent>
                    <Typography
                        sx={{
                            padding: "20px",
                            color: "text.secondary",
                        }}
                    >
                        Are you sure you want to purchase this bundle ?{" "}
                        {reference}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handlePurchase(reference, amount);
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
