import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import ContactSection from "../sections/@dashboard/Contact/ContactSection";

ProfileAbout.propTypes = {
    profile: PropTypes.object,
};

export default function ProfileAbout({ profile, account }) {
    return (
        <Box>
            <ContactSection account={account} />
        </Box>
    );
}
