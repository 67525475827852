import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "@mui/material";
import CheckoutReserved from "./CheckoutReserved.js";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
    Card,
    Box,
    CardHeader,
    CardContent,
    useTheme,
    Container,
    Typography,
    Stack,
    Avatar,
} from "@mui/material";

import { GetCustomerCoupons, ReserveCoupon } from "../../../CouponsData.ts";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";

import SimpleBar from "simplebar-react";

const Scrollbar = styled(SimpleBar)``;

const AvailableTableColumns = [
    {
        field: "ownerDetails",
        headerName: "Owner",
        width: 250,
        renderCell: (params) => (
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => {
                    window.open(
                        `/Profile/${params.row.ownerUserName}`,
                        "_blank"
                    );
                }}
            >
                <Avatar
                    alt={params.row.ownerFirstName}
                    src={params.row.ownerProfilePicture}
                />
                <div>
                    <Typography variant="subtitle2">
                        {params.row.ownerFirstName} {params.row.ownerLastName}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            color: "text.secondary",
                            mt: 0.5,
                            display: "block",
                        }}
                    >
                        {params.row.ownerUserName}
                    </Typography>
                </div>
            </Stack>
        ),
    },
    // { field: "reference", headerName: "Reference", width: 140 },
    { field: "serialKey", headerName: "Serial Key", width: 170 },
];

export const AppManageCoupons = () => {
    const [CustomerReservedCoupons, setCustomerReservedCoupons] = useState([]);
    const [CustomerUsedCoupons, setCustomerUsedCoupons] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomerCoupons();
    }, []);

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 5,
    });

    const handleApplyCoupon = async (coupon) => {
        try {
            const response = await ReserveCoupon(coupon);
            fetchCustomerCoupons();
            if (response.error) {
                throw new Error(response.error);
            }
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };

    const fetchCustomerCoupons = async () => {
        try {
            const response = await GetCustomerCoupons();

            const CustomerReservedCoupons = response.filter(
                (coupon) => coupon.isReserved && !coupon.isUsed
            );
            const CustomerUsedCoupons = response.filter(
                (coupon) => coupon.isReserved && coupon.isUsed
            );

            if (CustomerReservedCoupons.length > 0) {
                setCustomerReservedCoupons(CustomerReservedCoupons);
            }
            if (CustomerUsedCoupons.length > 0) {
                setCustomerUsedCoupons(CustomerUsedCoupons);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const theme = useTheme();

    return (
        <Container>
            <Helmet>
                <title> IDigix | Manage Coupons </title>
            </Helmet>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Manage Coupons
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader
                            title="Your Reserved Coupons"
                            subheader="Craft custom links to suit your needs You can easily add, edit, and delete links, logos, and URLs according to your preferences."
                        />
                        <CardContent>
                            <Scrollbar>
                                <Box sx={{ height: 370 }}>
                                    <DataGrid
                                        columns={AvailableTableColumns}
                                        rows={CustomerReservedCoupons}
                                        onRowClick={(o) => {
                                            localStorage.setItem(
                                                "serialKey",
                                                o.row.serialKey
                                            );
                                            navigate(
                                                `/Profile/${o.row.ownerUserName}`
                                            );
                                        }}
                                        sx={{
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: "none",
                                            },
                                            "& .MuiDataGrid-cell": {
                                                alignContent: "center",
                                            },
                                            border: "none",
                                            backgroundColor:
                                                theme.palette.common.white,
                                        }}
                                        paginationModel={pagination}
                                        paginationMode="client"
                                        onPaginationModelChange={setPagination}
                                    />
                                </Box>
                            </Scrollbar>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <CheckoutReserved onApply={handleApplyCoupon} />
                </Grid>
                <ToastContainer />
            </Grid>
        </Container>
    );
};

export default AppManageCoupons;
