import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Box, Card } from "@mui/material";
import SimpleBar from "simplebar-react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";

const Scrollbar = styled(SimpleBar)``;

const columns = [
    { field: "amount", headerName: "Amount" },
    { field: "country", headerName: "Country" },
    { field: "reference", headerName: "Reference", width: 110 },
    { field: "date", headerName: "Date", width: 180 },
];

export default function AppPurchasesTab({ purchases }) {
    const theme = useTheme();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 5,
    });

    return (
        <Card>
            <Scrollbar>
                <Box sx={{ height: 370 }}>
                    <DataGrid
                        columns={columns}
                        rows={purchases}
                        sx={{
                            "& .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                alignContent: "center",
                            },
                            border: "none",
                            backgroundColor: theme.palette.common.white,
                        }}
                        paginationModel={pagination}
                        paginationMode="client"
                        onPaginationModelChange={setPagination}
                    />
                </Box>
            </Scrollbar>
        </Card>
    );
}
