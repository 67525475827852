import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify";
import { Login } from "../../../AuthenticationData.ts";
import { ToastContainer, toast } from "react-toastify";
import { useProfile } from "../../../Context/ProfileContext";

export default function LoginForm() {
    const navigate = useNavigate();
    const { fetchProfile } = useProfile();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setEmailError("");
    };

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        setPasswordError("");
    };

    const validateForm = () => {
        let isValid = true;

        if (!email.trim()) {
            setEmailError("Email is required");
            isValid = false;
        }
        // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        //     setEmailError("Invalid email format");
        //     isValid = false;
        // }

        if (!password.trim()) {
            setPasswordError("Password is required");
            isValid = false;
        }

        return isValid;
    };

    const onSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const response = await Login({ email, password });

            if (response) {
                await fetchProfile();
                navigate("/admin/user", { replace: true });
                localStorage.setItem("isCardVisible", "true");
                localStorage.setItem("isLinksCardVisible", "true");
            } else {
                toast.error("Login failed. Please check your credentials.", {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error(
                "An error occurred while logging in. Please check your informations then try again.",
                {
                    position: "top-center",
                    autoClose: 1000,
                }
            );
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") onSubmit();
    };

    return (
        <>
            <Stack spacing={3} margin-top="">
                <TextField
                    id="email"
                    name="email"
                    label="Email address / user name"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                    onKeyPress={handleKeyPress}
                />

                <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!passwordError}
                    helperText={passwordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    <Iconify
                                        icon={
                                            showPassword
                                                ? "eva:eye-fill"
                                                : "eva:eye-off-fill"
                                        }
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onKeyPress={handleKeyPress}
                />
            </Stack>

            {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="right"
                sx={{ my: 2 }}
            >
                <Link variant="subtitle2" underline="hover">
                    Forgot password?
                </Link>
            </Stack> */}

            <Typography variant="body2" sx={{ mb: 2, mt: 5 }}>
                Don’t have an account? {""}
                <Link
                    href="/signup"
                    style={{
                        color: "silver",
                        marginLeft: "10px",
                        textDecoration: "none",
                    }}
                >
                    Get started
                </Link>
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
                <Link
                    href="/ForgotPasswordEmail"
                    style={{ color: "silver", textDecoration: "none" }}
                >
                    Forgot password ?
                </Link>
            </Typography>

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
            >
                Login
            </LoadingButton>
            <ToastContainer />
        </>
    );
}
