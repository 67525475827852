import React, { useState } from "react";
import {
  TextField,
  Grid,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Card,
  CardContent,
  IconButton,
  Box,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ImageIcon from "@mui/icons-material/Image";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import XIcon from "@mui/icons-material/X";
import Typography from "@mui/material/Typography";
import AppLinksByProfile from "../sections/@dashboard/app/AppLinksByProfile";
import { AppProfileCard } from "../sections/@dashboard/app";
import { EditProfile } from "../ProfileData.ts";
import {
  GetSocialLinks,
  EditCustomLink,
  EditSocialLink,
  CreateSocialLink,
  CreateCustomLink,
  DeleteSocialLink,
  GetCustomLinks,
  DeleteCustomLink,
} from "../LinkData.ts";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoSelector from "../sections/auth/signup/PhotoSelector";
import { useProfile } from "../Context/ProfileContext";
import PhoneLinkDialog from "../sections/@dashboard/Link/PhoneLinkDialog";

import Appearance from "./Appearance";
// import About from "./About";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ee705e",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ee705e",
  },
}));

const ProfileUser = () => {
  const { profile, fetchProfile } = useProfile();
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isProfileCardVisible, setIsProfileCardVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(true);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [User, setUser] = useState({
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    category: "",
    budget: 0.0,
  });

  const [Profile, setProfile] = useState({
    id: 0,
    userId: 0,
    userName: "",
    birthDate: "",
    gender: "",
    profilePicture: "",
    profileCoverPicture: "",
    profilePictureFrame: 0,
    occupation: "",
    isPremium: false,
    user: null,
    socialLinks: null,
    customLinks: null,
    premium: null,
    isSearch: null,
    country: null,
  });

  const [SocialLinks, setSocialLinks] = useState([]);

  const [CustomLinks, setCustomLinks] = useState([]);

  const [newSocialLink, setNewSocialLink] = useState({
    ProfileId: 0,
    LinkUrl: "",
    Category: "",
    Title: "",
    Color: "",
  });

  const [newCustomLink, setNewCustomLink] = useState({
    ProfileId: 0,
    LinkUrl: "",
    Title: "",
    Color: "Gray",
    Icon: null,
  });

  const [editCustomSectionVisible, setEditCustomSectionVisible] =
    useState(false);

  const [editedCustomLink, setEditedCustomLink] = useState({
    Id: 0,
    ProfileId: 0,
    LinkUrl: "",
    Title: "",
    Icon: null,
  });

  const [editSocialSectionVisible, setEditSocialSectionVisible] =
    useState(false);

  const [editedSocialLink, setEditedSocialLink] = useState({
    Id: 0,
    ProfileId: 0,
    Title: "",
    LinkUrl: "",
    Category: "",
    Color: "",
  });

  const [open, setOpen] = useState(false);
  const [CategoryChosen, setCategoryChosen] = useState(false);

  const handleClickOpen = (title, text, color) => {
    setOpen(true);
    setNewSocialLink((prevNewSocialLink) => ({
      ...prevNewSocialLink,
      Category: text,
      Color: color,
    }));
  };

  const socialLinks = [
    {
      platform: "Twitter",
      icon: "X",
      label: "Can you provide your Twitter account link?",
      dialogTitle: "Twitter link",
      color: "linear-gradient(to bottom,#0d90e0, #1DA1F2)",
    },
    {
      platform: "GitHub",
      icon: "GitHub",
      label: "Can you provide your GitHub account link?",
      dialogTitle: "GitHub link",
      color:
        "linear-gradient(112.1deg, rgb(63, 76, 119) 11.4%, rgb(32, 38, 57) 70.2%)",
    },
    {
      platform: "Instagram",
      icon: "Instagram",
      label: "Can you provide your Insta account link?",
      dialogTitle: "insta link",
      color: "linear-gradient(90deg, #f46f30, #c32aa3)",
    },
    {
      platform: "Facebook",
      icon: "Facebook",
      label: "Can you provide your Facebook account link?",
      dialogTitle: "facebook link",
      color: "linear-gradient(180deg, #1877f2, #3b5998)",
    },
    {
      platform: "LinkedIn",
      icon: "LinkedIn",
      label: "Can you provide your LinkedIn account link?",
      dialogTitle: "LinkedIn link",
      color: "linear-gradient(135deg, #0077B5, #00A0DC)",
    },
  ];

  const PhoneLinks = [
    {
      platform: "Phone",
      icon: "Phone",
      label: "Can you provide your Phone number?",
      dialogTitle: "Phone link",
      color: "linear-gradient(to bottom,#0d90e0, #1DA1F2)",
    },
  ];

  const handleUserChange = (event) => {
    const { name, value } = event.target;

    const hasNonSpaceCharacter = value.trim() !== "";

    const isValidInput = /^[a-zA-Z\s]+$/.test(value);

    if (name === "firstName" || name === "lastName") {
      if (isValidInput && hasNonSpaceCharacter) {
        setUser((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
        setIsSaveButtonActive(false);
      }
    }
  };

  const handleProfileChange = (event) => {
    const { name, value } = event.target;

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
    setIsSaveButtonActive(false);
  };

  const handleSearchChange = (value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      isSearch: value,
    }));
    setIsSaveButtonActive(false);
  };

  const handleNewSocialLinkChange = (event) => {
    const { name, value } = event.target;
    setNewSocialLink((prevNewSocialLink) => ({
      ...prevNewSocialLink,
      [name]: value,
    }));
  };

  const handleNewCustomLinkChange = (event) => {
    const { name, value } = event.target;
    setNewCustomLink((prevNewCustomLink) => ({
      ...prevNewCustomLink,
      [name]: value,
    }));

    setValidationStatus({
      ...validationStatus,
      [name]: value !== "",
    });
  };

  const handlePhotoSelect = (photoDataUrl) => {
    setProfile((prevData) => ({
      ...prevData,
      profilePicture: photoDataUrl,
    }));
    setIsSaveButtonActive(false);
  };

  const handleCoverPhotoSelect = (photoDataUrl) => {
    setProfile((prevData) => ({
      ...prevData,
      profileCoverPicture: photoDataUrl,
    }));
    setIsSaveButtonActive(false);
  };

  const handleSave = async () => {
    try {
      await EditProfile(User, Profile);
      await fetchProfile();
      fetchUserData();
      toast.success("Saved", {
        position: "top-center",
        autoClose: 1000,
      });
      setIsSaveButtonActive(true);
    } catch (error) {
      toast.error("Error Saving profile data:", error.message);
    }
  };

  // edit Custom links
  const handleBringEditedCustomLink = async (link) => {
    setEditedCustomLink(link);
    setEditCustomSectionVisible(true);
  };

  const handleEditedCustomLinkChange = async (event) => {
    const { name, value } = event.target;
    setEditedCustomLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleCustomLinkPhotoSelectEdit = (photoDataUrl) => {
    setEditedCustomLink((prevLink) => ({
      ...prevLink,
      Icon: photoDataUrl,
    }));
    setValidationStatus({
      ...validationStatus,
      photo: photoDataUrl !== null,
    });
  };

  const handleCustomLinkEdit = async () => {
    try {
      if (!isValidCustomURL(editedCustomLink.LinkUrl)) {
        toast.error("Invalid URL format", {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }

      if (editedCustomLink.Title === "") {
        toast.error("Title can't be empty", {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }

      await EditCustomLink(editedCustomLink);

      setEditedCustomLink({
        Id: 0,
        ProfileId: 0,
        LinkUrl: "",
        Title: "",
      });

      setEditCustomSectionVisible(false);

      toast.success("Link edited", {
        position: "top-center",
        autoClose: 1000,
      });

      fetchCustomLinksData();
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 1000,
      });
      console.error("Error saving Custom link data:", error.message);
    }
  };

  // edit Social link
  const handleBringEditedSocialLink = async (link) => {
    setEditedSocialLink(link);
    setEditSocialSectionVisible(true);
  };

  const handleEditedSocialLinkChange = async (event) => {
    const { name, value } = event.target;
    setEditedSocialLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleSocialLinkEdit = async () => {
    try {
      if (!isValidURL(editedSocialLink.LinkUrl)) {
        toast.error("Invalid URL format", {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }

      if (editedSocialLink.Title === "") {
        toast.error("Title can't be empty", {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }

      const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/.]+)/i;
      const matches = editedSocialLink.LinkUrl.match(regex);
      const domain = matches ? matches[1] : null;

      if (domain !== editedSocialLink.Category.toLowerCase()) {
        toast.error("You cant change the category of the link", {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }

      await EditSocialLink(editedSocialLink);

      setEditedSocialLink({
        Id: 0,
        ProfileId: 0,
        Title: "",
        LinkUrl: "",
        Category: "",
        Color: "",
      });

      setEditSocialSectionVisible(false);
      setCategoryChosen(false);

      toast.success("Link edited", {
        position: "top-center",
        autoClose: 1000,
      });

      fetchSocialLinksData();
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 1000,
      });
      console.error("Error saving Social link data:", error.message);
    }
  };

  const handleDeleteCustomLink = async (Id) => {
    const response = await DeleteCustomLink(Id);
    if (response != null) {
      toast.error("Custom link deleted", {
        position: "top-center",
        autoClose: 1000,
      });
      fetchCustomLinksData();
    }
  };

  const handleDeleteSocialLink = async (Id) => {
    const response = await DeleteSocialLink(Id);
    if (response != null) {
      toast.error("Social link deleted", {
        position: "top-center",
        autoClose: 1000,
      });
      fetchSocialLinksData();
    }
  };

  const fetchUserData = async () => {
    try {
      setUser({
        id: profile.id,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        category: profile.category,
      });
      setProfile(profile.profile);

      setNewSocialLink((prevNewSocialLink) => ({
        ...prevNewSocialLink,
        ProfileId: profile.profile.id,
      }));

      setNewCustomLink((prevNewCustomLink) => ({
        ...prevNewCustomLink,
        ProfileId: profile.profile.id,
      }));
    } catch (error) {
      if (error.redirectToLogin) {
        navigate("/Login");
      }
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize immediately to set the initial state
    handleResize();

    // Fetch data when component mounts
    fetchUserData();
    fetchSocialLinksData();
    fetchCustomLinksData();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [profile]);

  const fetchSocialLinksData = async () => {
    try {
      const response = await GetSocialLinks();
      setSocialLinks(response.data);
    } catch (error) {
      console.error("Error fetching social links data:", error);
    }
  };

  const fetchCustomLinksData = async () => {
    try {
      const response = await GetCustomLinks();
      setCustomLinks(response.data);
    } catch (error) {
      console.error("Error fetching social links data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setNewSocialLink((prevNewSocialLink) => ({
      ...prevNewSocialLink,
      LinkUrl: "",
      Category: "",
      Title: "",
      Color: "",
    }));
  };

  const handleDone = async () => {
    if (!isValidURL(newSocialLink.LinkUrl)) {
      toast.error("Invalid URL format", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    const response = await CreateSocialLink(newSocialLink);
    localStorage.setItem("isLinksCardVisible", "true");
    handleClose();
    if (response) {
      fetchSocialLinksData();
      toast.success("Social link created", {
        position: "top-center",
        autoClose: 1000,
      });
    } else {
      toast.error("Error while creating social link", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const handleCustomLinkDone = async () => {
    if (!isValidCustomURL(newCustomLink.LinkUrl)) {
      toast.error("Invalid URL format", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    const response = await CreateCustomLink(newCustomLink);

    if (response) {
      fetchCustomLinksData();
      toast.success("Custom link created", {
        position: "top-center",
        autoClose: 1000,
      });
      handleCustomLinkClose();
    } else {
      toast.error("Error while creating custom link", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const handleCustomLinkPhotoSelect = (photoDataUrl) => {
    setNewCustomLink((prevData) => ({
      ...prevData,
      Icon: photoDataUrl,
    }));
    setValidationStatus({
      ...validationStatus,
      photo: photoDataUrl !== null,
    });
  };

  // Second Dialog
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);

  const [openCategoryChooseDialog, setOpenCategoryChooseDialog] =
    useState(false);

  // Second Dialog Handlers
  const handleClickOpenSecond = () => {
    setOpenSecondDialog(true);
  };

  const handleCustomLinkClose = () => {
    setNewCustomLink((prevData) => ({
      ...prevData,
      Title: "",
      LinkUrl: "",
      Icon: "",
    }));
    setOpenSecondDialog(false);
  };

  //lazem create
  const [validationStatus, setValidationStatus] = useState({
    title: false,
    linkUrl: false,
    photo: false,
  });

  const isFormValid =
    newCustomLink.Title.trim() !== "" &&
    newCustomLink.LinkUrl.trim() !== "" &&
    newCustomLink.Icon !== null;

  const isValidURL = (input) => {
    // Regular expression pattern to match URLs that start with http or https
    const urlPattern =
      /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})(\/[^?#]*)?(\?[^#]*)?(#.*)?$/i;

    // Regular expression pattern to match phone numbers with 8 digits
    const phonePattern = /^\d{8}$/;

    // Check if the input matches URL pattern
    const isURL = urlPattern.test(input);

    // Check if the input matches phone number pattern
    const isPhoneNumber = phonePattern.test(input);

    // List of social media domains
    const socialMediaDomains = [
      "facebook.com",
      "twitter.com",
      "instagram.com",
      "linkedin.com",
      "instagram.com",
      "github.com",
    ];

    // Check if the input matches any social media domain and starts with http or https
    const isSocialMedia = socialMediaDomains.some((domain) =>
      new RegExp(`^https?:\/\/(?:www\.)?${domain}`, "i").test(input)
    );

    const isCategoryLike = new RegExp(
      `^https?:\/\/(?:www\.)?${newSocialLink.Category}`,
      "i"
    ).test(input);

    // Return true if it's a valid URL with http/https, a valid social media URL with http/https, OR a valid phone number
    return (isURL && isSocialMedia && isCategoryLike) || isPhoneNumber;
  };

  const isValidCustomURL = (input) => {
    // Regular expression pattern to match URLs that start with http or https
    const urlPattern =
      /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})(\/[^?#]*)?(\?[^#]*)?(#.*)?$/i;

    // Check if the input matches URL pattern
    const isURL = urlPattern.test(input);

    return isURL;
  };

  const IconFromPlatform = (platform) => {
    switch (platform) {
      case "Twitter":
        return (
          <XIcon
            sx={{
              fontSize: "36px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#212121",
              },
            }}
          />
        );
      case "GitHub":
        return (
          <GitHubIcon
            sx={{
              fontSize: "36px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#212121",
              },
            }}
          />
        );
      case "Instagram":
        return (
          <InstagramIcon
            sx={{
              fontSize: "35px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#D81B60",
              },
            }}
          />
        );
      case "Facebook":
        return (
          <FacebookIcon
            sx={{
              fontSize: "35px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#5892d0",
              },
            }}
          />
        );
      case "LinkedIn":
        return (
          <LinkedInIcon
            sx={{
              fontSize: "35px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#00b9f1",
              },
            }}
          />
        );
      case "Phone":
        return (
          <PhoneIcon
            sx={{
              fontSize: "35px",
              cursor: "pointer",
              transition: "color 0.3s",
              "&:hover": {
                color: "#4CAF50",
              },
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Helmet>
        <title> IDigix | Profile </title>
      </Helmet>
      {/* Tabs at the top */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "45px",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Account tabs"
        >
          <Tab label="Appearance" />
          {/* <Tab label="About (Beta)" /> */}
          <Tab label="Links" />
        </Tabs>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: "-50px" }}>
        <Grid item xs={12} md={9}>
          {User.category && (
            <Card sx={{ p: 3, marginBottom: "30px" }}>
              <Typography
                variant="overline"
                sx={{
                  mb: 3,
                  display: "block",
                  color: "text.secondary",
                }}
              >
                Your Plan
              </Typography>
              <Typography variant="h4">{User.category}</Typography>
              {User.category !== "Freelance" &&
                User.category !== "Enterprise" && (
                  <Box
                    sx={{
                      mt: { xs: 2, sm: 0 },
                      position: { sm: "absolute" },
                      top: { sm: 24 },
                      right: { sm: 24 },
                    }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        navigate("/admin/bundles");
                      }}
                    >
                      Upgrade plan
                    </Button>
                  </Box>
                )}
            </Card>
          )}
          {activeTab === 0 && (
            <Appearance
              Profile={Profile}
              User={User}
              isSaveButtonActive={isSaveButtonActive}
              setIsSaveButtonActive={setIsSaveButtonActive}
              handlePhotoSelect={handlePhotoSelect}
              handleProfileChange={handleProfileChange}
              handleUserChange={handleUserChange}
              handleSave={handleSave}
              handleIsSearchChange={handleSearchChange}
              handleCoverPhotoSelect={handleCoverPhotoSelect}
            />
          )}
          {/* {activeTab === 1 && <About />} */}
          {activeTab === 1 && (
            <Grid container spacing={2}>
              {/* Create Links */}
              <Grid item xs={12} md={12}>
                {/* Custom Links */}
                <Card>
                  <CardHeader
                    title="Create your links"
                    subheader="Craft custom links to suit your needs You can easily add, edit, and delete links, logos, and URLs according to your preferences."
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Button
                          size="large"
                          fullWidth
                          sx={{
                            marginBottom: "15px",
                            color: "text.secondary",
                            fontSize: "15px",
                            fontWeight: "500",
                            borderColor: "rgba(20, 43, 58, 0.3)",
                            borderStyle: "dotted",
                            borderWidth: "2px",
                            "&:hover": {
                              borderStyle: "Solid",
                              borderColor: "primary.main",
                              backgroundColor: "white",
                              borderWidth: "2px",
                            },
                          }}
                          onClick={() => handleClickOpenSecond()}
                        >
                          <CreateIcon />
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Create your link
                          </span>
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {/* Social Links */}
                <Card
                  sx={{
                    marginTop: "10px",
                    borderColor: "rgba(0, 0, 0, 0.15)",
                    borderStyle: "solid",
                  }}
                >
                  <CardHeader subheader="This platform provides predefined social platforms where you can add your own titles and URLs. Gain insights into daily views to track trends and patterns." />
                  <CardContent>
                    <Grid container spacing={1}>
                      {socialLinks.map(({ platform, color }) => {
                        let icon = IconFromPlatform(platform);
                        return (
                          <Grid
                            item
                            xs={3}
                            sm={6}
                            md={2}
                            lg={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                            key={platform}
                          >
                            <BootstrapTooltip
                              title={platform}
                              sx={{
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "13px",
                                },
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "rgba(20, 43, 58, 0.5)",
                                  borderColor: "rgba(20, 43, 58, 0.3)",
                                  height: "100%",
                                  padding: "15px 28px",
                                }}
                                onClick={() =>
                                  handleClickOpen(
                                    "Customize your link",
                                    platform,
                                    color
                                  )
                                }
                              >
                                {icon}
                              </Button>
                            </BootstrapTooltip>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Divider
                      sx={{
                        width: "100%",
                        margin: "30px 0",
                        borderBottomWidth: "2px",
                        borderColor: "rgba(0, 0, 0, 0.4)",
                      }}
                    />
                    <Grid container spacing={1}>
                      {PhoneLinks.map(({ platform, color }) => {
                        let icon = IconFromPlatform(platform);
                        return (
                          <Grid
                            item
                            xs={3}
                            sm={6}
                            md={2}
                            lg={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                            key={platform}
                          >
                            <BootstrapTooltip
                              title={platform}
                              sx={{
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "13px",
                                },
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "rgba(20, 43, 58, 0.5)",
                                  borderColor: "rgba(20, 43, 58, 0.3)",
                                  height: "100%",
                                  padding: "15px 28px",
                                }}
                                onClick={() => {
                                  setOpenPhoneDialog(true);
                                }}
                              >
                                {icon}
                              </Button>
                            </BootstrapTooltip>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {/* edit Social */}
              {editSocialSectionVisible && (
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardHeader
                      title="Edit your links"
                      subheader="This is where you can edit your entire profile! Here, you can manage and edit your 'About' section."
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                          }}
                          aria-label="close"
                          onClick={() => {
                            setEditSocialSectionVisible(false);
                            setCategoryChosen(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <Grid item xs={9} md={10}>
                          <TextField
                            name="Title"
                            label="Type your link title"
                            focused
                            value={editedSocialLink.Title}
                            sx={{ width: "100%" }}
                            onChange={handleEditedSocialLinkChange}
                          />
                        </Grid>
                        <Grid item xs={1} md={1}>
                          {CategoryChosen ? (
                            <Button
                              variant="outlined"
                              sx={{
                                color: "rgba(20, 43, 58, 0.5)",
                                borderColor: "rgba(20, 43, 58, 0.3)",
                                height: "100%",
                                marginLeft: "100%",
                              }}
                              onClick={() => {
                                setOpenCategoryChooseDialog(true);
                              }}
                            >
                              {IconFromPlatform(editedSocialLink.Category)}
                            </Button>
                          ) : (
                            <ImageIcon
                              sx={{
                                color: "text.secondary",
                                cursor: "pointer",
                                transition: "color 1s",
                                "&:hover": {
                                  color: "#ee705e",
                                },
                                fontSize: "60px",
                                height: "100%",
                                marginLeft: "100%",
                              }}
                              onClick={() => {
                                setOpenCategoryChooseDialog(true);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            name="LinkUrl"
                            label="Type your link url"
                            value={editedSocialLink.LinkUrl}
                            focused
                            sx={{ width: "100%" }}
                            onChange={handleEditedSocialLinkChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Button
                      onClick={handleSocialLinkEdit}
                      color="primary"
                      variant="outlined"
                      sx={{
                        margin: "25px",
                        backgroundColor: "#ee705e",
                        color: "white",
                        "&:hover": {
                          color: "#ee705e",
                        },
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Save your link
                      </span>
                      <SaveIcon />
                    </Button>

                    <Dialog
                      open={openCategoryChooseDialog}
                      onClose={() => {
                        setOpenCategoryChooseDialog(false);
                      }}
                    >
                      <DialogTitle color="primary">
                        Choose a website
                      </DialogTitle>

                      <DialogContent>
                        <Grid
                          sx={{
                            display: "flex",
                          }}
                          container
                          spacing={2}
                        >
                          {socialLinks.map(({ platform }) => {
                            let icon = IconFromPlatform(platform);
                            return (
                              <Grid
                                item
                                xs={3}
                                sm={6}
                                md={2}
                                lg={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "5px",
                                }}
                                key={platform}
                              >
                                <BootstrapTooltip
                                  title={platform}
                                  sx={{
                                    "& .MuiTooltip-tooltip": {
                                      fontSize: "13px",
                                    },
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      color: "rgba(20, 43, 58, 0.5)",
                                      borderColor: "rgba(20, 43, 58, 0.3)",
                                      height: "100%",
                                      padding: "15px 20px",
                                    }}
                                    onClick={() => {
                                      setEditedSocialLink((prevLink) => ({
                                        ...prevLink,
                                        Category: platform,
                                      }));
                                      setOpenCategoryChooseDialog(false);
                                      setCategoryChosen(true);
                                    }}
                                  >
                                    {icon}
                                  </Button>
                                </BootstrapTooltip>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </DialogContent>
                      <DialogActions></DialogActions>
                    </Dialog>
                  </Card>
                </Grid>
              )}
              {/* edit Custom */}
              {editCustomSectionVisible && (
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardHeader
                      title="Edit your links"
                      subheader="Analyze the daily views to understand the trends and patterns in the number of views your content receives.Gain valuable insights into the most active days and make informed decisions based on this data."
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                          }}
                          aria-label="close"
                          onClick={() => {
                            setEditCustomSectionVisible(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <Grid item xs={10} md={11}>
                          <TextField
                            name="Title"
                            label="Type your link title"
                            value={editedCustomLink.Title}
                            focused
                            sx={{ width: "100%" }}
                            onChange={handleEditedCustomLinkChange}
                          />
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <Avatar
                            style={{
                              width: "3rem",
                              height: "3rem",
                            }}
                            focused
                            src={editedCustomLink.Icon}
                          />
                          <PhotoSelector
                            onSelect={handleCustomLinkPhotoSelectEdit}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            name="LinkUrl"
                            label="Type your link url"
                            value={editedCustomLink.LinkUrl}
                            focused
                            sx={{ width: "100%" }}
                            onChange={handleEditedCustomLinkChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Button
                      onClick={handleCustomLinkEdit}
                      color="primary"
                      variant="outlined"
                      sx={{
                        margin: "25px",
                        backgroundColor: "#ee705e",
                        color: "white",
                        "&:hover": {
                          color: "#ee705e",
                        },
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Save your link
                      </span>
                      <SaveIcon />
                    </Button>
                  </Card>
                </Grid>
              )}
              <Dialog open={openSecondDialog} onClose={handleCustomLinkClose}>
                <DialogTitle color="primary">
                  Create your custom link
                </DialogTitle>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Avatar
                      style={{
                        width: "5rem",
                        height: "5rem",
                      }}
                      src={newCustomLink.Icon}
                      alt="User Profile Photo"
                    />
                    <PhotoSelector
                      onSelect={handleCustomLinkPhotoSelect}
                      error={
                        newCustomLink.Icon === null && validationStatus.photo
                      }
                      helperText={
                        newCustomLink.Icon === null ? "Photo is required" : ""
                      }
                    />
                  </div>
                </div>

                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Title"
                    label="Title"
                    type="text"
                    fullWidth
                    required
                    color="primary"
                    value={newCustomLink.Title}
                    onChange={handleNewCustomLinkChange}
                    error={
                      newCustomLink.Title.trim() === "" &&
                      validationStatus.title
                    }
                    helperText={
                      newCustomLink.Title.trim() === ""
                        ? "Title is required"
                        : ""
                    }
                  />

                  <TextField
                    autoFocus
                    name="LinkUrl"
                    margin="dense"
                    label="Your link"
                    type="url"
                    fullWidth
                    required
                    value={newCustomLink.LinkUrl}
                    onChange={handleNewCustomLinkChange}
                    error={
                      newCustomLink.LinkUrl.trim() === "" &&
                      validationStatus.linkUrl
                    }
                    helperText={
                      newCustomLink.LinkUrl.trim() === ""
                        ? "URL is required"
                        : ""
                    }
                  />
                  {/* Hints and Tips Section */}
                  <Box
                    mt={2}
                    p={2}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="subtitle1" color="textPrimary">
                      Tips for Creating Your Custom Link
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Make sure your URL starts with "http://" or "https://".
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Ensure your link is correctly formatted, e.g.
                      "https://www.idigics.com/...".
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCustomLinkClose}>Cancel</Button>
                  <Button
                    onClick={handleCustomLinkDone}
                    disabled={!isFormValid}
                  >
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create your social link</DialogTitle>
                <DialogContent>
                  <TextField
                    name="Title"
                    autoFocus
                    margin="dense"
                    label="Title"
                    type="url"
                    fullWidth
                    required
                    value={newSocialLink.Title}
                    onChange={handleNewSocialLinkChange}
                    helperText={
                      newSocialLink.Title === "" ? "Title is required" : ""
                    }
                  />

                  <TextField
                    name="LinkUrl"
                    autoFocus
                    margin="dense"
                    id="linkUrl"
                    label="Url"
                    type="url"
                    fullWidth
                    required
                    value={newSocialLink.LinkUrl}
                    onChange={handleNewSocialLinkChange}
                    helperText={
                      newSocialLink.LinkUrl === "" ? "URL is required" : ""
                    }
                  />
                  {/* Hints and Tips Section */}
                  <Box
                    mt={2}
                    p={2}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="subtitle1" color="textPrimary">
                      Tips for Creating Predefined Link
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Make sure your URL starts with "http://" or "https://".
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Only links from social media platforms like Facebook,
                      Twitter, Instagram, LinkedIn, and GitHub are accepted.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Ensure your link is correctly formatted, e.g.,
                      "https://www.facebook.com/yourprofile".
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - For phone numbers, simply enter an 8-digit number
                      without spaces or symbols.
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={handleDone}
                    disabled={
                      newSocialLink.Title === "" || newSocialLink.LinkUrl === ""
                    }
                  >
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
              <PhoneLinkDialog
                setOpenPhoneDialog={setOpenPhoneDialog}
                openPhoneDialog={openPhoneDialog}
                Id={profile.id}
                PhoneNumberExist={profile.contacts.some(
                  (item) => item.category === "PhoneNumber"
                )}
              />
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    display: isVisible ? "flex" : "none",
                    marginTop: "20px",
                  }}
                >
                  <CardContent>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                      aria-label="close"
                      onClick={() => {
                        setIsVisible(false);
                        localStorage.setItem("isLinksCardVisible", "false");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography gutterBottom variant="h6" component="div">
                      Create Your Custom Link!
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "20px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Automate content with dynamic feeds and images.
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "20px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Use your own domain to boost branding.
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "20px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Access analytics to improve your strategy.
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "20px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Unlock premium features for more engagement.
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                    <Button
                      variant="contained"
                      onClick={() => handleClickOpenSecond()}
                    >
                      Get Started
                    </Button>
                  </CardContent>
                  <Box
                    sx={{
                      objectFit: "cover",
                      width: "50%",
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                  >
                    <img src="https://linktr.ee/_gatsby/image/1f7e31106ab8fd6cf4d62970cef6fec5/5dd82ff0dad9ac8464af794a9dc6bbeb/lsp_16x9-linktree-edm-release-may-2020-hero-socials-1_260520-024625.png?u=https%3A%2F%2Fapi.blog.production.linktr.ee%2Fwp-content%2Fuploads%2F2020%2F05%2Flsp_16x9-linktree-edm-release-may-2020-hero-socials-1_260520-024625.png&a=w%3D364%26h%3D449%26fit%3Dcrop%26crop%3Dcenter%26fm%3Dpng%26q%3D75&cd=4f02ff65d6cf6e346076e548f1a232da" />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            {/* Profile */}
            {activeTab === 0 && (
              <Box
                sx={{
                  "@media (min-width: 900px)": {
                    maxHeight: "100vh",
                    width: "400px !important",
                    overflowY: "auto",
                    position: "fixed",
                  },
                }}
              >
                <AppProfileCard
                  title="Profile"
                  subheader="Here is your profile"
                  SocialLinks={SocialLinks}
                  CustomLinks={CustomLinks}
                  User={User}
                  Profile={Profile}
                />
              </Box>
            )}
            {activeTab === 1 && (
              <Box
                sx={{
                  "@media (min-width: 900px)": {
                    maxHeight: "550px",
                    width: "400px",
                    overflowY: "auto",
                    position: "fixed",
                  },
                }}
              >
                <Box>
                  {/* social links */}
                  <AppLinksByProfile
                    title="Social links"
                    subheader="Here are your social links"
                    type="socialLinks"
                    list={SocialLinks.map(
                      ({ title, linkUrl, category, id, profileId }) => {
                        let iconn;
                        let color;
                        switch (category) {
                          case "Twitter":
                            iconn = <XIcon />;
                            color = "#43aff1";
                            break;
                          case "GitHub":
                            iconn = <GitHubIcon />;
                            color = "#212121";
                            break;
                          case "Instagram":
                            iconn = <InstagramIcon />;
                            color = "#c32aa3";
                            break;
                          case "Facebook":
                            iconn = <FacebookIcon />;
                            color = "#5892d0";
                            break;
                          case "LinkedIn":
                            iconn = <LinkedInIcon />;
                            color = "#00b9f1";
                            break;
                          case "PhoneNumber":
                            iconn = <PhoneIcon />;
                            color = "#212121";
                            break;
                          default:
                            iconn = null;
                            color = "#ffffff";
                        }
                        return {
                          Id: id,
                          Title: title,
                          LinkUrl: linkUrl,
                          Color: color,
                          Icon: iconn,
                          ProfileId: profileId,
                          Category: category,
                        };
                      }
                    )}
                    onDelete={handleDeleteSocialLink}
                    onEdit={handleBringEditedSocialLink}
                  />
                  {/* custom links */}
                  <AppLinksByProfile
                    title="Custom links"
                    subheader="Here are your custom links"
                    type="customLinks"
                    list={CustomLinks.map((link) => {
                      return {
                        Id: link.id,
                        ProfileId: link.profileId,
                        Title: link.title,
                        LinkUrl: link.linkUrl,
                        Icon: link.icon,
                      };
                    })}
                    onDelete={handleDeleteCustomLink}
                    onEdit={handleBringEditedCustomLink}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        )}

        {isMobile && (
          <Button
            disableRipple
            color="primary"
            onClick={() => setIsProfileCardVisible((prev) => !prev)}
            variant="contained"
            sx={{
              margin: "10px 21px 20px ",
              zIndex: 2000,
              position: "fixed",
              right: "1rem",
              bottom: "1rem",
              borderRadius: "50%",
              height: "55px",
              minWidth: "5px",
            }}
          >
            <PhoneIphoneIcon />
          </Button>
        )}

        <Dialog open={isProfileCardVisible} fullScreen>
          <DialogContent>
            <AppProfileCard
              title="Profile"
              subheader="Here is your profile"
              SocialLinks={SocialLinks}
              CustomLinks={CustomLinks}
              User={User}
              Profile={Profile}
            />
            <Box>
              {/* social links */}
              <AppLinksByProfile
                title="Social links"
                subheader="Here are your social links"
                type="socialLinks"
                list={SocialLinks.map(
                  ({ title, linkUrl, category, id, profileId }) => {
                    let iconn;
                    let color;
                    switch (category) {
                      case "Twitter":
                        iconn = <XIcon />;
                        color = "#43aff1";
                        break;
                      case "GitHub":
                      case "Phone":
                        iconn = <GitHubIcon />;
                        color = "#212121";
                        break;
                      case "Instagram":
                        iconn = <InstagramIcon />;
                        color = "#c32aa3";
                        break;
                      case "Facebook":
                        iconn = <FacebookIcon />;
                        color = "#5892d0";
                        break;
                      case "LinkedIn":
                        iconn = <LinkedInIcon />;
                        color = "#00b9f1";
                        break;
                      default:
                        iconn = null;
                        color = "#ffffff";
                    }
                    return {
                      Id: id,
                      Title: title,
                      LinkUrl: linkUrl,
                      Color: color,
                      Icon: iconn,
                      ProfileId: profileId,
                      Category: category,
                    };
                  }
                )}
                onDelete={handleDeleteSocialLink}
                onEdit={handleBringEditedSocialLink}
                ProfileCardVisible={setIsProfileCardVisible}
              />
              {/* custom links */}
              <AppLinksByProfile
                title="Custom links"
                subheader="Here are your custom links"
                type="customLinks"
                list={CustomLinks.map((link) => {
                  return {
                    Id: link.id,
                    ProfileId: link.profileId,
                    Title: link.title,
                    LinkUrl: link.linkUrl,
                    Icon: link.icon,
                  };
                })}
                onDelete={handleDeleteCustomLink}
                onEdit={handleBringEditedCustomLink}
                ProfileCardVisible={setIsProfileCardVisible}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
      <ToastContainer />
    </Container>
  );
};

export default ProfileUser;
