import React, { createContext, useContext, useState, useEffect } from "react";
import { useProfile } from "./ProfileContext";

const BudgetContext = createContext();

export const useBudget = () => useContext(BudgetContext);

export const BudgetProvider = ({ children }) => {
    const { profile } = useProfile();
    const [budget, setBudget] = useState(0.0);

    useEffect(() => {
        fetchProfile();
    }, [profile]);

    const fetchProfile = async () => {
        setBudget(profile?.budget);
    };

    return (
        <BudgetContext.Provider value={{ budget, setBudget }}>
            {children}
        </BudgetContext.Provider>
    );
};
