import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PostSearch {
    UserId: number;
    Query: string;
    Date: string;
}

export const GetProfileFromSearch = async (search: string) => {
    console.log(search);
    try {
        const response = await api.get(
            `${BASE_URL}/Search/Search`,
            {
                params: {
                    search: search,
                },
                headers: {
                    "Cache-Control": "public,max-age=3600",
                    Pragma: "",
                    withCredentials: true,
                },
            }
        );

        return response;
    } catch (error) {
        console.log("Error:", error.message);
    }
};

export const PostSearch = async (search: PostSearch) => {
    const data = {
        UserId: search.UserId,
        Query: search.Query,
        Date: search.Date,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.post(`${BASE_URL}/Search/PostSearch`, data, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const GetSearchQueries = async () => {
    try {
        var authToken = getAuthToken();
        const response = await api.get(`${BASE_URL}/Search/GetSearchQueries`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export function getAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return cookie.substring("authToken=".length, cookie.length);
        }
    }

    return null;
}
