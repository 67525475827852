import { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Card,
    Box,
    Typography,
    Tabs,
    Tab,
    Container,
    Rating,
    Stack,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { useSearch } from "../Context/SearchContext";
import SearchNotFound from "./SearchNotFound";
import { motion } from "framer-motion";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useSearchParams } from "react-router-dom";

const SearchResults = () => {
    const { searchResults, fetchSearchResults } = useSearch();
    const [activeTab, setActiveTab] = useState(0);
    const [displayCount, setDisplayCount] = useState(5);
    const [sortedResults, setSortedResults] = useState([]);
    const [searchParams] = useSearchParams();

    // Fetch search results only when search parameters change
    useEffect(() => {
        const handleSearch = async () => {
            if (searchParams && searchParams.toString() !== "") {
                await fetchSearchResults(searchParams.get("q"));
            }
        };
        handleSearch(); // Call search function only when searchParams change
    }, [searchParams]);

    // Update sortedResults whenever searchResults or activeTab changes
    useEffect(() => {
        setSortedResults(sortResultsByTab(activeTab));
    }, [searchResults, activeTab]);

    const GetCategoryIcon = (category) => {
        switch (category) {
            case "Free":
                return <EmojiPeopleIcon fontSize="Large" />;
            case "Student":
                return <BusinessCenterIcon fontSize="Large" />;
            case "Freelance":
                return <EngineeringIcon fontSize="Large" />;
            case "Entrepreneur":
                return <ApartmentIcon fontSize="Large" />;
            default:
                return null;
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setDisplayCount(5); // Reset display count when switching tabs
    };

    const sortResultsByTab = (tabValue) => {
        switch (tabValue) {
            case 1:
                return (
                    searchResults
                        // .filter(
                        //     (result) => !result.user.rate || result.user.rate === 0
                        // )
                        .sort((a, b) => a.userName.localeCompare(b.userName))
                );
            case 0:
                return searchResults
                    .filter((result) => result.user.rate > 0)
                    .sort((a, b) => b.user.rate - a.user.rate);
            default:
                return searchResults;
        }
    };

    return (
        <Container>
            <Typography variant="h5" component="h1" gutterBottom>
                Search
            </Typography>
            <Typography component="h2" color="textSecondary">
                Results for {searchParams.get("q")}
            </Typography>
            {searchResults.length > 0 ? (
                <Box sx={{ mt: 5 }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            padding: "10px",
                            marginBottom: "30px",
                        }}
                    >
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="Account tabs"
                        >
                            <Tab label="Rate" />
                            <Tab label="All" />
                        </Tabs>
                    </Box>
                    <Grid container spacing={3}>
                        {sortedResults.slice(0, displayCount).map((result) => (
                            <Grid key={result.id} item xs={12}>
                                <motion.div
                                    className="animate-on-scroll"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        duration: 0.5,
                                        ease: "easeOut",
                                    }}
                                >
                                    <Link
                                        to={`/Profile/${result.userName}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                p: 3,
                                                gap: "10px",
                                                boxShadow:
                                                    "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                transition:
                                                    "box-shadow 0.3s ease",
                                                "&:hover": {
                                                    boxShadow:
                                                        "0px 8px 20px rgba(0, 0, 0, 0.2)",
                                                },
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                sx={{ flex: 1 }}
                                            >
                                                <Avatar
                                                    alt={
                                                        result.user.firstName +
                                                        " " +
                                                        result.user.lastName
                                                    }
                                                    src={result.profilePicture}
                                                    sx={{
                                                        width: 80,
                                                        height: 80,
                                                    }}
                                                />
                                                <Stack
                                                    direction="column"
                                                    spacing={1}
                                                >
                                                    <Typography variant="h6">
                                                        {result.user.firstName}{" "}
                                                        {result.user.lastName}{" "}
                                                        <Stack
                                                            direction="row"
                                                            alignItems="baseline"
                                                            spacing={1}
                                                            sx={{
                                                                display:
                                                                    "inline-flex",
                                                                marginLeft: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    color: "rgba(20, 43, 58, 0.5)",
                                                                }}
                                                            >
                                                                @
                                                                {
                                                                    result.userName
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: "rgba(20, 43, 58, 0.5)",
                                                                }}
                                                            >
                                                                {GetCategoryIcon(
                                                                    result.user
                                                                        .category
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    </Typography>
                                                    {result.user.rate > 0 && (
                                                        <Rating
                                                            name="read-only"
                                                            value={
                                                                result.user.rate
                                                            }
                                                            readOnly
                                                            emptyIcon={
                                                                <StarBorderIcon fontSize="inherit" />
                                                            }
                                                            sx={{
                                                                marginLeft:
                                                                    "10px",
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        />
                                                    )}
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        {result.country && (
                                                            <>
                                                                <LocationOnIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                />
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{
                                                                        color: "rgba(20, 43, 58, 0.5)",
                                                                    }}
                                                                >
                                                                    {
                                                                        result.country
                                                                    }
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        {result.occupation && (
                                                            <>
                                                                <WorkOutlineIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                />
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{
                                                                        color: "rgba(20, 43, 58, 0.5)",
                                                                    }}
                                                                >
                                                                    {
                                                                        result.occupation
                                                                    }
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Card>
                                    </Link>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>
                    {displayCount < sortedResults.length && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: 3,
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    setDisplayCount(displayCount + 5)
                                }
                            >
                                View More
                            </Button>
                        </Box>
                    )}
                </Box>
            ) : (
                <SearchNotFound searchQuery={searchParams.get("q")} />
            )}
        </Container>
    );
};

export default SearchResults;
