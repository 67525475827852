import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, CircularProgress } from "@mui/material";
import SeverErrorIllustration from "../components/SeverErrorIllustration";

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

const LazyAccessDenied = lazy(() => import("./AccessDenied"));

export default function AccessDeniedLazy() {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>IDigix | Access Denied</title>
            </Helmet>

            <Container>
                <StyledContent
                    sx={{ textAlign: "center", alignItems: "center" }}
                >
                    <Typography variant="h3" paragraph color="primary">
                        Access Denied
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                        Sorry, you don't have permission to access this page.
                    </Typography>

                    <SeverErrorIllustration
                        sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                    />

                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => {
                            navigate("/admin/user");
                        }}
                    >
                        Go Back
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
}

export const AccessDenied = () => (
    <Suspense fallback={<CircularProgress />}>
        <LazyAccessDenied />
    </Suspense>
);
