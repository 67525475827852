import { useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
    Input,
    Slide,
    Button,
    IconButton,
    InputAdornment,
    ClickAwayListener,
    Link,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// component
import Iconify from "../../../components/iconify";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled("div")(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    top: 0,
    left: 0,
    zIndex: 99,
    width: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    height: HEADER_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up("md")]: {
        height: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(!open);
    };

    const onChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearchSubmit = async (event) => {
        event.preventDefault();

        const trimmedQuery = searchQuery.trim();

        if (!trimmedQuery || /^\.{3}$/.test(trimmedQuery)) {
            toast.error("Please enter a valid search query.", {
                position: "top-center",
                autoClose: 1000,
            });
        } else {
            // setSearchParams({ q: trimmedQuery });
            handleClose();
            navigate(`/admin/search?q=${trimmedQuery}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSearchSubmit(event);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!open && (
                    <IconButton onClick={handleOpen}>
                        <Iconify icon="eva:search-fill" />
                    </IconButton>
                )}

                <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                    <StyledSearchbar>
                        <Input
                            autoFocus
                            fullWidth
                            disableUnderline
                            placeholder="Search…"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify
                                        icon="eva:search-fill"
                                        sx={{
                                            color: "text.disabled",
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </InputAdornment>
                            }
                            sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                            onChange={onChange}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSearchSubmit}
                        >
                            Search
                        </Button>
                    </StyledSearchbar>
                </Slide>
            </div>
        </ClickAwayListener>
    );
}
