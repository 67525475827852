import { Helmet } from "react-helmet-async";
import {
    Container,
    Button,
    Card,
    CardHeader,
    CardContent,
    Box,
    Typography,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VerifyPasswordChanging } from "../AuthenticationData.ts";
import { ToastContainer, toast } from "react-toastify";

const VerifyPasswordChangingPage = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [strength, setStrength] = useState("");

    useEffect(() => {
        validatePassword(password);
    }, [password, confirmPassword]);

    const validatePassword = (password) => {
        let strengthIndicator = -1;
        let upper = false,
            lower = false,
            numbers = false,
            specialChars = false,
            PasswordConfirmed = false;

        if (/[A-Z]/.test(password)) upper = true;
        if (/[a-z]/.test(password)) lower = true;
        if (/\d/.test(password)) numbers = true;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password))
            specialChars = true;

        if (
            password !== "" &&
            confirmPassword !== "" &&
            password === confirmPassword
        ) {
            PasswordConfirmed = true;
        }

        if (password.length >= 8) {
            strengthIndicator++;
            if (password.length > 5) strengthIndicator++;
        }
        if (upper && lower && numbers && specialChars) strengthIndicator++;

        setStrength(["weak", "medium", "strong"][strengthIndicator] || "");

        switch (true) {
            case password.length < 8:
                setPasswordError(
                    "Password must be at least 8 characters long."
                );
                break;
            case !specialChars:
                setPasswordError(
                    "Password must contain at least one special character."
                );
                break;
            case !upper:
                setPasswordError(
                    "Password must contain at least one upper case character."
                );
                break;
            case !lower:
                setPasswordError(
                    "Password must contain at least one lower case character."
                );
                break;
            case !numbers:
                setPasswordError("Password must contain numbers.");
                break;
            case !PasswordConfirmed:
                setPasswordError("Please Confirm your password.");
                break;
            default:
                setPasswordError("");
                break;
        }
    };

    const handleConfirm = async () => {
        try {
            const response = await VerifyPasswordChanging(
                confirmPassword,
                token
            );
            if (response.status === 200) {
                toast.success("Password changed successfully", {
                    position: "top-center",
                    autoClose: 1000,
                });
                setPassword("");
                setConfirmPassword("");
                setPasswordChanged(true);
            } else {
                const errorData = await response.error;
                toast.error(
                    `Error changing the password: ${errorData.message}`,
                    {
                        position: "top-center",
                        autoClose: 1000,
                    }
                );
            }
        } catch (error) {
            console.error("Error verifying email:", error);
        }
    };

    return (
        <>
            <Helmet>
                <title>IDigix | Password Change</title>
            </Helmet>

            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                }}
            >
                <Card sx={{ width: 600, borderRadius: 2, padding: "40px" }}>
                    <CardHeader
                        title="Password Change"
                        subheader="Please remember your passwords next time :)"
                    />
                    {passwordChanged ? (
                        <CardContent>
                            <Box mb={2}>
                                <Typography
                                    variant="overline"
                                    sx={{
                                        mb: 3,
                                        display: "block",
                                        color: "text.secondary",
                                    }}
                                >
                                    Please check your email
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate("/Login");
                                }}
                            >
                                Go to login
                            </Button>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                            </Box>
                            <div className={`bars ${strength}`}>
                                <div></div>
                            </div>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(event) => {
                                        setConfirmPassword(event.target.value);
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                disabled={
                                    !!passwordError ||
                                    strength.trim() !== "strong"
                                }
                            >
                                Confirm
                            </Button>
                        </CardContent>
                    )}
                </Card>
            </Container>
            <ToastContainer />
        </>
    );
};

export default VerifyPasswordChangingPage;
