// SignUpForm.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Register } from "../../../AuthenticationData.ts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register1 from "./Register1";
import Register2 from "./Register2";
import Register3 from "./Register3";
import RegisterConfirm from "./RegisterConfirm";

export default function SignUpForm() {
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        gender: "",
        contactInfo: "",
        categoryUser: "",
        contactCategory: "PhoneNumber",
        profilePicture: "",
    });
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [isFormValid, setFormValid] = useState(false); // Validation state

    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            const response = await Register(formData);

            if (response.error) {
                throw new Error(response.error);
            }

            navigate("/Login");
            setRegistrationSuccess(true);
        } catch (error) {
            setCurrentPage(3);
            setFormData((prevData) => ({ ...prevData, password: "" }));
            toast.error(error.message, {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };
    const handleNext = (data) => {
        setFormData((prevData) => ({ ...prevData, ...data }));
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevious = (data) => {
        setFormData((prevData) => ({ ...prevData, ...data }));
        setCurrentPage((prevPage) => prevPage - 1);
    };

    useEffect(() => {
        validateForm();

        if (registrationSuccess) {
            toast.success("Registration successful!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }, [registrationSuccess, formData]);

    const validateForm = () => {
        const { firstName, lastName, userName, categoryUser } = formData;

        const isStep1Valid =
            firstName.trim() !== "" &&
            lastName.trim() !== "" &&
            userName.trim() !== "" &&
            categoryUser.trim() !== "";
        const isStep2Valid = formData.contactInfo.trim().length === 8;

        setFormValid(isStep1Valid && isStep2Valid /* && ... */);
    };

    const renderPage = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Register1
                        onNext={handleNext}
                        data={formData}
                        isFormValid={isFormValid}
                    />
                );
            case 2:
                return (
                    <Register2
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        data={formData}
                        isFormValid={isFormValid}
                    />
                );
            case 3:
                return (
                    <Register3
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        data={formData}
                        isFormValid={isFormValid}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                {currentPage > 3 ? (
                    <RegisterConfirm onSubmit={onSubmit} />
                ) : (
                    renderPage()
                )}
            </div>
            <ToastContainer />
        </>
    );
}
