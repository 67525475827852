import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import PageNotFoundIllustration from "../components/PageNotFoundIllustration";
import { Helmet } from "react-helmet-async";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
    display: "flex",
    minHeight: "100%",
    alignItems: "center",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title> Page Not Found </title>
            </Helmet>
            <RootStyle>
                <Container>
                    <Box
                        sx={{
                            maxWidth: 480,
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h3" paragraph color="primary">
                            Sorry, page not found!
                        </Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                            Sorry, we couldn’t find the page you’re looking for.
                            Perhaps you’ve mistyped the URL? Be sure to check
                            your spelling.
                        </Typography>

                        <PageNotFoundIllustration
                            sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                        />

                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => {
                                navigate("admin/user");
                            }}
                        >
                            Go Back
                        </Button>
                    </Box>
                </Container>
            </RootStyle>
        </>
    );
}
