import PropTypes from "prop-types";
import { useEffect, lazy, Suspense } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
    Box,
    Drawer,
    Typography,
    Avatar,
    Stack,
    Button,
    CircularProgress,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import { useProfile } from "../../../Context/ProfileContext";
import { useNavigate } from "react-router-dom";
import { PROFILE_URL } from "../../../Context/config";

const NavSection = lazy(() => import("../../../components/nav-section"));
const Logo = lazy(() => import("../../../components/Logo"));

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { profile } = useProfile();
    const navigate = useNavigate();

    const isDesktop = useResponsive("up", "lg");

    useEffect(() => {}, [profile]);

    const renderContent = (
        <>
            <Box sx={{ px: 3, py: 2, display: "inline-flex" }}>
                <Suspense fallback={<CircularProgress />}>
                    <Logo />
                </Suspense>
            </Box>

            <Box sx={{ mb: 5, mx: 2.5 }}>
                <a
                    href={`${PROFILE_URL}/Profile/${profile.profile.userName}`}
                    style={{
                        textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    <StyledAccount>
                        <Suspense fallback={<CircularProgress />}>
                            <Avatar
                                src={profile.profile.profilePicture}
                                alt="photoURL"
                            />
                        </Suspense>

                        <Box sx={{ ml: 2 }}>
                            <Typography
                                variant="subtitle2"
                                sx={{ color: "text.primary" }}
                            >
                                {profile.firstName} {profile.lastName}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    color: "text.secondary",
                                    fontSize: "10px",
                                }}
                            >
                                @{profile.profile.userName}
                            </Typography>
                        </Box>
                    </StyledAccount>
                </a>
            </Box>

            <Suspense fallback={<CircularProgress />}>
                <NavSection
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    category={profile.category}
                />
            </Suspense>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack
                    alignItems="center"
                    spacing={3}
                    sx={{ pt: 5, borderRadius: 2, position: "relative" }}
                >
                    <Box
                        component="img"
                        src="/assets/illustrations/illustration_avatar.png"
                        sx={{ width: 100, position: "absolute", top: -35 }}
                    />
                    <Button
                        onClick={() => {
                            navigate("/admin/Bundles");
                        }}
                    >
                        Upgrade plan
                    </Button>
                </Stack>
            </Box>
        </>
    );

    const handleTabClick = () => {
        if (!isDesktop) {
            onCloseNav();
        }
    };

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: "background.default",
                            borderRightStyle: "dashed",
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    <div onClick={handleTabClick}>{renderContent}</div>
                </Drawer>
            )}
        </Box>
    );
}
