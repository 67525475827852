import api from "./Api";
import { BASE_URL } from "./Context/config";
import Cookies from "js-cookie";

export interface PostUserData {
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    gender: string;
    contactInfo: string;
    contactCategory: string;
    categoryUser: string;
    profilePicture: string;
    profileCoverPicture: string;
}

export interface LogUserData {
    email: string;
    password: string;
}

const handleRandomPhotoSelect = async () => {
    const randomAvatarNumber = Math.floor(Math.random() * 24) + 1;
    const randomAvatarUrl = `/assets/images/avatars/avatar_${randomAvatarNumber}.jpg`;

    const response = await fetch(randomAvatarUrl);
    const blob = await response.blob();

    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
};

export const Register = async (user: PostUserData) => {
    const profilePicture =
        user.profilePicture || (await handleRandomPhotoSelect());
    const data = {
        Email: user.email,
        FirstName: user.firstName,
        LastName: user.lastName,
        UserName: user.userName,
        Password: user.password,
        Gender: user.gender,
        Category: user.categoryUser,
        ContactInfo: user.contactInfo,
        ContactCategory: user.contactCategory,
        ProfilePicture: profilePicture,
    };

    try {
        const response = await api.post(`${BASE_URL}/Auth/Register`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        localStorage.setItem("isEmailSent", "true");

        return response;
    } catch (error) {
        return { error: error.response.data };
    }
};

export const verifyEmail = async (token: string) => {
    try {
        const response = await api.get(`${BASE_URL}/Auth/VerifyEmail/${token}`);
        return response;
    } catch (error) {
        console.error("Error while verifying email:", error);
        return {
            error: "An error occurred while verifying the email. Please try again later.",
        };
    }
};

export const ForgotPassword = async (email: string) => {
    try {
        const response = await api.post(
            `${BASE_URL}/Auth/ForgotPassword`,
            email,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.error("Error while forgotchanging password:", error);
        return {
            error: "An error occurred while forgot password. Please try again later.",
        };
    }
};

export const ChangePassword = async (password: string) => {
    try {
        const response = await api.post(
            `${BASE_URL}/Auth/ChangePassword`,
            password,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.error("Error while changing password:", error);
        return {
            error: "An error occurred while changing password. Please try again later.",
        };
    }
};

export const VerifyPasswordChanging = async (
    confirmPassword: string,
    token: string
) => {
    try {
        const response = await api.post(
            `${BASE_URL}/Auth/VerifyPasswordChanging/${token}`,
            confirmPassword,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.error("Error while verifiying changing password:", error);
        return {
            error: "An error occurred while verifiying changing password. Please try again later.",
        };
    }
};

export const Login = async (user) => {
    const data = {
        Email: user.email,
        Password: user.password,
    };

    try {
        const response = await api.post(`/Auth/Login`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        const expiresIn = new Date(Date.parse(response.data.expiresIn));
        expiresIn.setHours(expiresIn.getHours() + 1);

        setAuthTokens(
            response.data.accessToken,
            response.data.refreshToken,
            expiresIn
        );

        const expires = new Date(expiresIn);

        Cookies.set("authToken", response.data.accessToken, {
            expires: expires,
            path: "/",
            ...(process.env.REACT_APP_ENV === "production" && {
                domain: "idigics.com",
            }),
        });

        Cookies.set("refreshToken", response.data.refreshToken, {
            expires: expires,
            path: "/",
            ...(process.env.REACT_APP_ENV === "production" && {
                domain: "idigics.com",
            }),
        });

        return response;
    } catch (error) {
        return null; // Return null instead of { error: error.message }
    }
};

export const Logout = async () => {
    const cookieOptions = {
        path: "/",
        ...(process.env.REACT_APP_ENV === "production" && {
            domain: "idigics.com",
        }),
    };

    Cookies.remove("authToken", cookieOptions);
    Cookies.remove("refreshToken", cookieOptions);
};

export const RefreshToken = async () => {
    const data = {
        refreshToken: getRefreshToken(),
    };

    try {
        const response = await api.post(`${BASE_URL}/Auth/RefreshToken`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        setAuthTokens(
            response.data.accessToken,
            response.data.refreshToken,
            response.data.expiresIn
        );

        return response;
    } catch (error) {
        return null;
    }
};

export function setAuthTokens(accessToken, refreshToken, expiresIn) {
    const expires = new Date(expiresIn);

    const domain =
        process.env.REACT_APP_ENV === "production" ? "idigics.com" : "";

    // Set cookies with or without domain based on the environment
    document.cookie = `authToken=${accessToken}; expires=${expires.toUTCString()}; path=/; domain=${domain}`;
    document.cookie = `refreshToken=${refreshToken}; expires=${expires.toUTCString()}; path=/; domain=${domain}`;
}

// export function setAuthToken(token: string) {
//     const expires = new Date();
//     expires.setDate(expires.getDate() + 3);
//     document.cookie = `authToken=${token}; expires=${expires.toUTCString()}; path=/`;
// }

export function getRefreshToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("refreshToken=")) {
            const refreshToken = cookie.substring(
                "refreshToken=".length,
                cookie.length
            );
            if (refreshToken.length > 0) {
                return refreshToken;
            } else {
                console.error(
                    "Refresh token found, but it's empty or malformed."
                );
                return null;
            }
        }
    }

    return null;
}

export function checkAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return true;
        }
    }

    return false;
}
