import { Helmet } from "react-helmet-async";
import {
    Container,
    Button,
    Card,
    CardHeader,
    CardContent,
    Box,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPassword } from "../AuthenticationData.ts";
import { ToastContainer, toast } from "react-toastify";

const ForgotPasswordEmail = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (email !== "") {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email]);

    const handleConfirm = async () => {
        try {
            const response = await ForgotPassword(email);
            if (response.status === 200) {
                setIsButtonDisabled(true);
                setEmailSent(true);
                toast.success("A verification email has been sent", {
                    position: "top-center",
                    autoClose: 1000,
                });
            } else {
                const errorData = await response.json();
                toast.error(
                    `Error changing the password: ${errorData.message}`,
                    {
                        position: "top-center",
                        autoClose: 1000,
                    }
                );
            }
        } catch (error) {
            console.error("Error verifying email:", error);
        }
    };

    return (
        <>
            <Helmet>
                <title>IDigix | Enter Email</title>
            </Helmet>

            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                }}
            >
                <Card sx={{ width: 600, borderRadius: 2, padding: "40px" }}>
                    <CardHeader
                        title="Email"
                        subheader="Enter your email please"
                    />
                    {emailSent ? (
                        <CardContent>
                            <Box mb={2}>
                                <Typography
                                    variant="overline"
                                    sx={{
                                        mb: 3,
                                        display: "block",
                                        color: "text.secondary",
                                    }}
                                >
                                    Please check your email
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate("/Login");
                                }}
                            >
                                Go to login
                            </Button>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Your Email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                disabled={isButtonDisabled}
                            >
                                Confirm
                            </Button>
                        </CardContent>
                    )}
                </Card>
            </Container>
            <ToastContainer />
        </>
    );
};

export default ForgotPasswordEmail;
