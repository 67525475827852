import api from "./Api";
import { BASE_URL } from "./Context/config";

export interface PostCoupon {
    UserId: number;
    Date: string;
    Country: string;
    Amount: number;
    Number: number;
}

export interface PostUseCoupon {
    SerialKey: string;
    Skill_QualityOfWork: number;
    Skill_CostEffectiveness: number;
    Skill_Timeliness: number;
    Skill_Communication: number;
    Skill_Agility: number;
}

export const PurchasingCoupon = async (coupon: PostCoupon) => {
    const data = {
        UserId: coupon.UserId,
        Date: coupon.Date,
        Country: coupon.Country,
        Amount: coupon.Amount,
        Number: coupon.Number,
    };

    try {
        var authToken = getAuthToken();
        const response = await api.post(
            `${BASE_URL}/Purchases/PurchasingCoupon`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const GetCoupons = async () => {
    try {
        var authToken = getAuthToken();
        const response = await api.get(`${BASE_URL}/Coupons/GetCoupons`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        const modifiedResponse = response.data.map((item) => {
            const { customer, ...rest } = item;
            if (customer != null) {
                const customerInfo = {
                    customerUserName: customer.userName,
                    customerFirstName: customer.firstName,
                    customerLastName: customer.lastName,
                    customerProfilePicture: customer.profilePicture,
                };
                return { ...rest, ...customerInfo };
            }
            return item;
        });

        return modifiedResponse;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export const GetCustomerCoupons = async () => {
    try {
        var authToken = getAuthToken();
        const response = await api.get(
            `${BASE_URL}/Coupons/GetCustomerCoupons`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const modifiedResponse = response.data.map((item) => {
            const { owner, ...rest } = item;
            const ownerInfo = {
                ownerId: owner.id,
                ownerUserName: owner.userName,
                ownerFirstName: owner.firstName,
                ownerLastName: owner.lastName,
                ownerProfilePicture: owner.profilePicture,
                ownerEmail: owner.email,
                ownerCategory: owner.category,
            };
            return { ...rest, ...ownerInfo };
        });

        return modifiedResponse;
    } catch (error) {
        return { error: error.message };
    }
};

export const GetCouponsFromCustomers = async () => {
    try {
        var authToken = getAuthToken();
        const response = await api.get(
            `${BASE_URL}/Coupons/GetCouponsFromCustomers`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.message };
    }
};

export const ReserveCoupon = async (Id: number) => {
    try {
        var authToken = getAuthToken();
        const response = await api.put(
            `${BASE_URL}/Coupons/ReserveCoupon/${Id}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        return { error: error.response.data };
    }
};

export const UseCoupon = async (coupon: PostUseCoupon) => {
    const data = {
        SerialKey: coupon.SerialKey,
        Skill_QualityOfWork: coupon.Skill_QualityOfWork,
        Skill_CostEffectiveness: coupon.Skill_CostEffectiveness,
        Skill_Timeliness: coupon.Skill_Timeliness,
        Skill_Communication: coupon.Skill_Communication,
        Skill_Agility: coupon.Skill_Agility,
    };
    try {
        var authToken = getAuthToken();
        const response = await api.post(`${BASE_URL}/Coupons/UseCoupon`, data, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (error) {
        return { error: error.response.data };
    }
};

export const DeleteCoupon = async (Id: number) => {
    try {
        var authToken = getAuthToken();
        const response = await api.post(
            `${BASE_URL}/Coupons/DeleteCoupon/${Id}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error.message);
    }
};

export function getAuthToken() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("authToken=")) {
            return cookie.substring("authToken=".length, cookie.length);
        }
    }

    return null;
}
