import { Box, Paper, Typography, Avatar } from "@mui/material";
import { PostClick } from "../../../AnalyticsData.ts";
import { useNavigate } from "react-router-dom";

export default function AppLinksByPublicProfile({
    AccountId,
    list,
    profile,
    type,
    sameAccount = false,
}) {
    const navigate = useNavigate();

    const handleClick = async (key, link) => {
        if (sameAccount === false) {
            await PostClick({
                linkId: key,
                userId: AccountId ?? null,
                gender: profile.profile.gender ?? null,
                country: profile.country ?? null,
                category: link.category,
                date: new Date(),
            });
        }
        if (validateURL(link.body)) {
            window.open(link.body, "_blank");
        } else {
            navigate("/404");
        }
    };

    const validateURL = (url) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };

    return (
        <Box>
            {type == "socialLinks"
                ? list.map((site, key) => (
                      <Paper
                          sx={{
                              padding: "0.8rem 1.5rem",
                              borderRadius: "10px",
                              background: "#f5f5f5f5",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center", // S
                              margin: "0.6rem 0",
                              // border: `2px solid ${site.color}`,
                              marginTop: "15px",
                              boxShadow:
                                  "0 0.4rem 0.8rem rgba(20, 43, 58, 0.50)",
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                  transform: "scale(1.05)", // Apply scale transformation on hover
                              },
                          }}
                          key={key}
                          onClick={() => handleClick(key, site)}
                      >
                          <Box color={site.color} fontSize="18px">
                              {site.icon}
                          </Box>

                          <Typography
                              sx={{
                                  color: "#333333",
                                  fontWeight: 1000,
                                  margin: "auto",
                              }}
                          >
                              {site.name}
                          </Typography>
                      </Paper>
                  ))
                : list.map((site, key) => (
                      <Paper
                          sx={{
                              padding: "0.8rem 1.5rem",
                              borderRadius: "10px",
                              position: "relative",
                              background: "#f5f5f5f5",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              margin: "0.6rem 0",
                              marginTop: "15px",
                              boxShadow:
                                  "0 0.4rem 0.8rem rgba(20, 43, 58, 0.50)",
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                  transform: "scale(1.05)",
                              },
                              "&::after": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  width: "10px",
                                  backgroundColor: "#ff715b",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                              },
                          }}
                          onClick={() => handleClick(key, site.body)}
                          key={key}
                      >
                          <Avatar
                              style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "60%",
                                  border: "1px solid rgba(10, 20, 20, 0.12)",
                                  marginBottom: "0.2rem",
                              }}
                              src={site.icon}
                          />

                          <Typography
                              sx={{
                                  color: "#333333",
                                  fontWeight: 1000,
                                  fontSize: "15px",
                                  margin: "auto",
                              }}
                          >
                              {site.name}
                          </Typography>
                      </Paper>
                  ))}
        </Box>
    );
}
