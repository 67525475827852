import React from "react";
import { Typography, CardContent, Box } from "@mui/material";
import palette from "src/theme/palette";

const ThankYouCard = ({ feedbackScores }) => {
    const totalScore = feedbackScores.reduce((acc, score) => acc + score, 0);
    const averageRating = totalScore / feedbackScores.length;
    const PRIMARY_MAIN = palette.primary.main;

    return (
        <Box
            sx={{
                margin: "auto",
                textAlign: "center",
                padding: "10px",
                borderRadius: "15px",
                maxWidth: "400px", // Optional: limits the width of the card
            }}
        >
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Your average rating: {averageRating.toFixed(2)}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px", // Optional: space below the image
                    }}
                >
                    <img
                        src="/assets/illustrations/characters/character_10.png"
                        alt="Character illustration"
                        style={{ maxWidth: "50%", height: "auto" }} // Ensures image is responsive
                    />
                </Box>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ color: PRIMARY_MAIN }}
                >
                    Please confirm your rating
                </Typography>
                <Typography variant="body2" component="p">
                    We appreciate the time you took to give a rating. If you
                    need further assistance, feel free to contact us!
                </Typography>
            </CardContent>
        </Box>
    );
};

export default ThankYouCard;
