import React, { useState } from "react";
import {
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
} from "@mui/material";

import { CreateContact, EditContact } from "../../../ContactData.ts";

import { toast } from "react-toastify";

const PhoneLinkDialog = ({
    setOpenPhoneDialog,
    openPhoneDialog,
    Id,
    PhoneNumberExist,
}) => {
    const [phoneNumber, setPhoneNumber] = useState("");

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const isValidPhoneNumber = (input) => {
        // Regular expression pattern to match phone numbers with 8 digits
        const phonePattern = /^\d{8}$/;

        // Check if the input matches phone number pattern
        const isPhoneNumber = phonePattern.test(input);

        // Return true if it's a valid URL with http/https, a valid social media URL with http/https, OR a valid phone number
        return isPhoneNumber;
    };

    const handleDone = async () => {
        if (!isValidPhoneNumber(phoneNumber)) {
            toast.error("Invalid phone format", {
                position: "top-center",
                autoClose: 2000,
            });
            return;
        }

        let response;

        if (PhoneNumberExist) {
            response = await EditContact({
                ContactInfo: phoneNumber,
                Category: "PhoneNumber",
                isPublic: true,
            });
        } else {
            response = await CreateContact({
                UserId: Id,
                ContactInfo: phoneNumber,
                Category: "PhoneNumber",
                isPublic: true,
            });
        }

        localStorage.setItem("isLinksCardVisible", "true");

        setPhoneNumber(0);
        setOpenPhoneDialog(false);

        if (response) {
            toast.success("Phone number added", {
                position: "top-center",
                autoClose: 1000,
            });
        } else {
            toast.error("Error while adding phone number", {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };

    return (
        <Dialog
            open={openPhoneDialog}
            onClose={() => {
                setOpenPhoneDialog(false);
            }}
        >
            <DialogTitle>Add your phone number</DialogTitle>
            <DialogContent>
                <TextField
                    name="PhoneNumber"
                    autoFocus
                    margin="dense"
                    label="Phone"
                    type="tel"
                    fullWidth
                    required
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    helperText={phoneNumber === "" ? "number is required" : ""}
                />
                {/* Hints and Tips Section */}
                <Box
                    mt={2}
                    p={2}
                    sx={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "5px",
                    }}
                >
                    <Typography variant="subtitle1" color="textPrimary">
                        Tips for Entering a Correct Phone Number
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        - Enter the phone number without spaces, dashes, or
                        symbols.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        - Include the country code, starting with a "+" followed
                        by the country code digits (e.g., +1 for the US).
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        - Ensure the phone number has the correct number of
                        digits for the country you're entering it for.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        - Example format: "+12345678901" for a US phone number.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setPhoneNumber(0);
                        setOpenPhoneDialog(false);
                    }}
                >
                    Cancel
                </Button>
                <Button onClick={handleDone} disabled={phoneNumber === ""}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhoneLinkDialog;
