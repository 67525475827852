import React, { useState, useEffect, useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
    Grid,
    Card,
    Typography,
    Button,
    Dialog,
    Box,
    DialogContent,
    DialogTitle,
    CircularProgress,
    IconButton,
    CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PortraitIcon from "@mui/icons-material/Portrait";

const ContactSection = ({ account }) => {
    const [isCvFileFound, setIsCVFileFound] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const fileURLRef = useRef(null);

    useEffect(() => {
        const existingCvContact = account.contacts.find(
            (contact) => contact.category === "CvFile"
        );
        if (existingCvContact) {
            setIsCVFileFound(true);
            fileURLRef.current = existingCvContact.contactInfo;
        }
        setIsLoading(false);
    }, [account.contacts]);

    const handleDownload = () => {
        if (fileURLRef.current) {
            const link = document.createElement("a");
            console.log(fileURLRef.current);
            link.href = `data:application/pdf;base64,${fileURLRef.current}`;
            link.download = `${account.firstName}_${account.lastName}_CV.pdf`;
            link.click();
        }
    };

    return (
        <>
            {isCvFileFound && (
                <Grid item xs={12} md={12}>
                    <Card sx={{ display: "flex", marginTop: "20px" }}>
                        <Box
                            sx={{
                                objectFit: "cover",
                                height: "100%",
                                width: "560px",
                                display: { xs: "none", sm: "block" },
                                overflow: "hidden",
                            }}
                        >
                            <img
                                src="../assets/images/PublicCv.jpg"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>

                        <CardContent sx={{ textAlign: "left" }}>
                            <Typography
                                variant="overline"
                                sx={{
                                    mb: 2,
                                    color: "text.secondary",
                                    fontSize: "1rem",
                                    letterSpacing: "0.1rem",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                }}
                            >
                                Resume / CV
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#333",
                                    marginBottom: "12px",
                                }}
                            >
                                {account.firstName} {account.lastName}'s
                                Professional Resume
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    lineHeight: "1.75",
                                    marginBottom: "24px",
                                }}
                            >
                                Discover the full range of {account.firstName}'s
                                experiences, skills, and qualifications. Click
                                below to view the complete resume in PDF format.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setDialogOpen(true)}
                                    sx={{ borderRadius: "8px" }}
                                >
                                    <PortraitIcon />
                                </Button>
                                {/* <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleDownload}
                                    sx={{ borderRadius: "8px" }}
                                >
                                    Download CV
                                </Button> */}
                            </Box>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>
                            CV Preview <PortraitIcon />
                        </DialogTitle>
                        <DialogContent>
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                                aria-label="close"
                                onClick={() => setDialogOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <div
                                    style={{
                                        height: "600px",
                                        width: "100%",
                                        overflow: "auto",
                                    }}
                                >
                                    <Worker
                                        workerUrl={`https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js`}
                                    >
                                        <Viewer
                                            fileUrl={fileURLRef.current}
                                            showPreviousViewOnLoad={false}
                                        />
                                    </Worker>
                                </div>
                            )}
                        </DialogContent>
                    </Dialog>
                </Grid>
            )}
        </>
    );
};

export default ContactSection;
