import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LoginPage from "./pages/LoginPage";
import SingUpPage from "./pages/SignUpPage";
import Page404 from "./pages/Page404";
import AccessDenied from "./pages/AccessDenied";
import VerifyMail from "./pages/VerifyMailPage";
import VerifiyingMail from "./pages/VerifiyingMailPage";
import VerifyPasswordChangingPage from "./pages/VerifyPasswordChangingPage";
import ForgotPasswordEmail from "./pages/ForgotPasswordEmail";
// import ProductsPage from "./pages/ProductsPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import Prof from "./pages/profileUser";
import { checkAuthToken } from "./AuthenticationData.ts";
import { BundlesPage } from "./pages/BundlesPage";
import { SettingsPage } from "./pages/SettingsPage";
import { RatingPage } from "./pages/RatingPage";
import { Track } from "./pages/TrackPage";
import AppManageCoupons from "./sections/@dashboard/Coupons/AppManageCoupons";
import SearchResults from "./pages/SearchResults";
import Profile from "./pages/Profile";

export default function Router() {
    const isAuthenticated = checkAuthToken();
    const userCategory = localStorage.getItem("userCategory");
    const isEmailSent = localStorage.getItem("isEmailSent") === "true";

    const RouteGuarding = (category, allowedCategories) => {
        if (isAuthenticated) {
            if (!allowedCategories.includes(category)) {
                return <Navigate to="/AccessDenied" />;
            }
        } else {
            return <Navigate to="/Login" />;
        }
        return null;
    };

    const EmailVerifGuard = () => {
        if (!isEmailSent == true) return <Navigate to="/AccessDenied" />;
        return null;
    };

    const routes = useRoutes([
        {
            path: "/",
            element: isAuthenticated ? (
                <Navigate to="/admin/User" />
            ) : (
                <Navigate to="/Login" />
            ),
        },
        {
            path: "/admin",
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/admin/user" /> },
                {
                    path: "User",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Free",
                                "Student",
                                "Freelance",
                                "Enterprise",
                            ])}
                            <Prof />
                        </>
                    ),
                },
                {
                    path: "Analytics",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Freelance",
                                "Enterprise",
                            ])}
                            <AnalyticsPage />
                        </>
                    ),
                },
                {
                    path: "Rating",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Freelance",
                                "Enterprise",
                            ])}
                            <RatingPage />
                        </>
                    ),
                },
                // {
                //     path: "Products",
                //     element: (
                //         <>
                //             {RouteGuarding(userCategory, [
                //                 "Free",
                //                 "Student",
                //                 "Freelance",
                //                 "Enterprise",
                //             ])}
                //             <ProductsPage />
                //         </>
                //     ),
                // },
                {
                    path: "Search",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Free",
                                "Student",
                                "Freelance",
                                "Enterprise",
                            ])}
                            <SearchResults />
                        </>
                    ),
                },
                {
                    path: "Settings",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Free",
                                "Student",
                                "Freelance",
                                "Enterprise",
                            ])}
                            <SettingsPage />
                        </>
                    ),
                },
                {
                    path: "Bundles",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Free",
                                "Student",
                                "Freelance",
                                "Enterprise",
                            ])}
                            <BundlesPage />
                        </>
                    ),
                },
                {
                    path: "ManageCoupons",
                    element: (
                        <>
                            {RouteGuarding(userCategory, [
                                "Free",
                                "Student",
                                "Freelance",
                                "Enterprise",
                            ])}
                            <AppManageCoupons />
                        </>
                    ),
                },
            ],
        },
        { path: "/Signup", element: <SingUpPage /> },
        {
            path: "/Login",
            element: isAuthenticated ? (
                <Navigate to="/admin/User" />
            ) : (
                <LoginPage />
            ),
        },
        { path: "/Profile/:param", element: <Profile /> },
        {
            path: "/Track/:param",
            element: (
                <>
                    {RouteGuarding(userCategory, ["Enterprise"])}
                    <Track />
                </>
            ),
        },

        { path: "404", element: <Page404 /> },
        { path: "AccessDenied", element: <AccessDenied /> },
        {
            path: "/VerifyMail",
            element: (
                <>
                    {EmailVerifGuard()}
                    <VerifyMail />
                </>
            ),
        },
        {
            path: "/ForgotPasswordEmail",
            element: <ForgotPasswordEmail />,
        },
        {
            path: "/VerifiyingMail/:token",
            element: (
                <>
                    {EmailVerifGuard()}
                    <VerifiyingMail />
                </>
            ),
        },
        {
            path: "/VerifyPasswordChanging/:token",
            element: <VerifyPasswordChangingPage />,
        },
        { path: "*", element: <Page404 /> },
    ]);

    return routes;
}
