import React, { useState, useEffect } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";

const Register2 = ({ onNext, onPrevious, data }) => {
    const [formData, setFormData] = useState(data);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setError("");
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    const handlePrevious = (e) => {
        e.preventDefault();
        onPrevious(formData);
    };

    const handleNext = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onNext(formData);
        } else {
            toast.error("Please validate your information", {
                position: "top-center",
                autoClose: 1000,
            });
        }
    };

    const validateForm = () => {
        const { contactInfo } = formData;

        const isPhoneNumberValid =
            /^\d{8}$/.test(contactInfo) && !/(\d)\1{7,}/.test(contactInfo);

        setError(
            contactInfo.trim() !== ""
                ? isPhoneNumberValid
                    ? ""
                    : "Phone number is Invalid"
                : "Phone number is required"
        );

        return isPhoneNumberValid;
    };

    return (
        <>
            <Stack spacing={3} className="mb-5">
                <TextField
                    id="contactInfo"
                    name="contactInfo"
                    label="Phone number"
                    type="tel"
                    value={formData.contactInfo}
                    onChange={handleChange}
                    error={error && formData.contactInfo.trim() !== ""}
                    helperText={error}
                />
            </Stack>

            <Typography variant="body2" sx={{ mb: 5 }}>
                Already have an account?{" "}
                <a href="/login" style={{ color: "silver" }}>
                    Sign in
                </a>
            </Typography>

            <LoadingButton
                className="me-5"
                size="large"
                type="submit"
                variant="contained"
                onClick={handlePrevious}
            >
                Previous
            </LoadingButton>
            <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={handleNext}
                disabled={!!error}
            >
                Next
            </LoadingButton>
            <ToastContainer />
        </>
    );
};

export default Register2;
