import React, { useState, useEffect } from "react";
import {
    Stack,
    FormControl,
    RadioGroup,
    TextField,
    FormControlLabel,
    Radio,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import "./Register1.css";

const Register1 = ({ onNext, data }) => {
    const [formData, setData] = useState(data);
    const [usernameChanged, setUsernameChanged] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isFormValid, setFormValid] = useState(false);
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        userName: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            if (usernameChanged) {
                handleConfirmDialogOpen();
            } else {
                onNext(formData);
            }
        } else {
            toast.error(
                "Please validate your information and select a gender",
                {
                    position: "top-center",
                    autoClose: 1000,
                }
            );
        }
    };

    const validateForm = () => {
        const { firstName, lastName, userName, gender } = formData;

        const isFirstNameValid = /^[A-Za-z ]{3,32}$/.test(firstName);
        const isLastNameValid = /^[A-Za-z ]{3,32}$/.test(lastName);
        const isUserNameValid = /^[A-Za-z0-9_]{3,16}$/.test(userName);
        const isGenderSelected = gender.trim() !== "";

        setErrors({
            firstName:
                firstName.length < 3
                    ? "First name is required ( minimum 3 characters )"
                    : isFirstNameValid
                    ? ""
                    : "Invalid first name",
            lastName:
                lastName.length < 3
                    ? "Last name is required ( minimum 3 characters )"
                    : isLastNameValid
                    ? ""
                    : "Invalid last name",
            userName:
                userName.length < 3
                    ? "Username is required ( minimum 3 characters )"
                    : isUserNameValid
                    ? ""
                    : "Username must be 3-16 characters and can only contain letters, numbers, and underscores",
            gender: isGenderSelected ? "" : "Please select a gender",
        });

        setFormValid(
            isFirstNameValid &&
                isLastNameValid &&
                isUserNameValid &&
                isGenderSelected
        );
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleUsernameChangeConfirmed = () => {
        setConfirmDialogOpen(false);
        onNext(formData);
    };

    return (
        <>
            <form>
                <Stack spacing={3}>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        error={
                            errors.firstName && formData.firstName.trim() !== ""
                        }
                        helperText={errors.firstName}
                    />

                    <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        error={
                            errors.lastName && formData.lastName.trim() !== ""
                        }
                        helperText={errors.lastName}
                    />

                    <TextField
                        id="userName"
                        name="userName"
                        label="Username"
                        value={formData.userName}
                        onChange={handleChange}
                        required
                        error={
                            errors.userName && formData.userName.trim() !== ""
                        }
                        helperText={errors.userName}
                    />

                    <p style={{ color: "red" }}>
                        Note: Usernames are permanent and cannot be changed
                        after registration. Choose wisely!
                    </p>

                    <FormControl className="ms-5 mb-4">
                        <RadioGroup
                            row
                            aria-labelledby="col-sm-3"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="female"
                                label={"Female"}
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="male"
                                label={"Male"}
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>

                <Typography variant="body2" sx={{ mb: 5 }}>
                    Already have an account?{" "}
                    <a href="/login" style={{ color: "silver" }}>
                        Sign in
                    </a>
                </Typography>

                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                >
                    Next
                </LoadingButton>

                <ToastContainer />
            </form>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Username Change"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        your username "{formData.userName}" is a permanent
                        action. <br /> Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUsernameChangeConfirmed}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Register1;
