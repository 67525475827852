import { useState, useEffect } from "react";
// @mui
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    IconButton,
    Popover,
} from "@mui/material";
// mocks_
import { Logout, checkAuthToken } from "../../../AuthenticationData.ts";
import { useProfile } from "../../../Context/ProfileContext";
import { useNavigate } from "react-router-dom";

export default function AccountPopover() {
    const { profile } = useProfile();
    const [Account, setAccount] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const HandleLogout = async () => {
        try {
            await Logout();

            let isAuthenticated = checkAuthToken();

            if (!isAuthenticated) {
                navigate("/Login");
            } else {
                console.error("Logout failed: User is still authenticated.");
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    useEffect(() => {
        fetchProfileData();
    }, [profile]);

    const fetchProfileData = async () => {
        try {
            setAccount({
                email: profile.email,
                firstName: profile.firstName,
                lastName: profile.lastName,
            });
        } catch (error) {
            if (error.redirectToLogin) {
                navigate("/Login");
            }
        }
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <i className="bi bi-gear-fill"></i>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    sx: {
                        mt: 1.5,
                        ml: 0.75,
                        width: 235,
                        "& .MuiMenuItem-root": {
                            typography: "body2",
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {Account.firstName} {Account.lastName}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {Account.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {/* <MenuItem onClick={handleClose}>Home</MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            navigate("User");
                            handleClose();
                        }}
                    >
                        Profile
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate("ManageCoupons");
                            handleClose();
                        }}
                    >
                        Coupons
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate("Settings");
                            handleClose();
                        }}
                    >
                        Settings
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose}>Feedback</MenuItem> */}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={HandleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
