import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import "react-toastify/dist/ReactToastify.css";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { BudgetProvider } from "./Context/BudgetContext";
import { ProfileProvider } from "./Context/ProfileContext";
import { SearchProvider } from "./Context/SearchContext";

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ProfileProvider>
            <BudgetProvider>
                <SearchProvider>
                    <HelmetProvider>
                        <BrowserRouter>
                            <ThemeProvider>
                                <ScrollToTop />
                                <StyledChart />
                                <Router />
                            </ThemeProvider>
                        </BrowserRouter>
                    </HelmetProvider>
                </SearchProvider>
            </BudgetProvider>
        </ProfileProvider>
    );
}
